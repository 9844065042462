import React, { useEffect, useState } from "react";
import "./FundAnnouncement.scss";
import {
  Badge,
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const FundAnnouncement = (props) => {
  return (
    <Container className="FundAnnoucement py-5">
      <Link to={"/fund-gallery"}>
        <div className={"d-flex px-3 justify-content-center"}>
          <div className={"cardName py-4"}>
            <Fade cascade triggerOnce>
              <div className={"p-1 fund-title"}>
                Ready to take the first step?
              </div>

              <span className={"text-light opacity-75"}>
                Access the current V0 Fund Selection
              </span>

              <Button className="primary-button base-button mt-4">
                View Fund Gallery
              </Button>
            </Fade>
          </div>
        </div>
      </Link>
    </Container>
  );
};

export default FundAnnouncement;
