import React, { useEffect } from "react";
import { Container, Table } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";
import FundBadge from "./FundBadge";
import "./TeamMembers.scss";
import { Badge } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDown, More } from "iconsax-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

export default function TeamMembers(props) {
  const { lpEntityCode, data } = { ...props };
  const { getAccessTokenSilently } = useAuth0();

  const handleButtonClick = (e, row) => {
    e.preventDefault();
  };
  useEffect(() => {}, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
      grow: 3,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      grow: 4,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      grow: 2,
    },
    // {
    //   name: "Last Active",
    //   selector: (row) => row.year,
    //   sortable: true,
    //   grow: 3,
    // },
    // {
    //   name: "Team Permission",
    //   selector: (row) => row.role,
    //   grow: 3,
    // },
    // {
    //   grow: 0,
    //   name: "",
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    //   cell: (row) => (
    //     <FontAwesomeIcon
    //       color={"rgba(51, 51, 51, 0.8)"}
    //       icon={faEllipsisV}
    //       className="mr-2"
    //       onClick={(e) => handleButtonClick(e, row)}
    //     />
    //     // <More
    //     //   //size="32"
    //     //   color="#333333"
    //     //   className={""}
    //     //   onClick={handleButtonClick}
    //     // ></More>
    //   ),
    //},
  ];

  return (
    <Container className="pb-3 TeamMembers">
      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        sortIcon={<ArrowDown className="mx-3" />}
        emptyComponent={<div></div>}
      />
    </Container>
  );
}
