import React, { useEffect, useContext, useState, Fragment } from "react";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb, BreadcrumbItem, Button, Col, FormGroup } from "reactstrap";
import FormStyles from "./FormStyles";
import { Field, Form } from "react-final-form";
import BaseService from "../service/BaseService";
import { Row, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import SignoutButton from "./SignoutButton";
import UserRegistration from "./UserRegistration";
import Hero2 from "./Hero2";

function getQueryVariable(variable) {
  var query = window.location.href.split("?")[1];
  var vars = (query || "").split("&");

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return "";
}

export default function EnterInvitation(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently, loginWithRedirect, loginWithPopup } =
    useAuth0();
  const [defaultCode, setDefaultCode] = useState("");
  const [user, setUser] = useState({});
  const [error, setError] = useState(undefined);

  const loadData = async () => {
    try {
      setDefaultCode(getQueryVariable("ic"));
      const token = await getAccessTokenSilently();

      await BaseService.login(token);
      let resp = await BaseService.getUser(token);
      if (resp) {
        if (resp.data.status === "ACTIVE") {
          setContext(resp.data);
          setUser(resp.data);
          //navigate("/");
          return;
        } else if (resp.data.status === "INVITED") {
          setContext(resp.data);
          setUser(resp.data);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    let timerId = setTimeout(() => {
      loadData();
    }, 100);

    return () => clearTimeout(timerId);
  }, []);

  let config = {
    authorizationParams: { screen_hint: "signup" },
  };
  return (
    <Fragment key={user.email}>
      {!user.email && (
        <>
          <Hero2
            image={2}
            showDownArrow={false}
            title={"Hello. Welcome to the V0 platform."}
            subtitle={
              "We are delighted to have you join us. Let’s get started."
            }
            otherComponent={
              <Button
                color="primary"
                className="btn-margin ml-5 mt-3 primary-button base-button"
                onClick={async () => {
                  await loginWithPopup(config);
                  await loadData();
                }}
              >
                Sign up
              </Button>
            }
          />
        </>
      )}
      {user.email && (
        <Container
          fluid
          style={
            {
              // backgroundColor: "#ffffff",
              // backgroundImage:
              //   "linear-gradient(130deg, #ffffff 0%, #ddf8fd 100%)",
            }
          }
        >
          <UserRegistration user={user} />
        </Container>
      )}
    </Fragment>
  );
}
