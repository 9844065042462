import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useScrollDirection } from "react-use-scroll-direction";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { isScrolling } = useScrollDirection();

  const location = useLocation();
  const lastHash = useRef("");

  const scrollToTop = () => {
    document.querySelector("body").scrollTo(0, 0);
    setTimeout(() => {
      if (!isScrolling) {
        window.scrollTo(0, 0);
      }
    }, 10);
  };

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }
    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        if (!isScrolling) {
          document.getElementById(lastHash.current)?.scrollIntoView({});
          lastHash.current = "";
        }
      }, 10);
    } else {
      !isScrolling && scrollToTop();
    }
    return () => {
      // cancel the subscription
    };
  }, [pathname, location]);

  return null;
}
