import React from "react";
import { Col, Row } from "reactstrap";
import BufferImage from "./BufferImage";
import { Badge, Placeholder, PlaceholderButton } from "react-bootstrap";
import logo from "../assets/logo.png";
import useScreenType from "react-screentype-hook";
const Buffer = require("buffer/").Buffer;

const imageSize = 300;

export default function FundTitle(props) {
  let {
    badge = "",
    secondaryBadge = "",
    tier = 0,
    listingView = false,
    fundTitle = "",
    base64ImgBuffer,
    size = imageSize,
    imageCoverMode,
    backgroundColor = "#111111",
    fontColor = "white",
    imageURL = "",
    alignTop = false,
  } = props;
  const screenType = useScreenType();
  let base64Img;
  if (base64ImgBuffer) {
    base64Img = Buffer.from(base64ImgBuffer).toString("utf8");
  }
  if (tier.toString() === "1") {
    size = imageSize;
  } else if (tier.toString() === "2") {
    size = "200px";
  } else if (tier.toString() === "6") {
    size = 300;
  }

  let margin = !screenType.isMobile
    ? { marginLeft: "auto" }
    : { marginLeft: "auto", marginRight: "auto" };

  const getImage = () => {
    if (imageURL) {
      return (
        <img
          alt={fundTitle}
          src={imageURL}
          style={{
            width: "100%",
            height: size,
            maxHeight: "250px",
            maxWidth: "300px",
            objectFit: "contain",
            ...margin,
          }}
        />
      );
    } else if (base64Img) {
      return (
        <BufferImage
          style={{
            bottom: "0",
            right: "0",
            width: "auto",
            height: size,
            maxWidth: size,
            objectFit: "contain",
            ...margin,
          }}
          base64ImgBuffer={base64ImgBuffer}
          size={size}
          imageCoverMode={imageCoverMode}
        />
      );
    } else {
      return (
        <img
          alt={fundTitle}
          className={"p-2"}
          src={logo}
          style={{
            bottom: "0",
            right: "0",
            width: "auto",
            height: size,
            maxHeight: "250px",
            maxWidth: size,
            objectFit: "contain",
            ...margin,
          }}
        />
      );
    }
  };

  return (
    <Row
      style={{
        maxWidth: "1100px",
        margin: "auto",
        backgroundColor: backgroundColor,
        color: fontColor,
        height: screenType.isMobile
          ? "inherit"
          : listingView
          ? "250px"
          : "280px",
      }}
      className={"border"}
    >
      <Col
        sm="12"
        xs="12"
        md={listingView ? "6" : "8"}
        lg={listingView ? "6" : "8"}
        className="z-3"
        style={{
          display: "flex",
          paddingTop: alignTop ? "2rem" : screenType.isMobile ? "2rem" : "0rem",
          //paddingBottom: screenType.isMobile ? "1rem" : "0rem",
          flexDirection: "column",
          // height: screenType.isMobile
          //   ? tier.toString() === "1"
          //     ? "inherit"
          //     : "20px"
          //   : "280px",
          justifyContent: alignTop ? "flex-start" : "center",
        }}
      >
        <div className="ml-4 pr-1">
          <h3 className={"mt-2 mb-2 fund-title"}>{fundTitle}</h3>
          <Row style={{ height: "28px" }}>
            {(tier.toString() === "1" || tier.toString() === "2") && (
              <>
                {badge && (
                  <Badge
                    className={`ml-2  TierBadge ${
                      tier.toString() === "1" && "Tier1Badge"
                    }`}
                    pill
                  >
                    {badge}
                  </Badge>
                )}

                {secondaryBadge && (
                  <Badge className={`ml-2  TierBadge ClosedBadge `} pill>
                    {secondaryBadge}
                  </Badge>
                )}
              </>
            )}
          </Row>
        </div>
      </Col>
      <Col
        sm="12"
        xs="12"
        md={listingView ? "6" : "4"}
        lg={listingView ? "6" : "4"}
        className={tier.toString() === "1" ? "px-3 py-3" : "px-3 py-3"}
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: screenType.isMobile ? "280px" : "inherit",
          // paddingTop: tier.toString() === "1" ? "40px" : "40px",
          // paddingBottom: tier.toString() === "1" ? "40px" : "40px",
          justifyContent: tier.toString() === "1" ? "center" : "center",
          alignItems: "center",
        }}
      >
        <div className="text-align-center">{getImage()}</div>
      </Col>
    </Row>
  );
}
