import React, { Suspense, useEffect, useState } from "react";
import {
  Card,
  CardText,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardService from "../service/DashboardService";
import BaseService from "../service/BaseService";
import { Container } from "react-bootstrap";
import Loading from "./Loading";
import ResponsiveEmbed from "react-responsive-embed";
import useScreenType from "react-screentype-hook";

export default function DataroomViewer(props) {
  const screenType = useScreenType();

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [datarooms, setDatarooms] = useState([]);
  const [embedURL, setEmbedURL] = useState(undefined);

  const { getAccessTokenSilently } = useAuth0();

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let digify = await BaseService.digify(token);
      //setFiles(digify?.data.files);
      let rooms = digify?.data?.datarooms?.sort((a, b) => {
        if (a.DataRoomName < b.DataRoomName) {
          return -1;
        }
        if (a.DataRoomName > b.DataRoomName) {
          return 1;
        }
        return 0;
      });

      setDatarooms(rooms);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getEmbedURL = async (digifyRoomGuid) => {
    const token = await getAccessTokenSilently();
    let resp = await BaseService.getDataroomEmbedURL(token, digifyRoomGuid);
    if (resp.data) {
      return resp.data;
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    setLoading(true);
    let timerId = setTimeout(() => {
      loadData();
    }, 100);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      <h2>Data Room Viewer</h2>

      <Container fluid className={"m-auto p-auto text-align-center"}>
        <div className={"form-fields py-3 w-50 m-auto"}>
          <Input
            type={"select"}
            onChange={async (e) => {
              setEmbedURL(undefined);
              if (e.target.value) {
                setEmbedURL(await getEmbedURL(e.target.value));
              }
            }}
          >
            <option value="">
              Please select one of the following data rooms
            </option>
            {(datarooms || []).map((room) => {
              return (
                <option key={room.DataRoomGuid} value={room.DataRoomGuid}>
                  {room.DataRoomName}
                </option>
              );
            })}
          </Input>
        </div>

        <div key={"outer-room-"} className="border border-light">
          <Suspense fallback={<Loading />}>
            {embedURL && (
              <ResponsiveEmbed
                loading="lazy"
                ratio={screenType.isMobile ? "2:4" : "1:1"}
                allowFullScreen
                src={embedURL}
              />
            )}
          </Suspense>
        </div>
      </Container>
    </>
  );
}
