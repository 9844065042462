import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import VCFundListing from "../components/VCFundListing";
import ReactGA from "react-ga4";
import {
  Link,
  NavLink as RouterNavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import { Fade } from "react-awesome-reveal";
import VCFundService from "../service/VCFundService";
import VCFundCarousel from "../components/VCFundCarousel";
import DashboardService from "../service/DashboardService";

export const VCFundsComponent = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    tiers = [1, 2, 3],
    exceptionalFundCategory = undefined,
    additionalFundTiers = [],
    otherFundTitle = "Discover Other Funds",
    showOtherFund = false,
    hideCategoryTitle = false,
    showAdditionalFund = false,
    alpMode = false,
  } = props;

  let title = props.title || "";
  let description = props.description || "";

  const [viewTiers, setViewTiers] = useState(tiers);
  const [otherTier, setOtherTier] = useState({});
  const [displayTitle, setDisplayTitle] = useState("");
  const [displayDescription, setDisplayDescription] = useState("");
  const navigate = useNavigate();

  const loadData = async () => {
    const token = await getAccessTokenSilently();
    if (alpMode) {
      let conf = await DashboardService.getConfiguration(token);

      let alpFundGalleryTitle = conf?.find(
        (e) => e.name.toLowerCase() === "alp-fund-gallery-title"
      )?.meta?.value;

      let alpFundGalleryDescription = conf?.find(
        (e) => e.name.toLowerCase() === "alp-fund-gallery-description"
      )?.meta?.value;

      if (alpFundGalleryTitle) {
        setDisplayTitle(alpFundGalleryTitle);
      }

      if (alpFundGalleryDescription) {
        setDisplayDescription(alpFundGalleryDescription);
      }
    }

    if (showOtherFund) {
      // get Tier 6 info

      let resp = await VCFundService.getAll(
        token,
        1,
        0,
        "",
        true,
        undefined,
        6
      );
      if (resp?.rows?.length > 0) {
        setOtherTier(resp.rows[0].fundCategory);
      }
    }
  };

  React.useEffect(() => {
    if (tiers.length !== viewTiers.length) {
      setViewTiers(tiers);
    }
  }, [tiers]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/fund-gallery/" + tiers,
    });
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, [tiers]);

  return (
    <Container className="mb-5" fluid>
      {(title || displayTitle) && (
        <Fade triggerOnce>
          <Row className={"pt-5 pb-3 page-width mx-auto"}>
            <Col>
              <h4 className="text-center py-3 fund-category-title">
                {displayTitle || title}
              </h4>
              <div
                className={"col col-md-10 mx-auto  fund-category-description"}
              >
                {displayDescription || description}
              </div>
            </Col>
          </Row>
        </Fade>
      )}

      {viewTiers.map((tier) => {
        return (
          <Container
            key={tier}
            className="h-100"
            id={tier === 1 ? "V0-Selected-Funds" : ""}
          >
            <VCFundListing
              tier={tier}
              publicView={true}
              hideCategoryTitle={hideCategoryTitle}
            />
          </Container>
        );
      })}

      {viewTiers.indexOf(6) !== -1 && (
        <Fade delay={3e3} cascade damping={1e-1}>
          <Container>
            <Link
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeft size="32" color="#333333" /> Back
            </Link>
          </Container>
        </Fade>
      )}

      {additionalFundTiers.length > 0 && (
        <Fade triggerOnce delay={1000}>
          <Container>
            <Row className={"page-width m-0 m-auto"}>
              <Col className="py-5 ">
                <VCFundCarousel
                  title={"Additional Funds"}
                  olddescription={`Our team meticulously curates and publishes funds that have
                  undergone rigorous review on the platforms. For the funds that
                  have successfully passed our stringent review process and
                  gained our endorsement, we present an exclusive opportunity to
                  our members: the privilege to solicit a personal introduction
                  to the respective funds.`}
                  description={null}
                  tiers={additionalFundTiers}
                  exceptionalFundCategory={exceptionalFundCategory}
                />
              </Col>
            </Row>
          </Container>
        </Fade>
      )}
      {showOtherFund && otherTier.title && (
        <Fade triggerOnce delay={1000}>
          <Row className={"py-3 my-3 mx-auto"}>
            <Col>
              <h4 className="text-center my-5 fund-category-title">
                {otherFundTitle}
              </h4>

              <Container className="bg-light py-5 px-5 mx-auto  page-width">
                <Fade triggerOnce cascade>
                  <h4 className="pt-1 fund-category-title-small">
                    {otherTier.title}
                  </h4>
                  <div className={"fund-category-description pt-0 pb-3"}>
                    {otherTier.description}
                  </div>

                  <Link to={"/fund-gallery/open-for-waitlist"}>
                    See funds <ArrowRight color="#333333" />
                  </Link>
                </Fade>
              </Container>
            </Col>
          </Row>
        </Fade>
      )}

      {showAdditionalFund && (
        <Fade triggerOnce delay={2000}>
          <Row className={"py-3 my-3 mx-auto"}>
            <Col>
              <Container className="bg-light py-5 px-5 mx-auto page-width ">
                <Fade triggerOnce cascade>
                  <h4 className="pt-1 fund-category-title-small">
                    Additional funds
                  </h4>
                  <div className={"fund-category-description pt-0 pb-3"}></div>

                  <Link to={"/fund-gallery/fund-library"}>
                    See funds <ArrowRight color="#333333" />
                  </Link>
                </Fade>
              </Container>
            </Col>
          </Row>
        </Fade>
      )}
    </Container>
  );
};

export default withAuthenticationRequired(VCFundsComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
