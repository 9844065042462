import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";
import VCFundService from "../service/VCFundService";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import "./PostInvestment.scss";

import { Fade } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import FundBadge from "../components/FundBadge";
import { ArrowLeft } from "iconsax-react";
import EditPostInvestmentComponent from "../components/PostInvestmentComponents/EditPostInvestmentComponent";
import EditPostInvestmentDocumentComponent from "../components/PostInvestmentComponents/EditPostInvestmentDocumentComponent";

export const EditPostInvestmentViewComponent = () => {
  let { fundName, lpEntityCode } = useParams();
  let navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState({});
  const [lp, setLp] = useState(undefined);

  const [data, setData] = useState(undefined);
  //
  const [activeTab, setActiveTab] = useState("Overview");

  const loadData = async () => {
    const token = await getAccessTokenSilently();
    let d = await VCFundService.getFundByName(token, fundName);
    setData(d);

    let resp = await BaseService.getUser(token);
    if (resp) {
      setUser(resp.data);
    }

    // let resp = await BaseService.getUser(token);
    // if (resp) {
    //   setUser(resp.data);
    // }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  useEffect(() => {
    //localStorage.setItem("activeManageTeamTab", activeTab);
    return () => {
      // Side-effect cleanup
    };
  }, [activeTab]);

  return (
    <Container className={"mb-5 px-lg-5 px-xl-5 px-3 PostInvestment"}>
      {!data && <Loading />}

      <div className="my-3">
        <Link
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size="32" color="#333333" /> Back
        </Link>
      </div>

      {data && (
        <div style={{ display: "flex" }}>
          <FundBadge
            fund={data}
            imageURL={data?.meta.logoURL}
            fontColor={data?.meta.fontColor}
            fundTitle={data?.meta.titleName || data?.name}
            tier={data?.meta.tier}
            imageCoverMode={"contain"}
          />
          <span className={"px-3"}>{data?.meta?.titleName}</span>
          <span className={"px-3"}>{data?.meta?.fundName}</span>
          <span className={"px-3"}>LP: {lpEntityCode}</span>
        </div>
      )}
      {!data && <Loading />}

      <div className="mt-3">
        <Nav>
          <NavItem>
            <NavLink
              className={activeTab === "Overview" ? "active" : ""}
              onClick={() => {
                setActiveTab("Overview");
              }}
            >
              Overview
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "Documents" ? "active" : ""}
              onClick={() => {
                setActiveTab("Documents");
              }}
            >
              Documents
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "HistoryOfTransactions" ? "active" : ""}
              onClick={() => {
                setActiveTab("HistoryOfTransactions");
              }}
            >
              History of Transactions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "WireTransfer" ? "active" : ""}
              onClick={() => {
                setActiveTab("WireTransfer");
              }}
            >
              Wire Transfer
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="Overview">
            <Fade>
              {data?.id && (
                <EditPostInvestmentComponent
                  meta={data.meta}
                  vcFundId={data?.id}
                  lpEntityCode={lpEntityCode}
                />
              )}
            </Fade>
          </TabPane>

          <TabPane tabId="Documents">
            <Fade>
              {data?.id && (
                <EditPostInvestmentDocumentComponent
                  meta={data.meta}
                  vcFundId={data?.id}
                  lpEntityCode={lpEntityCode}
                />
              )}
            </Fade>
          </TabPane>

          <TabPane tabId="HistoryOfTransactions">
            <Fade>
              <Container className="mt-5">History of Transactions</Container>
            </Fade>
          </TabPane>

          <TabPane tabId="WireTransfer">
            <Fade>
              <Container className="mt-5">Wire Transfer</Container>
            </Fade>
          </TabPane>
        </TabContent>
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(EditPostInvestmentViewComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
