import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  
  a {
    text-decoration: none !important;
    color: ${({ theme }) => theme.text} ;
  }
  
  a:hover {
    color: ${({ theme }) => theme.text} ;
  }

  .brand {
    color: ${({ theme }) => theme.text} ;
  }
  
  
  .bg-light {
    //background: ${({ theme }) => theme.body} !important;
    //color: ${({ theme }) => theme.text} !important;
    //border: 1px ${({ theme }) => theme.text} solid !important;
  }
  
  .bg-dark {
    background: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
    border: 1px ${({ theme }) => theme.text} solid !important;
  }
  
  .nav-link {
    color: ${({ theme }) => theme.text} !important ;
  }
  
  .navbar-dark {
    color: ${({ theme }) => theme.text} !important ;
    border: none !important;
  }
  
  td {
   color: ${({ theme }) => theme.text} !important ;
  }
  
  th {
   color: ${({ theme }) => theme.text} !important ;
  }
  
  .nav-tabs .nav-link.active{
     //color: ${({ theme }) => theme.text} !important ;
  }
  
  // .text-muted {
  //   color: ${({ theme }) => theme.muted} !important ;
  // }
  
  .pagination .bg-dark {
     //border: none !important;
  }

  .dropdown-item.active {
    color: ${({ theme }) => theme.muted} !important ;
  }
  

  .wmde-markdown-var {  
    --color-canvas-default: 'transparent' !important;
    --color-border-muted: 'transparent' !important;
    --color-border-default:  ${({ theme }) => theme.text} !important;
  }

  .wmde-markdown, .wmde-markdown-color {
    --color-canvas-default: 'transparent' !important;
    --color-border-muted: 'transparent' !important;
    --color-border-default:  ${({ theme }) => theme.text} !important;
  }
  
  .w-md-editor-text-pre {
     color: ${({ theme }) => theme.text} !important ;
     display: none !important
  }
  .w-md-editor-text-input {
       
       -webkit-text-fill-color:  ${({ theme }) => theme.text} !important ;

  }
  
  .w-md-editor-text {
      color:  ${({ theme }) => theme.text} !important ;
  }
  
  .reactstrap-confirm  {
     color:  ${({ theme }) => theme.active} !important ;
  }
`;
