import { useState } from "react";

const IconImage = ({ src, alt, fallback, className }) => {
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  return error ? (
    fallback
  ) : (
    <div className="">
      <img
        src={src}
        alt={alt}
        onError={onError}
        className={className}
        title={alt}
      />
      {alt}
    </div>
  );
};

export default IconImage;
