import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";
import VCFundService from "../service/VCFundService";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import "./PostInvestment.scss";

import { Fade } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import FundTitle from "../components/FundTitle";
import FundCommitmentProgressBar from "../components/PostInvestmentComponents/FundCommitmentProgressBar";
import PostInvestmentSelector from "../components/PostInvestmentComponents/PostInvestmentSelector";
import PostInvestmentDocumentComponent from "../components/PostInvestmentComponents/PostInvestmentDocumentComponent";

export const PostInvestmentComponent = () => {
  let { fundName } = useParams();

  const { getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState({});

  const [userToken, setUserToken] = useState(undefined);
  const [data, setData] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Overview");

  const loadData = async () => {
    const token = await getAccessTokenSilently();
    setUserToken(token);
    setLoading(true);
    setData(undefined);
    let d = await VCFundService.getFundByName(token, fundName);
    setData(d);

    let resp = await BaseService.getUser(token);
    if (resp) {
      setUser(resp.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, [fundName]);

  useEffect(() => {
    //localStorage.setItem("activeManageTeamTab", activeTab);
    return () => {
      // Side-effect cleanup
    };
  }, [activeTab]);

  return (
    <Container className={"mb-5  PostInvestment page-width h-100"}>
      {!data && <Loading />}
      {data && (
        <Fade triggerOnce className={"mb-0"}>
          <FundTitle
            fontColor={data?.meta.fontColor}
            imageURL={data?.meta.logoURL}
            badge={data?.fundCategory?.name}
            tier={data?.meta.tier}
            fundTitle={data?.meta.titleName || data?.name}
            backgroundColor={data?.meta.backgroundColor}
            base64ImgBuffer={data?.logoPhoto}
            imageCoverMode={"contain"}
          />
        </Fade>
      )}

      {data && (
        <div className="d-flex justify-content-end">
          <PostInvestmentSelector
            token={userToken}
            currentFundName={fundName}
          />
        </div>
      )}

      {data && (
        <div className="mt-3">
          <Nav>
            <NavItem>
              <NavLink
                className={activeTab === "Overview" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Overview");
                }}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "Documents" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Documents");
                }}
              >
                Documents
              </NavLink>
            </NavItem>
            {/* <NavItem>
            <NavLink
              className={activeTab === "HistoryOfTransactions" ? "active" : ""}
              onClick={() => {
                setActiveTab("HistoryOfTransactions");
              }}
            >
              History of Transactions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "WireTransfer" ? "active" : ""}
              onClick={() => {
                setActiveTab("WireTransfer");
              }}
            >
              Wire Transfer
            </NavLink>
          </NavItem> */}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="Overview">
              <Fade>
                <div className="mt-5">
                  {data && data?.meta && user?.meta && userToken && (
                    <FundCommitmentProgressBar
                      meta={data.meta}
                      token={userToken}
                      vcFundId={data?.id}
                      lpEntityCode={user?.meta?.defaultLPEntityCode}
                    />
                  )}
                </div>
              </Fade>
            </TabPane>

            <TabPane tabId="Documents">
              <Fade>
                <div className="mt-5">
                  {data && data?.meta && user?.meta && userToken && (
                    <PostInvestmentDocumentComponent
                      meta={data.meta}
                      token={userToken}
                      vcFundId={data?.id}
                      lpEntityCode={user?.meta?.defaultLPEntityCode}
                    />
                  )}
                </div>
              </Fade>
            </TabPane>

            <TabPane tabId="HistoryOfTransactions">
              <Fade>
                <div className="mt-5">History of Transactions</div>
              </Fade>
            </TabPane>

            <TabPane tabId="WireTransfer">
              <Fade>
                <div className="mt-5">Wire Transfer</div>
              </Fade>
            </TabPane>
          </TabContent>
        </div>
      )}
    </Container>
  );
};

export default withAuthenticationRequired(PostInvestmentComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
