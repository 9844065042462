import React, { Suspense, useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";
import { Fade } from "react-awesome-reveal";
import UserDashboard from "../components/UserDashboard";
import VCFundListing from "../components/VCFundListing";
import ReactGA from "react-ga4";
import DashboardService from "../service/DashboardService";
import NotionPageRenderer from "../components/NotionPageRenderer";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";

export const NewsComponent = () => {
  const [newsPageId, setNewsPageId] = useState(undefined);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const loadData = async () => {
    const token = await getAccessTokenSilently();
    let conf = await DashboardService.getConfiguration(token);

    let newsId = conf?.find(
      (e) => e.name.toLowerCase() === "dashboard-news-page-id"
    )?.meta?.value;
    setNewsPageId(newsId);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/portfolio-updates",
    });
    loadData();
  }, []);

  return (
    <Container className={"news-page"} fluid>
      <Suspense fallback={<Loading />}>
        <Fade triggerOnce cascade>
          <Container className={"page-width"}>
            {newsPageId && (
              <NotionPageRenderer
                disableHeader={true}
                pageId={newsPageId}
                maxResult={50}
              ></NotionPageRenderer>
            )}
          </Container>
        </Fade>
      </Suspense>
    </Container>
  );
};

export default withAuthenticationRequired(NewsComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
