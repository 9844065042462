import React, { useContext, useEffect, useRef, useState } from "react";
import { Badge, Col, Container, Row } from "reactstrap";
import { ArrowLeft } from "iconsax-react";
import { Fade, Slide } from "react-awesome-reveal";
import ReactGA from "react-ga4";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import FundTitle from "../components/FundTitle";
import VCFundService from "../service/VCFundService";
import { UserContext } from "../UserContext";
import BaseService from "../service/BaseService";
import MDComponent from "../components/MDComponent";
import FundProgressBar from "../components/FundProgressBar";
import "./FundReport.scss";
import Tiptap from "../components/Tiptap";
import ResponsiveEmbed from "react-responsive-embed";
import DDReport from "../components/DDReport";
import FundReportActions from "../components/FundReportActions";
import Linkify from "react-linkify";
import useScreenType from "react-screentype-hook";
import IconImage from "../components/IconImage";

export const FundReportComponent = (props) => {
  let { fundName } = useParams();
  const [data, setData] = useState(undefined);
  const [pageHistory, setPageHistory] = useState(0);
  const [lpProfiles, setLPProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [context] = useContext(UserContext);
  const screenType = useScreenType();

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setPageHistory(window.history.length);
    ReactGA.send({
      hitType: "pageview",
      page: "/fundpage",
    });
    loadData();
  }, [fundName]);

  const loadData = async () => {
    setLoading(true);
    let token = await getAccessTokenSilently();
    let resp2 = (await BaseService.getLPProfile(token)) || {};
    setLPProfiles(resp2);
    let d = await VCFundService.getFundByName(token, fundName);
    setData(d);

    await VCFundService.applyFundActionByName(
      token,
      fundName,
      context.meta.defaultLPEntityCode,
      "Visited",
      {}
    );
    setLoading(false);
  };

  const getValue = (value, label) => {
    if (!value) {
      return null;
    }
    return (
      <div className={"pb-4 pt-1"}>
        <div className={"text-uppercase text-lightly-muted  mb-1 label small"}>
          {label}
        </div>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target="blank"
              href={decoratedHref}
              key={key}
              className={"text-muted"}
            >
              {decoratedText}
            </a>
          )}
        >
          <span className={"text-muted"}> {value}</span>
        </Linkify>
      </div>
    );
  };

  const getStats = (attr, label) => {
    if (!data?.meta[attr]) {
      return null;
    }
    return getValue(data.meta[attr], label);
  };

  const getBadges = (attr, label) => {
    let badges = data?.meta[attr] || "";
    if (!badges) {
      return;
    }
    return (
      <div className={"pb-0"}>
        <div className={"text-uppercase text-lightly-muted small "}>
          {label}
        </div>
        <span>
          {badges.split(",").map((b) => {
            return (
              <span className={"p-0 mr-1 mb-0 text-muted"} key={b}>
                {b}
              </span>
            );
          })}
        </span>
      </div>
    );
  };

  const getIcons = (attr, label) => {
    let badges = data?.meta[attr] || "";
    if (!badges) {
      return;
    }
    return (
      <div className={"pb-4 pt-1"}>
        <div className={"text-uppercase text-lightly-muted small "}>
          {label}
        </div>
        <span>
          {badges.split(",").map((b) => {
            return (
              <IconImage
                alt={b}
                className={"pr-3 py-2 rounded"}
                src={`https://logo.clearbit.com/${b
                  .toLowerCase()
                  .trim()}.com?size=70&format=png&greyscale=true`}
                fallback={
                  <Badge
                    color="light"
                    className=" mr-3 py-2 text-align-center text-dark text-justify small"
                    key={b}
                    title={b}
                  >
                    {b}
                  </Badge>
                }
              ></IconImage>
            );
          })}
        </span>
      </div>
    );
  };

  return (
    <Container className={"mb-5 FundReport px-0"}>
      {!data && <Loading />}
      {data && (
        <Fade triggerOnce>
          <FundTitle
            fontColor={data?.meta.fontColor}
            imageURL={data?.meta.logoURL}
            badge={
              context.meta?.defaultLPType !== "ALP"
                ? data?.fundCategory?.name
                : undefined
            }
            secondaryBadge={
              data.fundRaisingStatus === "Closed"
                ? data?.fundRaisingStatus
                : undefined
            }
            tier={data?.meta.tier}
            fundTitle={data?.meta.titleName || data?.name}
            backgroundColor={data?.meta.backgroundColor}
            base64ImgBuffer={data?.logoPhoto}
            imageCoverMode={"contain"}
          />
        </Fade>
      )}
      {data && (
        <Fade triggerOnce>
          <Row className={"mt-5 mx-3 "}>
            <Col xs={12} sm={12} md={8} lg={8} className={"pb-5"}>
              <Link
                className={"mb-5"}
                onClick={() => {
                  setLoading(true);
                  let currentPageHistory = window.history.length;
                  let diff = (currentPageHistory - pageHistory) * -1 - 1;

                  navigate(diff);
                }}
              >
                <ArrowLeft size="32" color="#333333" /> Back
              </Link>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className={"pb-md-5 pb-4 px-0 "}>
              {data && (
                <div
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "100%",
                  }}
                >
                  <FundReportActions
                    dd={data?.dd}
                    spotsLeftLocation={"right"}
                    fund={data}
                    fundCategory={data.fundCategory}
                    tier={data?.meta.tier}
                    userId={context.id}
                    vcFundActions={data?.vcFundActions}
                    fundName={data?.name}
                    lpEntityCode={
                      context?.meta?.defaultLPEntityCode || lpProfiles[0]?.code
                    }
                    isALP={context?.meta?.defaultLPType === "ALP"}
                    vcFundId={data?.id}
                    align={"center"}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Fade>
      )}
      <Row className={"my-0 mx-3"}>
        <Col xs={12} sm={12} md={8} lg={8} className={"mb-1"}>
          {data?.meta.quote && (
            <Slide triggerOnce={true}>
              <div className={"mr-md-5 pb-5"}>
                <blockquote>
                  <h3 className={"pb-5"}>{data?.meta.quote}</h3>
                </blockquote>
              </div>
            </Slide>
          )}

          {data?.meta.overview && (
            <MDComponent readOnly={true} defaultValue={data?.meta.overview} />
          )}

          {data && data?.meta && (
            <FundProgressBar
              meta={data.meta}
              isALP={context?.meta?.defaultLPType === "ALP"}
              fundCategory={data.fundCategory}
            />
          )}
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className={"pl-md-3 pt-sm-3"}>
          {getStats("stage", "Stage")}
          {getStats("theme", "Theme")}
          {getStats("yearFounded", "Founded")}
          {getStats("hqLocation", "Location(s)")}
          {getStats("website", "Website")}
          {getBadges("iconicDeals", "Select Investments")}
          {/* {getIcons("iconicDeals", "Select Investments")} */}
        </Col>
      </Row>
      <Row className={"my-0 mx-3"}>
        <Col xs={12} sm={12} md={12} lg={12} className={"my-1"}>
          {data?.meta.investmentDetails && (
            <Tiptap editable={false} data={data?.meta.investmentDetails} />
          )}
        </Col>
      </Row>
      {data?.publicDigifyVideoGuid && (
        <Row className={"my-0 mx-3"}>
          <Col xs={12} sm={12} md={12} lg={12} className={""}>
            <ResponsiveEmbed
              allowFullScreen
              src={data.publicDigifyVideoGuid}
            ></ResponsiveEmbed>
          </Col>
        </Row>
      )}
      {data && !loading && (
        <Row className={"my-1 mx-md-3 mx-sm-0"}>
          <Col xs={12} sm={12} md={12} lg={12} className={"mb-5"}>
            <DDReport theFund={data} userId={context.id} context={context} />
          </Col>
        </Row>
      )}
      {/* <NotionPageRenderer /> */}
    </Container>
  );
};

export default withAuthenticationRequired(FundReportComponent, {
  onRedirecting: () => <Loading />,
});
