import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  return (
    <footer id="footer">
      <Container fluid>
        <Row className="row py-2 px-5">
          <Col
            sm={"12"}
            md={"12"}
            lg={"6"}
            xl={"6"}
            className={`${isMobile ? "text-center" : ""} py-1`}
          >
            <p className="small text-muted mb-0">© 2024 V0 Inc.</p>
          </Col>

          <Col
            sm={"12"}
            md={"12"}
            lg={"6"}
            xl={"6"}
            className={`${isMobile ? "text-center" : "text-right"}  py-1`}
          >
            <strong>Get in touch: </strong>
            <a
              href="mailto:contact@v0invest.com"
              className={`small text-muted`}
            >
              contact@v0invest.com
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
