import BaseService from "./BaseService";

class DashboardService {
  static async system(token) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/system`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async getFundCategory(token) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/fundcategory`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }
  static async getConfiguration(token) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/configuration`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async getConfigurationByName(token, name) {
    let resp = await this.getConfiguration(token);
    if (!resp) {
      return undefined;
    }
    return (resp || []).find((r) => r.name === name);
  }

  static async editConfiguration(token, id, data) {
    let d = JSON.stringify({ ...data, id: id });
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/configuration/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async addConfiguration(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/configuration`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async deleteConfiguration(token, id) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/configuration/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      credentials: "include",
    });
    return response.statusText;
  }

  static async editFundCategory(token, id, data) {
    let d = JSON.stringify({ ...data, id: id });
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/fundCategory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async addFundCategory(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/fundCategory`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async deleteFundCategory(token, id) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/fundCategory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      credentials: "include",
    });
    return response.statusText;
  }
}

export default DashboardService;
