import React, { useEffect, useState } from "react";
import {
  Container,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardSubtitle,
  Row,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Link1 } from "iconsax-react";
import BaseService from "../service/BaseService";
import { Link } from "react-router-dom";
import Loading from "./Loading";
const moment = require("moment");
export default function UserSteps(props) {
  const { user, lpProfiles = [] } = props;

  return (
    <UncontrolledAccordion flush defaultOpen={["user"]} stayOpen>
      <AccordionItem>
        <AccordionHeader targetId="user">User Information</AccordionHeader>
        <AccordionBody accordionId="user">
          <h2>
            {user.given_name} {user.family_name}
          </h2>
          <span className={"text-muted"}>{user.email}</span>
          <div className={"text-muted"}>
            Member Since: {moment(user.meta?.joined).format("MMM DD, YYYY")}
          </div>
        </AccordionBody>
      </AccordionItem>

      {lpProfiles.map((lp, index) => {
        return (
          <AccordionItem key={lp.companyName}>
            <AccordionHeader targetId={index.toString()}>
              {lp.companyName}
            </AccordionHeader>
            <AccordionBody accordionId={index.toString()}>
              <div>
                <div>Phone: {lp.phone}</div>
              </div>
              {/* <div>
                      <div>Credits: {lp.meta?.credits}</div>
                    </div> */}
              {/* Expires:{" "}
                    {moment(lp.meta.subscription.expiredAt).format(
                      "MMM DD, YYYY"
                    )} */}
              {lp.dd?.length > 0 && (
                <>
                  <div tag="h5" className="text-muted mt-3">
                    Due Diligence
                  </div>
                  <div>
                    <ul className="list-unstyled m-auto">
                      {lp.dd.map((dd, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/fund-detail/${dd.name}`}>
                              <Link1
                                size="35"
                                color="#3e7cb1"
                                className={"mr-3 mb-1"}
                              />
                              {dd.name} -
                              {BaseService.getDateString(dd.createdAt)}{" "}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </AccordionBody>
          </AccordionItem>
        );
      })}

      <AccordionItem>
        <AccordionHeader targetId="tos">Terms of Use</AccordionHeader>
        <AccordionBody accordionId="tos">
          <strong>TOS 2023-08-01</strong>
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="nda">NDA</AccordionHeader>
        <AccordionBody accordionId="nda">
          <strong>NDA 2023-08-01</strong>
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="payment">
          Payment Information
        </AccordionHeader>
        <AccordionBody accordionId="payment">
          <strong>Wire Transfer Information</strong>
        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  );
}
