import React from "react";
import { Badge, Col, Row } from "reactstrap";
import BufferImage from "./BufferImage";
import { Placeholder, PlaceholderButton } from "react-bootstrap";
import logo from "../assets/logo.png";
import "./FundTile.scss";

const Buffer = require("buffer/").Buffer;

export default function FundTile(props) {
  let {
    fund = {},
    badge = "",
    secondaryBadge = "",
    subtitle = "",
    fundTitle = "",
    base64ImgBuffer,
    widthMode = false,
    imageCoverMode,
    backgroundColor = "#FFF",
    fontColor = "#000",
    imageURL = "",
    tier = "",
  } = props;

  let base64Img;
  if (base64ImgBuffer) {
    base64Img = Buffer.from(base64ImgBuffer).toString("utf8");
  }
  if (
    fund?.meta?.tier?.toString() === "1" ||
    fund?.meta?.tier?.toString() === "2"
  ) {
    backgroundColor = fund.meta.backgroundColor;
    fontColor = fund.meta.fontColor || "#CCC";
  }

  const getImage = (title) => {
    let hasBadge =
      badge && (tier.toString() === "1" || tier.toString() === "2");

    if (imageURL) {
      return (
        <img
          alt={fundTitle}
          className={`p-0 img  ${widthMode ? "lower" : ""}
          ${tier?.toString() === "6" ? " tier6-img " : ""}`}
          src={imageURL}
          style={{ flex: 1 }}
        />
      );
    } else if (base64Img) {
      return (
        <BufferImage
          size={200}
          base64ImgBuffer={base64ImgBuffer}
          imageCoverMode={imageCoverMode}
        />
      );
    } else {
      return <h6 className={"m-auto"}>{title}</h6>;

      //return <img alt={fundTitle} className={"p-2 img"} src={logo} />;
    }
  };

  return (
    <div className="p-0 m-0">
      <div
        className={`p-0 m-0  m-auto  FundTile ${
          !subtitle ? "border" : "border"
        }`}
        style={{
          backgroundColor: backgroundColor,
          color: fontColor,
          width: widthMode ? "325px" : "300px",
        }}
      >
        {(tier.toString() === "1" || tier.toString() === "2") && (
          <div style={{ flex: 1, position: "absolute", zIndex: 100 }}>
            <Col className="TierBadgeContainer">
              {badge && (
                <Badge
                  className={`mb-2 TierBadge ${
                    tier.toString() === "1" && "Tier1Badge"
                  }`}
                  pill
                >
                  {badge}
                </Badge>
              )}

              {secondaryBadge && (
                <Badge className={`TierBadge ClosedBadge`} pill>
                  {secondaryBadge}
                </Badge>
              )}
            </Col>
          </div>
        )}

        {getImage(fundTitle)}
      </div>

      <span
        className={
          "py-2 my-2 small text-light text-muted text-center w-100 d-block"
        }
      >
        {subtitle}
      </span>
    </div>
  );
}
