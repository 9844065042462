import React, { useEffect } from "react";
import { Container, Table } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";
import FundBadge from "./FundBadge";
import "./AppliedFundListing.scss";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ArrowDown, ArrowRight } from "iconsax-react";
import { Fade } from "react-awesome-reveal";

export default function AppliedFundListing(props) {
  const {
    subtitle = "",
    title = "",
    funds = [],
    type,
    emptyComponent = undefined,
    showBadge = true,
  } = { ...props };

  const smBP = props.smBP || 576;
  const navigate = useNavigate();
  const getBadge = (data) => {
    return (
      <FundBadge
        size="Normal"
        fund={data}
        imageURL={data?.meta.logoURL}
        fontColor={data?.meta.fontColor}
        fundTitle={data?.meta.titleName || data?.name}
        tier={data?.meta.tier}
        imageCoverMode={"contain"}
      />
    );
  };

  const getClosingDate = (fund) => {
    let text;
    if (fund?.meta?.closingDate) {
      text =
        "Closing on " +
        BaseService.getDateString(fund?.meta?.closingDate) +
        ".  ";
    }
    return text;
  };

  const getMobileText = (row) => {
    let data = row.fund;

    if (type === "Committed") {
      return (
        <div className="d-block d-sm-none  pt-1 info-span details  p-0">
          <span className="pr-1">
            Date Committed: {BaseService.getDateString(row.createdAt)}
          </span>
          <span className="pr-1">
            {row.meta.amount ? BaseService.formatMoney(row.meta.amount) : ""}
          </span>
        </div>
      );
    } else if (type === "PostInvestment") {
      return (
        <div className="d-block d-sm-none py-2 pt-4">
          <Container fluid>
            <Row className="PostInvestmentHeader">
              <Col>
                Vintage
                <br />
                Year
              </Col>
              <Col>Committed</Col>
              <Col>Net Asset</Col>
            </Row>
            <Row className="PostInvestmentRow">
              <Col>
                {row.fund?.postInvestmentOverview?.overviewMeta?.vintageYear}
              </Col>
              <Col>
                {BaseService.formatMoney(
                  row.fund?.postInvestmentOverview?.overviewMeta?.committed
                )}
              </Col>
              <Col>
                {BaseService.formatMoney(
                  row.fund?.postInvestmentOverview?.overviewMeta?.netAssetValue
                )}
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (type === "DecisionPending") {
      return (
        <div className="d-flex-row d-flex pt-1 ">
          <div className="d-block d-sm-none  info-span details pr-1 pl-0">
            {getClosingDate(data)}
            {data.meta.spotsLeftMessage && <>{data.meta.spotsLeftMessage}</>}
          </div>
        </div>
      );
    } else if (type !== "Committed") {
      return (
        <div className={"d-block d-sm-none pt-1 details  info-span p-0"}>
          {BaseService.getDateString(row.createdAt)}
        </div>
      );
    }
  };

  const getColumns = () => {
    const columns = [
      {
        name: "Fund",
        grow: 0,
        style: {
          alignItems: "flex-start",
        },
        compact: false,
        selector: (row) => {
          let data = row.fund;
          return <div className="pl-0 py-2 pe-none">{getBadge(data)}</div>;
        },
        sortable: false,
      },

      {
        compact: true,
        name: " ",
        grow: 3,
        selector: (row) => {
          let data = row.fund;
          return (
            <div className="py-2 d-flex pe-none opacity-100">
              <div className={"fundDetails d-flex opacity-100"}>
                <div className="d-flex-row">
                  <span className={"fundName"}>{data.meta.titleName}</span>
                </div>
                {showBadge && (
                  <div className="d-flex">
                    {(data?.meta?.tier?.toString() === "1" ||
                      data?.meta?.tier?.toString() === "2") && (
                      <Badge
                        className={`mr-1 TierBadge mt-1 ${
                          data?.meta?.tier?.toString() === "1" && "Tier1Badge"
                        }`}
                        pill
                      >
                        {data?.fundCategory?.name}
                      </Badge>
                    )}
                  </div>
                )}
                {getMobileText(row)}
              </div>
            </div>
          );
        },
        sortable: false,
      },
    ];

    const columnWaitlisted = columns.concat([
      {
        hide: smBP,
        compact: true,
        name: "Date",
        format: (row) => BaseService.getDateString(row.createdAt),
        selector: (row) => row.createdAt,
        sortable: true,
      },
    ]);

    const columnsCommitted = columns.concat([
      {
        hide: smBP,
        grow: 2,
        compact: true,
        name: "Date Committed",
        format: (row) => BaseService.getDateString(row.createdAt),
        selector: (row) => row.createdAt,
        sortable: true,
      },
      {
        hide: smBP,
        right: true,
        compact: false,
        name: "Amount ",
        format: (row) => BaseService.formatMoney(row.meta?.amount),
        selector: (row) => row.meta?.amount,
        sortable: true,
      },
    ]);

    const columnsDecisionPending = columns.concat({
      name: "",
      grow: 3,
      right: true,
      hide: smBP,
      compact: true,
      sortable: false,
      selector: (row) => {
        let closingText = "",
          spotsLeftText = "";
        if (row.fund?.meta?.closingDate) {
          closingText = getClosingDate(row.fund);
        }
        if (row.fund?.meta?.spotsLeftMessage) {
          spotsLeftText = row.fund?.meta?.spotsLeftMessage;
        }

        return (
          <div className="info-span pr-1 pl-0 text-right d-flex pe-none pr-3">
            {closingText && <span className="pl-1">{closingText}</span>}
            {spotsLeftText && <span className="pl-1"> {spotsLeftText}</span>}
          </div>
        );
      },
    });

    const columnsIntroduced = columns.concat([
      {
        hide: smBP,
        compact: true,
        name: "Date",
        format: (row) => BaseService.getDateString(row.createdAt),
        selector: (row) => row.createdAt,
        sortable: true,
      },
    ]);

    const columnsPostInvestment = columns.concat([
      {
        hide: smBP,
        compact: true,
        minWidth: "140px",
        name: "Vintage Year",
        selector: (row) =>
          row.fund?.postInvestmentOverview?.overviewMeta?.vintageYear,
        sortable: true,
      },
      {
        hide: smBP,
        compact: true,
        minWidth: "130px",
        name: "Committed",
        format: (row) =>
          BaseService.formatMoney(
            row.fund?.postInvestmentOverview?.overviewMeta?.committed
          ),
        selector: (row) =>
          row.fund?.postInvestmentOverview?.overviewMeta?.committed,

        sortable: true,
      },
      {
        hide: smBP,
        minWidth: "120px",
        compact: true,
        name: "Net Asset",

        format: (row) =>
          BaseService.formatMoney(
            row.fund?.postInvestmentOverview?.overviewMeta?.netAssetValue
          ),
        selector: (row) =>
          row.fund?.postInvestmentOverview?.overviewMeta?.netAssetValue,

        sortable: true,
      },
    ]);

    columnsPostInvestment[1].grow = 2;

    if (type === "Committed") {
      return columnsCommitted;
    } else if (type === "Waitlisted") {
      return columnWaitlisted;
    } else if (type === "DecisionPending") {
      return columnsDecisionPending;
    } else if (type === "Introduced") {
      return columnsIntroduced;
    } else if (type === "PostInvestment") {
      return columnsPostInvestment;
    }

    return columns;
  };

  const navToFund = (row) => {
    if (row.fund.postInvestmentOverview) {
      navigate(`/dashboard/post-investment/${row.fund.name}`);
    } else {
      navigate(`/fund-detail/${row.fund.name}`);
    }
  };
  if (funds.length === 0 && !emptyComponent) {
    return null;
  }

  return (
    <Container fluid className={"pb-5 AppliedFundListing"}>
      <Fade cascade triggerOnce>
        {subtitle && <span className={"subtitle my-5"}>{subtitle}</span>}
        <h4 className={"title"}>{title}</h4>
        {funds.length > 0 ? (
          <DataTable
            onRowClicked={(row) => {
              navToFund(row);
            }}
            className="cursor-pointer"
            dense
            responsive={true}
            highlightOnHover={true}
            columns={getColumns()}
            data={funds}
            sortIcon={<ArrowDown className="mx-3" />}
          />
        ) : (
          <span className={"py-0 empty"}>{emptyComponent}</span>
        )}
      </Fade>
    </Container>
  );
}
