import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { Form, Field } from "react-final-form";
import { useAuth0 } from "@auth0/auth0-react";
import UserService from "../service/UserService";
import FormStyles from "./FormStyles";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import BaseService from "../service/BaseService";
import DataTable from "react-data-table-component";
import LPEntityService from "../service/LPEntityService";
import {
  AddCircle,
  ArrowDown,
  Menu,
  Pause,
  Refresh,
  TextalignJustifycenter,
} from "iconsax-react";
import { ButtonGroup, ButtonToolbar, InputGroup } from "react-bootstrap";

export default function UserTable(props) {
  const { getAccessTokenSilently } = useAuth0();

  const { editEnabled = false } = props;
  const [data, setData] = useState([]);
  const [lps, setLPs] = useState([]);
  const [filter, setFilter] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [isInviting, setIsInviting] = useState(false);
  const [user, setUser] = useState(undefined);
  const [modal, setModal] = useState(false);

  const close = () => {
    setUser(undefined);
    setModal(false);
  };

  const inviteUser = () => {
    setIsInviting(true);
    setUser({});
    setModal(true);
  };

  const editUser = (data) => {
    setIsInviting(false);
    setUser(data);
    setModal(true);
  };

  const saveUser = async (data) => {
    try {
      const token = await getAccessTokenSilently();
      if (user.id) {
        await UserService.editUser(token, user.id, data);
      } else {
        // check for isInviting
        if (isInviting) {
          data.isInviting = true;
        }
        await UserService.addUser(token, data);
      }
      loadData();
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const deleteUser = async (id) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const token = await getAccessTokenSilently();
              await UserService.deleteUser(token, id);
              loadData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const sendInvitation = async (id) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const token = await getAccessTokenSilently();
              await UserService.inviteUser(token, id, false);
              loadData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const sendInvitationCode = async (id) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const token = await getAccessTokenSilently();
              await UserService.inviteUser(token, id, true);
              loadData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };
  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await UserService.getAll(token);
      resp.map((d) => {
        d.lpFormatted = (d.lps || []).map((lp) => {
          return lp.companyName;
        });
      });

      let lps = await LPEntityService.getAll(token);
      setLPs(lps);
      setData(resp);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  useEffect(() => {
    let filteredData = data;
    if (filter.lpEntityCode) {
      filteredData = data.filter((d) => {
        let found = false;

        (d.lps || []).forEach((lp) => {
          if (lp.code === filter.lpEntityCode) {
            found = true;
          }
        });

        return found;
      });
    }

    if (filter.userType) {
      if (filter.userType === "VT") {
        filteredData = filteredData.filter((d) => {
          return d.type === filter.userType;
        });
      } else if (filter.userType === "LP") {
        filteredData = filteredData.filter((d) => {
          return d.type === filter.userType;
        });
      } else if (filter.userType === "FO") {
        filteredData = filteredData.filter((d) => {
          let found = false;
          (d.lps || []).forEach((lp) => {
            if (lp.meta.type === "FO") {
              found = true;
            }
          });
          return found;
        });
      } else if (filter.userType === "ALP") {
        filteredData = filteredData.filter((d) => {
          let found = false;
          (d.lps || []).forEach((lp) => {
            if (lp.meta.type === "ALP") {
              found = true;
            }
          });
          return found;
        });
      }
    }

    // FO and ALP, need to check the associated LP Metadata

    if (filter.userStatus) {
      filteredData = filteredData.filter((d) => {
        return d.status === filter.userStatus;
      });
    }

    if (filter.query) {
      filteredData = filteredData.filter((d) => {
        return (
          d.firstName?.toLowerCase().includes(filter.query.toLowerCase()) ||
          d.lastName?.toLowerCase().includes(filter.query.toLowerCase()) ||
          d.email?.toLowerCase().includes(filter.query.toLowerCase())
        );
      });
    }
    setFilteredData(filteredData);
  }, [data, filter]);

  const columns = [
    {
      grow: 2,
      name: "Name",
      selector: (row) => (row.firstName || "") + "  " + (row.lastName || ""),
      sortable: true,
      allowOverflow: false,
    },

    {
      grow: 3,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      allowOverflow: true,
    },
    {
      name: "Status",
      compact: true,
      width: "80px",
      selector: (row) => <Badge pill>{row.status}</Badge>,
      grow: 0,
      sortable: false,
    },
    {
      name: "Type",
      compact: true,
      selector: (row) => <Badge pill>{row.type}</Badge>,
      grow: 0,
      width: "50px",
      allowOverflow: false,
      sortable: false,
    },
    {
      name: "LP",
      selector: (row) => {
        return (
          <div className="column">
            {(row.lpFormatted || []).map((lp) => (
              <div>
                <Badge key={lp} color="primary" pill>
                  {lp}
                </Badge>
              </div>
            ))}
          </div>
        );
      },
      minWidth: "150px",
    },
    {
      name: "Last Updated",
      format: (row) => BaseService.getDateString(row.updatedAt),
      selector: (row) => row.updatedAt,
      sortable: true,
      width: "180px",
    },
    {
      name: "Joined",
      format: (row) => BaseService.getDateString(row.meta?.joined),
      selector: (row) => row.meta?.joined,
    },
  ];

  if (editEnabled) {
    columns.push({
      name: "",
      ignoreRowClick: true,
      selector: (d) => {
        return (
          <UncontrolledDropdown className="rounded z-3">
            <DropdownToggle color={""}>
              <TextalignJustifycenter />
            </DropdownToggle>
            <DropdownMenu className="btn-light">
              <DropdownItem
                onClick={() => {
                  editUser(d);
                }}
              >
                Edit
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  deleteUser(d.id);
                }}
              >
                Delete
              </DropdownItem>

              {d.status !== "ACTIVE" && d.lps?.length > 0 && (
                <DropdownItem
                  onClick={() => {
                    sendInvitation(d.id);
                  }}
                >
                  Send Invitation
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    });
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
  );

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        toggle={close}
        fullscreen={true}
        backdrop={true}
        fade={true}
        size={"xl"}
        scrollable={true}
      >
        <ModalHeader toggle={close}>
          {user?.id ? "Edit User" : "Add User"}
        </ModalHeader>
        <ModalBody>
          {user && (
            <FormStyles>
              <Form
                onSubmit={async (values, { setSubmitting }) => {
                  await saveUser(values);
                  setModal(false);
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  }
                  if (!values.firstName) {
                    errors.firstName = "Required";
                  }
                  if (!values.lastName) {
                    errors.lastName = "Required";
                  }
                  return errors;
                }}
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  type: isInviting ? "LP" : user.type,
                  status: user.status,
                  meta: user.meta,
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <h3>Basic Information</h3>

                    <div className={"form-fields"}>
                      <label>First Name</label>
                      <Field name="firstName" component="input" type="text" />
                    </div>
                    <div className={"form-fields"}>
                      <label>Last Name</label>
                      <Field name="lastName" component="input" type="text" />
                    </div>
                    <div className={"form-fields"}>
                      <label>Email</label>
                      <Field name="email" component="input" type="email" />
                    </div>
                    <div className={"form-fields"}>
                      <label>Type</label>
                      <Field
                        name="type"
                        component="select"
                        disabled={isInviting}
                      >
                        <option value="" />
                        <option value="VT">VT</option>
                        <option value="LP">LP</option>
                      </Field>
                    </div>
                    {!isInviting && (
                      <div className={"form-fields"}>
                        <label>Status</label>
                        <Field name="status" component="select">
                          <option value="" />
                          <option value="INVITED">INVITED</option>
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="BLOCKED">BLOCKED</option>
                        </Field>
                      </div>
                    )}

                    <h3>Membership</h3>

                    <div className={"form-fields"}>
                      <label>Joined</label>
                      <Field name="meta.joined" component="input" type="date" />
                    </div>

                    <div className={"form-fields"}>
                      <label>Level</label>
                      <Field
                        name="meta.subscription.level"
                        component="input"
                        type="text"
                      />
                    </div>

                    <div className="buttons">
                      <button type="submit" disabled={submitting}>
                        {user?.id ? "Save" : isInviting ? "Invite" : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={form.reset}
                        disabled={submitting}
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              />
            </FormStyles>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {editEnabled && (
        <Container className="pt-2">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  loadData();
                }}
              >
                <Refresh color="#FFF" />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  let currentFilter = { ...filter };
                  currentFilter.usePagination = !currentFilter.usePagination;
                  setFilter(currentFilter);
                }}
              >
                <Pause color={filter.usePagination ? "#FFF" : "grey"} />
              </Button>
            </ButtonGroup>
            {/* <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  inviteUser();
                }}
              >
                Invite User
              </Button>
            </ButtonGroup> */}
            <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  editUser({});
                }}
              >
                <AddCircle />
              </Button>
            </ButtonGroup>

            <ButtonGroup className="me-2">
              <Export
                onExport={() =>
                  BaseService.downloadCSV(
                    filteredData.map(({ lps, ...keepAttrs }) => keepAttrs)
                  )
                }
              />
            </ButtonGroup>

            <InputGroup className="me-2">
              <Input
                placeholder="Search by text"
                inputMode={"text"}
                value={filter.query}
                onChange={async (e) => {
                  let currentFilter = { ...filter };
                  currentFilter.query = e.target.value;
                  setFilter(currentFilter);
                }}
              />
            </InputGroup>

            <ButtonGroup className="me-2">
              <UncontrolledDropdown>
                <DropdownToggle caret color={"primary"}>
                  LP{" "}
                  {filter?.lpEntityCode
                    ? `- ${filter.lpEntityCode}`
                    : " - Show All"}
                </DropdownToggle>
                <DropdownMenu className="btn-light">
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.lpEntityCode = undefined;
                      setFilter(currentFilter);
                    }}
                  >
                    Show All
                  </DropdownItem>

                  {lps.map((d, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          let currentFilter = { ...filter };
                          currentFilter.lpEntityCode = d.code;
                          setFilter(currentFilter);
                        }}
                      >
                        {d.code}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>

            <ButtonGroup className="me-2">
              <UncontrolledDropdown>
                <DropdownToggle caret color={"primary"}>
                  Status{" "}
                  {filter?.userStatus
                    ? `- ${filter.userStatus}`
                    : " - Show All"}
                </DropdownToggle>
                <DropdownMenu className="btn-light">
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userStatus = undefined;
                      setFilter(currentFilter);
                    }}
                  >
                    Show All
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userStatus = "ACTIVE";
                      setFilter(currentFilter);
                    }}
                  >
                    ACTIVE
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userStatus = "INVITED";
                      setFilter(currentFilter);
                    }}
                  >
                    INVITED
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userStatus = "BLOCKED";
                      setFilter(currentFilter);
                    }}
                  >
                    BLOCKED
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <UncontrolledDropdown>
                <DropdownToggle caret color={"primary"}>
                  Type{" "}
                  {filter?.userType ? `- ${filter.userType}` : " - Show All"}
                </DropdownToggle>
                <DropdownMenu className="btn-light">
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userType = undefined;
                      setFilter(currentFilter);
                    }}
                  >
                    Show All
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userType = "LP";
                      setFilter(currentFilter);
                    }}
                  >
                    Show LP (FO and ALP)
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userType = "FO";
                      setFilter(currentFilter);
                    }}
                  >
                    Show FO
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userType = "ALP";
                      setFilter(currentFilter);
                    }}
                  >
                    Show ALP
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.userType = "VT";
                      setFilter(currentFilter);
                    }}
                  >
                    Show VT
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </ButtonToolbar>
        </Container>
      )}
      <DataTable
        highlightOnHover
        data={filteredData}
        columns={columns}
        dense={false}
        responsive={true}
        sortIcon={<ArrowDown className="mx-3" />}
        pagination={filter.usePagination}
      />
      {!filter.usePagination && (
        <div className=" ml-2 mt-1 label">Count: {filteredData.length}</div>
      )}
    </Fragment>
  );
}
