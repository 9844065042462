import * as Sentry from "@sentry/react";
import { useConsent, ConsentBanner, ConsentProvider } from "react-hook-consent";
import "react-hook-consent/dist/styles/style.css";

import ReactGA from "react-ga4";
import { useCookies } from "react-cookie";
import { Container, Col, Button, Row } from "reactstrap";

import {
  consent as fsConsent,
  init as initFullStory,
  isInitialized as isFullStoryInitialized,
  shutdown as fullstoryShutdown,
} from "@fullstory/browser";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const AppCookieBanner = (props) => {
  const { toggleBanner, hasConsent, toggleDetails } = useConsent();

  const loadAnalytics = () => {
    ReactGA.initialize("354040281", {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: "",
      },
    });

    if (!isFullStoryInitialized()) {
      initFullStory({ orgId: "o-1PNF5A-na1" });
      fsConsent(true);
    }

    let sentryInitialized = Sentry.getCurrentHub().getClient();

    if (!sentryInitialized) {
      Sentry.init({
        dsn: "https://1baca23b821945f9b731666683d1badf@o4505364986920960.ingest.sentry.io/4505557208530944",
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        beforeSend: (event) => {
          if (window.location.hostname === "localhost") {
            return null;
          }

          return event;
        },
        // Performance Monitoring
        tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  };

  useEffect(() => {
    if (hasConsent("cookie-analytic")) {
      loadAnalytics();
    } else {
      let sentryInitialized = Sentry.getCurrentHub().getClient();

      if (!isFullStoryInitialized()) {
        initFullStory({ orgId: "o-1PNF5A-na1" });
        fsConsent(false);
      }

      if (sentryInitialized) {
        // I have no choice to clean out sentry and FS
        window.location.reload(false);
      }
    }
  }, [hasConsent]);

  return (
    <div>
      {props.showButton && (
        <>
          <Button
            className="primary-button base-button my-5"
            onClick={() => {
              toggleDetails();
            }}
          >
            Modify Cookie Preferences
          </Button>
        </>
      )}

      <ConsentBanner
        settings={{
          label: "Preference",
          modal: {
            title: "Your Cookie Preferences",
            description:
              "We use different types of cookies to optimize your experience on our website.  You may choose which types of cookies to allow and can change your preferences at any time. Remember that disabling cookies may affect your experience on the website. You can learn more about how we use cookies by visiting our Cookie Policy.",
            decline: { hidden: true },
          },
        }}
        decline={{ show: false, label: "No", hidden: true }}
        approve={{ label: "Yes" }}
      >
        <>
          We use essential cookies to make our site work. With your consent, we
          may also use non-essential cookies to improve user experience and
          analyze website traffic. By clicking “Accept,“ you agree to our
          website's cookie use as described in our Cookie Policy. You can change
          your cookie settings at any time by clicking "Preferences" or by going
          to your "Profile".
        </>
      </ConsentBanner>
    </div>
  );
};

export default AppCookieBanner;
