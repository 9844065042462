import React, { useEffect, useState, Fragment } from "react";
import "./Stars.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";

const Heading = (props) => {
  return (
    <Container className={"mt-0 mb-2 px-3   pb-3  mx-auto "}>
      <Row className={"py-5 px-1"}>
        <Col
          className={"pt-5"}
          lg={{
            offset: 0,
            size: 12,
          }}
          md={{
            offset: 0,
            size: 12,
          }}
          sm="12"
          xs="12"
        >
          <div className="py-0 px-0">
            <Fade cascade triggerOnce>
              <span>{props.subtitle}</span>
              <h1 className={props.className}>{props.title}</h1>
            </Fade>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Heading;
