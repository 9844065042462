import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { Form, Field } from "react-final-form";
import { useAuth0 } from "@auth0/auth0-react";
import FormStyles from "./FormStyles";
import DashboardService from "../service/DashboardService";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { AddCircle, Menu, TextalignJustifycenter } from "iconsax-react";

export default function ConfigurationTable(props) {
  const { getAccessTokenSilently } = useAuth0();

  const { editEnabled = false } = props;
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [row, setRow] = useState(undefined);

  const close = () => {
    setModal(false);
  };

  const edit = (data) => {
    setRow(data);
    setModal(true);
  };

  const save = async (data) => {
    let d = {
      name: data.name,
      meta: {
        value: data.value,
        type: data.type,
      },
      public: data.public,
    };

    try {
      const token = await getAccessTokenSilently();
      if (row.id) {
        await DashboardService.editConfiguration(token, row.id, d);
      } else {
        await DashboardService.addConfiguration(token, d);
      }
      loadData();
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const deleteRow = async (id) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const token = await getAccessTokenSilently();
              await DashboardService.deleteConfiguration(token, id);
              loadData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await DashboardService.getConfiguration(token);
      setData(resp);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        toggle={close}
        fullscreen={false}
        backdrop={true}
        fade={true}
        size={"xl"}
        scrollable={true}
      >
        <ModalHeader toggle={close} className="bg-light">
          {row?.id ? "Edit" : "Add"}
        </ModalHeader>
        <ModalBody>
          {row && (
            <FormStyles>
              <Form
                onSubmit={async (values, { setSubmitting }) => {
                  await save(values);
                  setModal(false);
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Required";
                  }

                  return errors;
                }}
                initialValues={{
                  name: row.name,
                  value: row.meta?.value,
                  type: row.meta?.type,
                  public: row.public,
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={"form-fields"}>
                      <label>Name</label>
                      <Field name="name" component="input" type="text" />
                    </div>
                    <div className={"form-fields"}>
                      <label>Public</label>
                      <Field name="public" component="input" type="checkbox" />
                    </div>
                    <div className={"form-fields"}>
                      <label>Type</label>
                      <Field name="type" component="select">
                        <option value="" />
                        <option value="string">String</option>
                        <option value="number">Number</option>
                        <option value="json">JSON</option>
                        <option value="date">Date</option>
                        <option value="boolean">Boolean</option>
                      </Field>
                    </div>

                    {values.type === "string" && (
                      <div className={"form-fields"}>
                        <label>Value</label>
                        <Field name="value" component="input" type="text" />
                      </div>
                    )}

                    {values.type === "number" && (
                      <div className={"form-fields"}>
                        <label>Value</label>
                        <Field name="value" component="input" type="number" />
                      </div>
                    )}

                    {values.type === "boolean" && (
                      <div className={"form-fields"}>
                        <label>Value</label>
                        <Field name="value" component="input" type="checkbox" />
                      </div>
                    )}
                    {values.type === "date" && (
                      <div className={"form-fields"}>
                        <label>Value</label>
                        <Field
                          name="value"
                          component="input"
                          type="datetime-local"
                        />
                        <div>PT</div>
                      </div>
                    )}

                    {values.type === "json" && (
                      <div className={"form-fields"}>
                        <label>Value</label>
                        <Field
                          name="value"
                          render={({ input, meta }) => (
                            <div className={"form-fields"} style={{ flex: 1 }}>
                              <textarea
                                {...input}
                                rows="10"
                                style={{ flex: 1 }}
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}

                    <div className="buttons">
                      <button type="submit" disabled={submitting}>
                        {row?.id ? "Save" : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={form.reset}
                        disabled={submitting}
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              />
            </FormStyles>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {editEnabled && (
        <Button
          color="primary"
          className={"m-1"}
          onClick={() => {
            edit({});
          }}
        >
          <AddCircle />
        </Button>
      )}

      <Table size="sm" responsive={true}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th>Type</th>
            <th>Public</th>

            {editEnabled && <th></th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => {
            return (
              <tr key={index}>
                <td>{d.name}</td>
                <td>{JSON.stringify(d.meta?.value)}</td>
                <td>{d.meta?.type}</td>
                <td>{d?.public ? "True" : "False"}</td>

                {editEnabled && (
                  <td>
                    <UncontrolledDropdown className="rounded">
                      <DropdownToggle color={""}>
                        <TextalignJustifycenter />
                      </DropdownToggle>
                      <DropdownMenu className="btn-light">
                        <DropdownItem
                          onClick={() => {
                            edit(d);
                          }}
                        >
                          Edit
                        </DropdownItem>

                        <DropdownItem
                          onClick={() => {
                            deleteRow(d.id);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}
