import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Progress, Row, Input } from "reactstrap";
import "./FundCommitmentProgressBar.scss";

import BaseService from "../../service/BaseService";
import { Field, Form } from "react-final-form";
import FormStyles from "../FormStyles";
import NumericInput from "react-numeric-input";
import PostInvestmentService from "../../service/PostInvestmentService";
import { useAuth0 } from "@auth0/auth0-react";
import FundCommitmentProgressBar from "../PostInvestmentComponents/FundCommitmentProgressBar";
import DataTable from "react-data-table-component";

export const EditPostInvestmentDocumentComponent = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { meta = {} } = { ...props };

  const [userToken, setUserToken] = useState(undefined);
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({});
  const [datarooms, setDatarooms] = useState([]);

  let prop = {
    vcFundId: props.vcFundId,
    lpEntityCode: props.lpEntityCode,
  };

  const save = async (data) => {
    let values = {
      vcFundId: props.vcFundId,
      lpEntityCode: props.lpEntityCode,
      meta: data,
    };

    if (data.fundDataroom) {
      values.meta.fundDataroomName = datarooms.find(
        (room) => room.DataRoomGuid === data.fundDataroom
      )?.DataRoomName;
    }

    if (data.entityDataroom) {
      values.meta.entityDataroomName = datarooms.find(
        (room) => room.DataRoomGuid === data.entityDataroom
      )?.DataRoomName;
    }

    try {
      const token = await getAccessTokenSilently();
      await PostInvestmentService.createPostInvestmentDocument(token, values);
      loadData();
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const loadData = async () => {
    const token = await getAccessTokenSilently();
    setUserToken(token);

    let digify = await BaseService.digify(token);

    setDatarooms(digify?.data.datarooms);

    let values = {
      vcFundId: props.vcFundId,
      lpEntityCode: props.lpEntityCode,
    };
    let rows = await PostInvestmentService.getAllPostInvestmentDocument(
      token,
      values
    );
    setRows(rows.data);

    if (rows.data?.length > 0) {
      setNewRow(rows.data[0].meta);
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  const columns = [
    {
      name: "Fund Dataroom",
      selector: (row) => row.meta?.fundDataroomName,
      sortable: true,
    },
    {
      name: "Entity Dataroom",
      selector: (row) => row.meta?.entityDataroomName,
      sortable: true,
    },

    {
      name: "Date",
      grow: 2,
      selector: (row) => BaseService.getLocalDateTimeString(row.createdAt),
      sortable: true,
    },
  ];

  return (
    <FormStyles>
      <Form
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        initialValues={{
          fundDataroom: newRow.fundDataroom,
          entityDataroom: newRow.entityDataroom,
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={"my-3 p-4 bg-light FundCommitmentProgressBar"}>
              <div>
                <span className={"text-muted small subtitle d-inline-flex"}>
                  Fund Documents
                </span>

                <br />
                <h3 className="  d-inline-flex">{meta?.fundName}</h3>
              </div>

              <Row>
                <Col lg="6" md="6" sm="6" xs="12">
                  <Field
                    name="fundDataroom"
                    render={({ input, meta }) => (
                      <div className={"form-fields"}>
                        <label>Fund Dataroom</label>
                        <Input {...input} type={"select"}>
                          <option value="">--</option>
                          {(datarooms || []).map((room, index) => {
                            return (
                              <option
                                key={room.DataRoomGuid}
                                value={room.DataRoomGuid}
                              >
                                {room.DataRoomName}
                              </option>
                            );
                          })}
                        </Input>
                      </div>
                    )}
                  />
                </Col>

                <Col lg="6" md="6" sm="6" xs="12">
                  <Field
                    name="entityDataroom"
                    render={({ input, meta }) => (
                      <div className={"form-fields"}>
                        <label>Entity Dataroom</label>
                        <Input {...input} type={"select"}>
                          <option value="">--</option>
                          {(datarooms || []).map((room, index) => {
                            return (
                              <option
                                key={room.DataRoomGuid}
                                value={room.DataRoomGuid}
                              >
                                {room.DataRoomName}
                              </option>
                            );
                          })}
                        </Input>
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </div>

            <div className="buttons">
              <button type="submit" disabled={submitting || pristine}>
                Save
              </button>
              <button type="button" onClick={form.reset} disabled={submitting}>
                Reset
              </button>
            </div>
          </form>
        )}
      />
      <div className={"py-3"}>
        <DataTable
          dense
          pagination
          columns={columns}
          data={rows}
          title="Document History"
        />
      </div>
    </FormStyles>
  );
};

export default EditPostInvestmentDocumentComponent;
