import React, { Fragment, useState, useEffect } from "react";
import { Badge, Col, Container, Progress, Row } from "reactstrap";
import "./FundCommitmentProgressBar.scss";
import { Fade, Slide } from "react-awesome-reveal";
import BaseService from "../../service/BaseService";
import PostInvestmentService from "../../service/PostInvestmentService";

export const FundCommitmentProgressBar = (props) => {
  const { meta, token, vcFundId, lpEntityCode } = { ...props };
  const [count, setCount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [data, setData] = useState(undefined);

  let closingDate = meta?.closingDate;

  let date1 = new Date(closingDate);
  let date2 = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  const diffDays = Math.round((date1 - date2) / oneDay);

  const slice = percentage / 10;

  useEffect(() => {
    if (count >= percentage) {
      return;
    }

    const id = setTimeout(() => setCount((oldCount) => oldCount + 1), slice);

    return () => {
      clearTimeout(id);
    };
  }, [count, percentage]);

  const loadData = async () => {
    try {
      if (!token) {
        return;
      }
      let resp = await PostInvestmentService.getPostInvestmentOverview(
        token,
        vcFundId,
        lpEntityCode
      );

      setData(resp.data);

      if (resp.data) {
        let p =
          (resp.data?.overviewMeta?.contributedPaid /
            resp.data?.overviewMeta?.committed) *
          100;
        setPercentage(p);
      }
    } catch (e) {}
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <div className={"my-3 p-4 bg-light FundCommitmentProgressBar"}>
      <div className={"by-2"}>
        <span className={"text-muted small subtitle d-inline-flex"}>
          Fund Commitment
        </span>
        <br />
        <h3 className="  d-inline-flex">{meta?.fundName}</h3>
      </div>

      <div className={"mb-4 mt-2"}>
        <Progress
          style={{ height: "15px", borderRadius: "8px" }}
          multi
          className={""}
        >
          <Progress
            bar
            value={count}
            color={"primary"}
            className="bar"
          ></Progress>
        </Progress>
      </div>

      <Row className={"my-md-4 my-2"}>
        <Col md="3" sm="6" xs="6" className="col">
          <span className={"text-muted small subtitle"}>
            {data?.overviewMeta.vintageYear || "--"}
          </span>
          <br />
          <span className="title text-lightly-muted small">Vintage Year</span>
        </Col>

        <Col md="3" sm="6" xs="6" className="col">
          <Fragment>
            <div
              className="d-inline-flex"
              style={{
                backgroundColor: "rgb(201, 212, 228)",
                height: 15,
                width: 15,
                marginRight: 10,
              }}
            ></div>
            <span className={"text-muted small subtitle"}>
              {BaseService.formatMoney(
                data?.overviewMeta.committed,
                "standard"
              ) || "--"}
            </span>
          </Fragment>
          <br />
          <span className="title text-lightly-muted small  d-inline-flex">
            Committed
          </span>
        </Col>
        <Col md="3" sm="6" xs="6" className="col">
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(
              data?.overviewMeta.calledCapital,
              "standard"
            ) || "--"}
          </span>
          <br />
          <span className="title text-lightly-muted small">Called Capital</span>
        </Col>

        <Col md="3" sm="6" xs="6" className="col">
          <Fragment>
            <div
              className="d-inline-flex"
              style={{
                backgroundColor: "rgb(48, 103, 161)",
                height: 14,
                width: 14,
                marginRight: 10,
              }}
            ></div>
            <span className={"text-muted small subtitle"}>
              {BaseService.formatMoney(
                data?.overviewMeta.contributedPaid,
                "standard"
              ) || "--"}
            </span>
          </Fragment>
          <br />
          <span className="title text-lightly-muted small d-inline-flex">
            Contributed Paid
          </span>
        </Col>

        <Col md="3" sm="6" xs="6" className="col">
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(
              data?.overviewMeta.contributedGAAP,
              "standard"
            ) || "--"}
          </span>
          <br />
          <span className="title text-lightly-muted small">
            Contributed GAAP
          </span>
        </Col>

        <Col md="3" sm="6" xs="6" className="col">
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(
              data?.overviewMeta.prepaidContributed,
              "standard"
            ) || "--"}
          </span>
          <br />
          <span className="title text-lightly-muted small">
            Prepaid Contributed
          </span>
        </Col>
        <Col md="3" sm="6" xs="6" className="col">
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(
              data?.overviewMeta.distributed,
              "standard"
            ) || "--"}
          </span>
          <br />
          <span className="title text-lightly-muted small">Distributed</span>
        </Col>
        <Col md="3" sm="6" xs="6" className="col">
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(
              data?.overviewMeta.netAssetValue,
              "standard"
            ) || "--"}
          </span>
          <br />
          <span className="title text-lightly-muted small">
            Net Asset Value
          </span>
        </Col>
      </Row>
      <Row className={"mt-4 mb-0"}>
        <Col md="6" sm="12" xs="12">
          <span className="information text-lightly-muted">
            Last updated:{" "}
            {BaseService.getLocalDateTimeString(data?.updatedAt) || "--"}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default FundCommitmentProgressBar;
