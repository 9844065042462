import React, { Fragment, useState, useEffect } from "react";
import { Badge, Col, Container, Progress, Row } from "reactstrap";
import "./FundCommitmentProgressBar.scss";
import { Fade, Slide } from "react-awesome-reveal";
import BaseService from "../../service/BaseService";
import PostInvestmentService from "../../service/PostInvestmentService";
import ResponsiveEmbed from "react-responsive-embed";
import useScreenType from "react-screentype-hook";

export const PostInvestmentDocumentComponent = (props) => {
  const { meta, token, vcFundId, lpEntityCode } = { ...props };

  const [data, setData] = useState(undefined);

  const loadData = async () => {
    try {
      if (!token) {
        return;
      }
      let resp = await PostInvestmentService.getPostInvestmentDocument(
        token,
        vcFundId,
        lpEntityCode
      );

      setData(resp.data);

      if (resp.data) {
      }
    } catch (e) {}
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <div className={"my-3 p-4 bg-light FundCommitmentProgressBar"}>
      <div className={"by-2"}>
        <span
          className={"text-muted small subtitle d-inline-flex"}
          onClick={loadData}
        >
          Fund Documents
        </span>
        <br />
        <h3 className="  d-inline-flex">{meta?.fundName}</h3>
      </div>

      {data?.meta?.fundDataroomLink && (
        <ResponsiveEmbed
          id="fundDataroom"
          ratio={useScreenType.isMobile ? "2:4" : "1:1"}
          allowFullScreen
          src={data.meta.fundDataroomLink}
        />
      )}
      <Row className={"mt-4 mb-0"}>
        <Col md="6" sm="12" xs="12">
          <span className="information text-lightly-muted">
            Last updated:{" "}
            {BaseService.getLocalDateTimeString(data?.updatedAt) || "--"}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default PostInvestmentDocumentComponent;
