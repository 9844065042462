// theme.js
export const lightTheme = {
  body: "rgb(255,255,255)",
  text: "#333333",
  active: "#455054",
  muted: "#222",
  toggleBorder: "#455054",
  gradient: "linear-gradient(#39598A, #79D7ED)",
};

export const darkTheme = {
  body: "rgb(255,255,255)",
  text: "#333333",
  active: "#000000",
  muted: "#222",
  toggleBorder: "#455054",
  gradient: "linear-gradient(#091236, #1E215D)",
};
