import React, { Suspense, useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import { Fade } from "react-awesome-reveal";
import UserDashboard from "../components/UserDashboard";
import ReactGA from "react-ga4";
import BaseService from "../service/BaseService";

export const ProfileComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [lpContext, setLpContext] = useState({});

  const loadData = async () => {
    const token = await getAccessTokenSilently();
    let resp2 = (await BaseService.getCurrentLPProfile(token)) || {};
    setLpContext(resp2);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/dashboard",
    });
    loadData();
  }, []);

  const showRightColumn = () => {
    if (lpContext?.meta?.type === "ALP") {
      return false;
    } else {
      return true;
    }
  };
  return (
    <Container fluid>
      <Suspense fallback={<Loading />}>
        <Fade triggerOnce cascade>
          <Container fluid>
            <UserDashboard showRightColumn={showRightColumn()} />
          </Container>
        </Fade>
      </Suspense>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
