import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Progress, Row } from "reactstrap";
import "./FundProgressBar.scss";
import { Fade, Slide } from "react-awesome-reveal";
import BaseService from "../service/BaseService";

export const FundProgressBar = (props) => {
  let percentage = 0;
  const { meta, isALP } = { ...props };
  const [count, setCount] = useState(0);

  let closingDate = meta.closingDate;

  let date1 = new Date(closingDate);
  let date2 = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  const diffDays = Math.round((date1 - date2) / oneDay);

  percentage = (meta.fundRaised / meta.fundAllocation) * 100;

  const loadData = async () => {};

  const slice = percentage / 10;
  useEffect(() => {
    if (count >= percentage) {
      return;
    }

    const id = setTimeout(() => setCount((oldCount) => oldCount + 1), slice);

    return () => {
      clearTimeout(id);
    };
  }, [count]);

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  if (!props.meta?.fundName) {
    return;
  }

  const fundMin = () => {
    //voCheckSize
    if (meta?.voCheckSize) {
      return meta.voCheckSize;
    } else {
      return props.fundCategory?.meta?.min;
    }
  };

  const getRow1 = () => {
    return (
      <Row className={"my-4"}>
        <Col md="4" sm="4" xs="4">
          <span className="title text-lightly-muted small">Target</span>
          <br />
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(props.meta?.fundTargetAmount)}
          </span>
        </Col>

        <Col md="4" sm="4" xs="4">
          <span className="title  text-lightly-muted small">Category</span>
          <br />
          <span className={"text-muted small subtitle"}>
            {props.fundCategory.name}
          </span>
        </Col>

        <Col md="4" sm="4" xs="4">
          <span className="title  text-lightly-muted small">Closing Date</span>
          <br />
          <span className={"text-muted small subtitle"}>
            {BaseService.getDateString(props.meta?.closingDate)}
          </span>
        </Col>
      </Row>
    );
  };

  const getRow1ALP = () => {
    return (
      <Row className={"my-4"}>
        <Col md="4" sm="4" xs="4">
          <span className="title text-lightly-muted small">Target</span>
          <br />
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(props.meta?.fundTargetAmount)}
          </span>
        </Col>

        <Col md="4" sm="4" xs="4">
          <span className="title  text-lightly-muted small">
            Investment Amount
          </span>
          <br />
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(fundMin())}
          </span>
        </Col>

        <Col md="4" sm="4" xs="4">
          <span className="title  text-lightly-muted small">Closing Date</span>
          <br />
          <span className={"text-muted small subtitle"}>
            {BaseService.getDateString(props.meta?.closingDate)}
          </span>
        </Col>
      </Row>
    );
  };

  const getRow2 = () => {
    return (
      <Row className={"my-4"}>
        <Col md="4" sm="4" xs="4">
          <span className="title  text-lightly-muted small">Amount Raised</span>
          <br />
          <span className={"text-muted small subtitle"}>
            {props.meta?.fundAmountRaised
              ? BaseService.formatMoney(props.meta?.fundAmountRaised)
              : "TBA"}
          </span>
        </Col>
        <Col md="4" sm="4" xs="4">
          <span className="title  text-lightly-muted small">
            Minimum Investment
          </span>
          <br />
          <span className={"text-muted small subtitle"}>
            {BaseService.formatMoney(props.meta?.voCheckSize)}
          </span>
        </Col>
        <Col md="4" sm="4" xs="4">
          {diffDays > 1 && (
            <>
              <span className="title  text-lightly-muted small">
                Days Remaining
              </span>
              <br />
              <span className={"text-muted small subtitle"}>{diffDays}</span>
            </>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div className={"my-3 p-4 bg-light FundProgressBar"}>
      <h3>{props.meta.fundName}</h3>

      {!isALP ? getRow1() : getRow1ALP()}

      {!isALP && getRow2()}

      {/* <Row className={"my-4"}>
        <Col md="4" sm="6" xs="6">
          Closing Date
          <br />
          <span className={"text-muted small"}>
            {BaseService.getDateString(props.meta?.closingDate)}
          </span>
        </Col>
        <Col md="4" sm="6" xs="6" className={""}>
          {diffDays > 1 && (
            <>
              Days Remaining
              <br />
              <span className={"text-muted small"}>{diffDays}</span>
            </>
          )}
        </Col>
      </Row> */}

      {props.meta?.fundAllocation && percentage >= 50 && (
        <>
          <Row className={"my-1"}>
            <Col>
              <div className="status">V0 Allocation Status</div>
            </Col>
            <Col className={"text-right"}>
              <span className={"raise"}>
                {BaseService.formatMoney(props.meta?.fundRaised)}
              </span>
              <br />
              <span className={"total"}>
                of {BaseService.formatMoney(props.meta?.fundAllocation)} Raised
              </span>
            </Col>
          </Row>

          <Progress
            style={{ height: "40px", borderRadius: "20px" }}
            multi
            className={""}
          >
            <Progress
              bar
              value={count}
              color={"primary"}
              className="bar "
              style={{
                textAlign: "right",
                paddingRight: "20px",
              }}
            >
              <Fade delay={1e3} cascade damping={1e-1}>
                V0 commitment
              </Fade>
            </Progress>
          </Progress>
        </>
      )}
    </div>
  );
};

export default FundProgressBar;
