import React from "react";
import { Badge, Col, Row } from "reactstrap";
import BufferImage from "./BufferImage";
import { Placeholder, PlaceholderButton } from "react-bootstrap";
import logo from "../assets/logo.png";
import "./FundBadge.scss";

const Buffer = require("buffer/").Buffer;

export default function FundBadge(props) {
  let {
    fund = {},
    size = "",
    subtitle = "",
    fundTitle = "",
    base64ImgBuffer,
    imageCoverMode,
    backgroundColor = "#FFF",
    fontColor = "#000",
    imageURL = "",
  } = props;

  let base64Img;
  if (base64ImgBuffer) {
    base64Img = Buffer.from(base64ImgBuffer).toString("utf8");
  }
  if (
    fund?.meta?.tier?.toString() === "1" ||
    fund?.meta?.tier?.toString() === "2"
  ) {
    backgroundColor = fund.meta.backgroundColor;
    fontColor = fund.meta.fontColor || "#CCC";
  }

  const getImage = (title) => {
    if (imageURL) {
      return (
        <img
          alt={fundTitle}
          className={"p-0 img"}
          src={imageURL}
          style={{ flex: 1 }}
        />
      );
    } else if (base64Img) {
      return (
        <BufferImage
          size={200}
          base64ImgBuffer={base64ImgBuffer}
          imageCoverMode={imageCoverMode}
        />
      );
    } else {
      return <h6 className={"m-auto"}>{title}</h6>;
      //return <img alt={fundTitle} className={"p-2 img"} src={logo} />;
    }
  };

  return (
    <div
      className={`p-0 m-0 FundBadge ${
        size === "Large" ? "LargeFundBadge" : ""
      } d-flex`}
      style={{
        backgroundColor: backgroundColor,
        color: fontColor,
        width: size === "Large" ? "150px" : "68px",
        height: size === "Large" ? "140px" : "60px",
        padding: "2px",
      }}
    >
      {getImage(fundTitle)}
    </div>
  );
}
