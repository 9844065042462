import React, { Fragment } from "react";
import { Fade } from "react-awesome-reveal";
import { Row, Col, Container } from "react-bootstrap";

const UserNotice = (props) => {
  const { header, icon, title, subtitle, more } = props;
  return (
    <Fade cascade triggerOnce>
      <Container fluid className="pb-5">
        <Row className="m-0">
          <Col sm={"12"} xs={"12"}>
            <span
              className="opacity-50 text-muted"
              style={{ fontSize: "12px" }}
            >
              {header}
            </span>
          </Col>
        </Row>

        <Row className={"border my-3 mx-3 pb-1"}>
          <Col sm={"3"} xs={"3"} className="p-2  my-1 text-align-center">
            {icon}
          </Col>
          <Col sm={"9"} xs={"9"} className="pl-2 my-1 p-2">
            <p
              className="py-1 my-1 pl-2 text-muted "
              style={{
                fontSize: "16px",
                fontWeight: "300",

                margin: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>{title}</span>
            </p>

            <p
              className=" pl-2 m-0 text-muted"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontSize: "12px",
                fontWeight: "200",
                opacity: 0.8,
              }}
            >
              {subtitle}
            </p>
            {more}
          </Col>
        </Row>
      </Container>
    </Fade>
  );
};
export default UserNotice;
