import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import { Link } from "react-router-dom";
import BaseService from "../service/BaseService";
import FundTitle from "./FundTitle";
import FundTile from "./FundTile";

export default function VCFundCard(props) {
  const {
    data = {},
    widthMode = props.widthMode,
    showQuote = false,
    tier = props.tier || data?.meta?.tier || "",
    showBadge = true,
    displayAsTier1 = false,
  } = props;

  //useEffect(() => {}, []);

  const currentTier = () => {
    if (displayAsTier1) {
      return "1";
    }
    return tier.toString();
  };

  if (currentTier() === "3") {
    return (
      <Link to={`/fund-detail/${data.name}`}>
        <FundTile
          badge={showBadge ? data?.fundCategory?.name : undefined}
          secondaryBadge={
            showBadge && data.fundRaisingStatus === "Closed"
              ? data?.fundRaisingStatus
              : undefined
          }
          imageURL={data?.meta.logoURL}
          fontColor={data?.meta.fontColor}
          backgroundColor={data?.meta.backgroundColor || "#CCC"}
          showQuote={false}
          quote={showQuote ? data?.meta.quote : ""}
          fundTitle={data?.meta.titleName || data?.name}
          base64ImgBuffer={data?.logoPhoto}
          tier={tier}
          imageCoverMode={"contain"}
        />
      </Link>
    );
  } else if (currentTier() === "6") {
    return (
      <Link to={`/fund-detail/${data.name}`}>
        <FundTile
          badge={showBadge ? data?.fundCategory?.name : undefined}
          secondaryBadge={
            showBadge && data.fundRaisingStatus === "Closed"
              ? data?.fundRaisingStatus
              : undefined
          }
          imageURL={data?.meta.logoURL}
          fontColor={data?.meta.fontColor}
          backgroundColor={data?.meta.backgroundColor || "#CCC"}
          showQuote={false}
          quote={showQuote ? data?.meta.quote : ""}
          fundTitle={data?.meta.titleName || data?.name}
          base64ImgBuffer={data?.logoPhoto}
          tier={tier}
          imageCoverMode={"contain"}
        />
      </Link>
    );
  } else if (currentTier() === "2") {
    return (
      <Link to={`/fund-detail/${data.name}`}>
        <div className={"mx-3 mb-2 px-0"}>
          <FundTile
            badge={showBadge ? data?.fundCategory?.name : undefined}
            secondaryBadge={
              showBadge && data.fundRaisingStatus === "Closed"
                ? data?.fundRaisingStatus
                : undefined
            }
            widthMode={widthMode}
            backgroundColor={data?.meta.backgroundColor || "#CCC"}
            imageURL={data?.meta.logoURL}
            fontColor={data?.meta.fontColor}
            fundTitle={data?.meta.titleName || data?.name}
            showQuote={false}
            tier={tier}
            imageCoverMode={"contain"}
          />
        </div>
      </Link>
    );
  } else if (currentTier() === "1") {
    return (
      <Link to={`/fund-detail/${data.name}`}>
        <div className={"mx-0 p-1"}>
          <FundTitle
            fontColor={data?.meta.fontColor}
            imageURL={data?.meta.logoURL}
            badge={showBadge ? data?.fundCategory?.name : undefined}
            secondaryBadge={
              data.fundRaisingStatus === "Closed"
                ? data?.fundRaisingStatus
                : undefined
            }
            showQuote={false}
            quote={showQuote ? data?.meta.quote : ""}
            fundTitle={data?.meta.titleName || data?.name}
            backgroundColor={data?.meta.backgroundColor || "#CCC"}
            base64ImgBuffer={data?.logoPhoto}
            tier={displayAsTier1 === 1 ? 1 : tier}
            imageCoverMode={"contain"}
          />
        </div>
      </Link>
    );
  } else {
    return (
      <Link to={`/fund-detail/${data.name}`}>
        <FundTile
          imageURL={data?.meta.logoURL}
          fontColor={data?.meta.fontColor}
          showQuote={false}
          quote={showQuote ? data?.meta.quote : ""}
          fundTitle={data?.meta.titleName || data?.name}
          base64ImgBuffer={data?.logoPhoto}
          tier={tier}
          imageCoverMode={"contain"}
        />
      </Link>
    );
  }
}
