import BaseService from "./BaseService";

class PostInvestmentService {
  static async createPostInvestment(token, vcFundId) {
    let d = JSON.stringify({ vcFundId: vcFundId });
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/postinvestment/create`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: d,
      method: "post",
    });
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    } else {
      const responseData = await response.json();
      return responseData;
    }
  }

  static async getAllPostInvestmentOverview(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(
      `${baseURL}api/v1/postinvestmentoverview/getAll`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: d,
        method: "post",
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    } else {
      const responseData = await response.json();
      return responseData;
    }
  }

  static async getAllPostInvestmentDocument(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(
      `${baseURL}api/v1/postinvestmentdocument/getAll`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: d,
        method: "post",
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    } else {
      const responseData = await response.json();
      return responseData;
    }
  }

  static async createPostInvestmentOverview(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(
      `${baseURL}api/v1/postinvestmentoverview/create`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: d,
        method: "post",
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    } else {
      const responseData = await response.json();
      return responseData;
    }
  }

  static async createPostInvestmentDocument(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(
      `${baseURL}api/v1/postinvestmentdocument/create`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: d,
        method: "post",
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    } else {
      const responseData = await response.json();
      return responseData;
    }
  }

  static async getPostInvestmentOverview(token, vcFundId, lpEntityCode) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(
      `${baseURL}api/v1/postinvestmentoverview/${vcFundId}/${lpEntityCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const responseData = await response.json();
    return responseData;
  }

  static async getPostInvestmentDocument(token, vcFundId, lpEntityCode) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(
      `${baseURL}api/v1/postinvestmentdocument/${vcFundId}/${lpEntityCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const responseData = await response.json();
    return responseData;
  }
}

export default PostInvestmentService;
