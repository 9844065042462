import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Progress, Row } from "reactstrap";
import "./FundCommitmentProgressBar.scss";

import BaseService from "../../service/BaseService";
import { Field, Form } from "react-final-form";
import FormStyles from "../FormStyles";
import NumericInput from "react-numeric-input";
import PostInvestmentService from "../../service/PostInvestmentService";
import { useAuth0 } from "@auth0/auth0-react";
import FundCommitmentProgressBar from "../PostInvestmentComponents/FundCommitmentProgressBar";
import DataTable from "react-data-table-component";

export const EditPostInvestmentComponent = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { meta = {} } = { ...props };

  const [userToken, setUserToken] = useState(undefined);
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({});

  let prop = {
    vcFundId: props.vcFundId,
    lpEntityCode: props.lpEntityCode,
  };
  const save = async (data) => {
    let values = {
      vcFundId: props.vcFundId,
      lpEntityCode: props.lpEntityCode,
      overviewMeta: data,
    };
    try {
      const token = await getAccessTokenSilently();
      await PostInvestmentService.createPostInvestmentOverview(token, values);
      loadData();
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const loadData = async () => {
    const token = await getAccessTokenSilently();
    setUserToken(token);

    let values = {
      vcFundId: props.vcFundId,
      lpEntityCode: props.lpEntityCode,
    };
    let rows = await PostInvestmentService.getAllPostInvestmentOverview(
      token,
      values
    );
    setRows(rows.data);

    if (rows.data?.length > 0) {
      setNewRow(rows.data[0].overviewMeta);
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  const columns = [
    {
      name: "V. Year",
      selector: (row) => row.overviewMeta?.vintageYear,
      sortable: true,
    },
    {
      name: "Committed",
      selector: (row) => row.overviewMeta?.committed,
      sortable: true,
    },
    {
      name: "Called Cap.",
      selector: (row) => row.overviewMeta?.calledCapital,
      sortable: true,
    },
    {
      name: "Cont. Paid",
      selector: (row) => row.overviewMeta?.contributedPaid,
      sortable: true,
      grow: 1,
    },
    {
      name: "Cont. GAAP",
      selector: (row) => row.overviewMeta?.contributedGAAP,
      sortable: true,
    },
    {
      name: "Prepaid Cont.",
      selector: (row) => row.overviewMeta?.prepaidContributed,
      sortable: true,
    },
    {
      name: "Dist.",
      selector: (row) => row.overviewMeta?.distributed,
      sortable: true,
    },
    {
      name: "NAV",
      selector: (row) => row.overviewMeta?.netAssetValue,
      sortable: true,
    },
    {
      name: "Date",
      grow: 2,
      selector: (row) => BaseService.getLocalDateTimeString(row.createdAt),
      sortable: true,
    },
  ];

  return (
    <FormStyles>
      <Form
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        initialValues={{
          vintageYear: newRow.vintageYear,
          committed: newRow.committed,
          calledCapital: newRow.calledCapital,
          contributedPaid: newRow.contributedPaid,
          contributedGAAP: newRow.contributedGAAP,
          prepaidContributed: newRow.prepaidContributed,
          distributed: newRow.distributed,
          netAssetValue: newRow.netAssetValue,
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={"my-3 p-4 bg-light FundCommitmentProgressBar"}>
              <div>
                <span className={"text-muted small subtitle d-inline-flex"}>
                  Fund Commitment
                </span>
                <br />
                <h3 className="  d-inline-flex">{meta?.fundName}</h3>
              </div>

              <Row>
                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="vintageYear"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1}
                        precision={0}
                        snap
                        min={2000}
                        {...input}
                      />
                    )}
                  />

                  <br />
                  <span className="title text-lightly-muted small">
                    Vintage Year
                  </span>
                </Col>

                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="committed"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1000}
                        precision={0}
                        snap
                        min={0}
                        {...input}
                      />
                    )}
                  />

                  <br />
                  <span className="title text-lightly-muted small">
                    Committed
                  </span>
                </Col>
                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="calledCapital"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1000}
                        precision={0}
                        snap
                        min={0}
                        {...input}
                      />
                    )}
                  />
                  <br />
                  <span className="title text-lightly-muted small">
                    Called Capital
                  </span>
                </Col>

                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="contributedPaid"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1000}
                        precision={0}
                        snap
                        min={0}
                        {...input}
                      />
                    )}
                  />
                  <br />
                  <span className="title text-lightly-muted small">
                    Contributed Paid
                  </span>
                </Col>
              </Row>

              <Row>
                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="contributedGAAP"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1000}
                        precision={0}
                        snap
                        min={0}
                        {...input}
                      />
                    )}
                  />
                  <br />
                  <span className="title text-lightly-muted small">
                    Contributed GAAP
                  </span>
                </Col>

                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="prepaidContributed"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1000}
                        precision={0}
                        snap
                        min={0}
                        {...input}
                      />
                    )}
                  />
                  <br />
                  <span className="title text-lightly-muted small">
                    Prepaid Contributed
                  </span>
                </Col>
                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="distributed"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1000}
                        precision={0}
                        snap
                        min={0}
                        {...input}
                      />
                    )}
                  />
                  <br />
                  <span className="title text-lightly-muted small">
                    Distributed
                  </span>
                </Col>
                <Col lg="3" md="6" sm="6" xs="12">
                  <Field
                    name="netAssetValue"
                    render={({ input, meta }) => (
                      <NumericInput
                        step={1000}
                        precision={0}
                        snap
                        min={0}
                        {...input}
                      />
                    )}
                  />
                  <br />
                  <span className="title text-lightly-muted small">
                    Net Asset Value
                  </span>
                </Col>
              </Row>
            </div>

            <div className="buttons">
              <button type="submit" disabled={submitting || pristine}>
                Save
              </button>
              <button type="button" onClick={form.reset} disabled={submitting}>
                Reset
              </button>
            </div>
          </form>
        )}
      />

      <div className={"py-3"}>
        <DataTable
          dense
          pagination
          columns={columns}
          data={rows}
          title="Fund Commitment History"
        />
      </div>
    </FormStyles>
  );
};

export default EditPostInvestmentComponent;
