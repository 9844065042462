import BaseService from "./BaseService";

class UserService {
  static async inviteUser(token, id, sendCode) {
    let baseURL = BaseService.baseURL();
    let d = JSON.stringify({ id: id, sendCode: sendCode });
    const response = await fetch(`${baseURL}api/v1/send-user-invitation`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: d,
      method: "post",
      credentials: "include",
    });
    return response.statusText;
  }

  static async getAll(token) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async addUser(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async editUser(token, id, data) {
    let d = JSON.stringify({ ...data, id: id });
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async deleteUser(token, id) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      credentials: "include",
    });
    return response.statusText;
  }
}

export default UserService;
