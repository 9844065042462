import React, { useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ResponsiveEmbed from "react-responsive-embed";
import useScreenType from "react-screentype-hook";
import ReactGA from "react-ga4";
const file =
  require("../assets/Terms of Service Agreement (8.29.2023).pdf") +
  "#toolbar=0";
export const TermsComponent = (props) => {
  const screenType = useScreenType();

  const loadData = async () => {};

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/terms-of-service/",
    });
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <Container className="py-5">
      <h1 className="pb-5 text-align-center">Terms of Service</h1>
      <ResponsiveEmbed
        ratio={screenType.isMobile ? "2:4" : "1:1"}
        src={file}
      ></ResponsiveEmbed>
    </Container>
  );
};

export default withAuthenticationRequired(TermsComponent, {
  onRedirecting: () => <Loading />,
});
