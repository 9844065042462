import React, { useEffect, useContext, useState } from "react";
import "./FundAnnouncement.scss";
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import BaseService from "../service/BaseService";
import { useAuth0 } from "@auth0/auth0-react";
import "./LPTeamSelector.scss";
import { UserContext } from "../UserContext";
import { ArrowSwapHorizontal, Setting2, User, UserTick } from "iconsax-react";
const LPTeamSelector = (props) => {
  const { isLoading, error, logout, getAccessTokenSilently } = useAuth0();
  const [context, setContext] = useContext(UserContext);
  const [lpProfiles, setLPProfiles] = useState([]);
  const [defaultLPEntity, setDefaultLPEntity] = useState({});
  const [hoverLP, setHoverLP] = useState(undefined);
  const [user, setUser] = useState({});

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await BaseService.getUser(token);
      let defaultCode = undefined;
      if (resp?.data) {
        setUser(resp.data);
        if (resp?.data?.meta?.defaultLPEntityCode) {
          defaultCode = resp.data?.meta?.defaultLPEntityCode;
        }
      }

      let resp2 =
        (await BaseService.getLPProfile(token, { profileOnly: true })) || [];

      let lp = resp2.find((lp) => lp.code === defaultCode);
      setDefaultLPEntity(lp);

      resp2 = resp2.sort((a, b) => a.code < b.code); // fix it

      setLPProfiles(resp2);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const setActiveLPEntityCode = async (code) => {
    const token = await getAccessTokenSilently();
    let meta = user.meta;
    meta.defaultLPEntityCode = code;
    await BaseService.updateUserMeta(token, meta);
    window.location.reload(false);
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup...
    };
  }, [context]);

  if (!user || !user.email) {
    return null;
  }

  if (lpProfiles.length === 0) {
    return null;
  } else if (lpProfiles.length === 1) {
    if (defaultLPEntity.meta?.type === "ALP") {
      return null;
    } else {
      return (
        <div
          className={`LPTeamSelector ${props.className}`}
          id="LPTeamSelector"
        >
          <div className={"team-dropdown fixed"}>
            {defaultLPEntity?.companyName}
          </div>
        </div>
      );
    }
  }

  return (
    <UncontrolledDropdown
      {...props}
      className={`LPTeamSelector ${props.className}`}
      id={"LPTeamSelector"}
    >
      <DropdownToggle caret={lpProfiles.length > 1} nav>
        <span>{defaultLPEntity?.companyName}</span>
      </DropdownToggle>
      {lpProfiles.length > 1 && (
        <DropdownMenu className={"team-dropdown"}>
          <DropdownItem className={"myLP pe-none"}>
            <span>{defaultLPEntity?.companyName}</span>

            <Link to={"/manage-team"} className="d-flex pe-auto">
              <Setting2 size={18} className=" m-0 p-0" />
              <div className={"d-inline mt-0 title"}>Manage</div>
            </Link>
          </DropdownItem>

          {lpProfiles
            .filter((lpProfile) => defaultLPEntity.code !== lpProfile.code)
            .map((lpProfile) => {
              return (
                <DropdownItem
                  className={"py-2"}
                  onMouseLeave={() => {
                    setHoverLP("");
                  }}
                  disabled={defaultLPEntity.code === lpProfile.code}
                  key={lpProfile.code}
                  onClick={() => {
                    setActiveLPEntityCode(lpProfile.code);
                  }}
                >
                  <div
                    onMouseOver={() => {
                      setHoverLP(lpProfile.code);
                    }}
                    onClick={() => {
                      setActiveLPEntityCode(lpProfile.code);
                    }}
                  >
                    {lpProfile.companyName}{" "}
                    {hoverLP === lpProfile.code ? (
                      <ArrowSwapHorizontal size="20" />
                    ) : (
                      <ArrowSwapHorizontal size="20" color="white" />
                    )}
                  </div>
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};

export default LPTeamSelector;
