import React, { Fragment, Suspense, useEffect, useState } from "react";
import {
  Container,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardSubtitle,
  Row,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import AppliedFundListing from "./AppliedFundListing";
import { Calendar, Link1, Note, MedalStar, ArrowRight } from "iconsax-react";
import BaseService from "../service/BaseService";
import DashboardService from "../service/DashboardService";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import UserSteps from "./UserSteps";
import FundTile from "./FundTile";
import VCFundListing from "./VCFundListing";
import UserReminder from "./UserReminder";
import NotionPageRenderer from "./NotionPageRenderer";
import { Fade } from "react-awesome-reveal";
import { Button } from "react-bootstrap";
import UserNotice from "./UserNotice";
export default function UserDashboard(props) {
  const { showRightColumn = false } = props;
  const [user, setUser] = useState(undefined);
  const [credits, setCredits] = useState(0);
  const [expiredAt, setExpiredAt] = useState(undefined);

  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);

  const [newsPageId, setNewsPageId] = useState(undefined);
  const [companyName, setCompanyName] = useState("");
  const [committed, setComitted] = useState([]);
  const [postInvestment, setPostInvestment] = useState([]);
  const [decisionPending, setDecisionPending] = useState([]);
  const [waitlisted, setWaitlisted] = useState([]);
  const [introduced, setIntroduced] = useState([]);

  const [isByPassDDCredit, setIsByPassDDCredit] = useState(undefined);

  const { getAccessTokenSilently } = useAuth0();

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await BaseService.getUser(token);
      if (resp) {
        setUser(resp.data);
      }
      let resp2 = (await BaseService.getCurrentLPProfile(token)) || {};

      setCompanyName(resp2.companyName);

      let conf = await DashboardService.getConfiguration(token);

      let eventTitle = conf?.find((e) => e.name.toLowerCase() === "event-title")
        ?.meta?.value;
      let eventTime = conf?.find((e) => e.name.toLowerCase() === "event-time")
        ?.meta?.value;
      setEvent({
        eventTime: eventTime,
        eventTitle: eventTitle,
      });

      let byPassCredit = conf?.find(
        (e) => e.name.toLowerCase() === "system-bypass-dd-credit"
      )?.meta?.value;
      setIsByPassDDCredit(byPassCredit === true);

      let newsId = conf?.find(
        (e) => e.name.toLowerCase() === "dashboard-news-page-id"
      )?.meta?.value;
      setNewsPageId(newsId);

      let committed = resp2.committed || [];
      let committedWithoutPostInvestment = committed.filter(
        (c) => !c.fund.postInvestmentOverview
      );

      setComitted(committedWithoutPostInvestment);

      let committedWithPostInvestment = committed.filter(
        (c) => c.fund.postInvestmentOverview
      );
      setPostInvestment(committedWithPostInvestment);

      setWaitlisted(resp2.waitlisted || []);
      setIntroduced(resp2.introduced || []);
      setDecisionPending(resp2.decisionPending || []);

      setCredits(parseInt(resp2.meta.credits));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const getCard = (icon, header, title, subtitle, more) => {
    return (
      <UserNotice
        header={header}
        title={title}
        more={more}
        icon={icon}
        subtitle={subtitle}
      />
    );
  };

  if (!user || !companyName) {
    return (
      <Container className={"h-100"}>
        <Loading />
      </Container>
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <Container fluid className={"pb-3 px-0 v0-black"}>
        <Container className={"mt-0 mb-2 pb-3  mx-auto  px-sm-0 px-md-3"}>
          <Row className={"py-3 px-0 mb-5"}>
            <Col
              className={"pt-5"}
              lg={{
                offset: 0,
                size: 12,
              }}
              md={{
                offset: 0,
                size: 12,
              }}
              sm="12"
              xs="12"
            >
              <div className="py-0 px-0">
                <Fade cascade triggerOnce>
                  {/* <span>{companyName}</span> */}
                  <h3 className="welcome-title">Welcome {user.given_name}!</h3>
                </Fade>
              </div>
            </Col>
          </Row>

          <Row className={"pb-3"}>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg={showRightColumn ? "8" : "12"}
              xl={showRightColumn ? "9" : "12"}
              className={"p-0 m-0"}
            >
              <Fade
                triggerOnce
                cascade
                delay={200}
                className="mr-2 ml-2 pr-md-0 pr-lg-5 pr-sm-0"
              >
                {postInvestment.length > 0 && (
                  <AppliedFundListing
                    showBadge={
                      user.meta?.defaultLPType === "ALP" ? false : true
                    }
                    type={"PostInvestment"}
                    funds={postInvestment}
                    title={"Invested Funds"}
                  />
                )}

                <AppliedFundListing
                  type={"DecisionPending"}
                  funds={decisionPending}
                  showBadge={user.meta?.defaultLPType === "ALP" ? false : true}
                  //subtitle={"FUNDS TO INVEST"}
                  title={"Decision Pending"}
                  // emptyComponent={
                  //   <div className={"p-0 m-0"}>
                  //     Congratulations! You have finished reviewing the V0
                  //     selected funds for this quarter. Meanwhile, you can review
                  //     other funds published on our platform.{" "}
                  //     <Link
                  //       to="/fund-gallery#additional-funds"
                  //       className="brand-link"
                  //     >
                  //       Explore additional funds {" > "}
                  //     </Link>
                  //   </div>
                  // }
                />

                {committed.length > 0 && (
                  <AppliedFundListing
                    type={"Committed"}
                    showBadge={
                      user.meta?.defaultLPType === "ALP" ? false : true
                    }
                    funds={committed}
                    title={"Fund Commitments"}
                  />
                )}

                {waitlisted.length > 0 && (
                  <AppliedFundListing
                    type={"Waitlisted"}
                    funds={waitlisted}
                    title={"Waitlisted Funds"}
                    emptyComponent={
                      <div>
                        Express your interest in the funds selection that the V0
                        partners will be analyzing.{" "}
                        <Link
                          to="/fund-gallery#additional-funds"
                          className="brand-link"
                        >
                          Explore the funds open for waitlist {" > "}
                        </Link>
                      </div>
                    }
                  />
                )}
                {introduced.length && (
                  <AppliedFundListing
                    type={"Introduced"}
                    funds={introduced}
                    title={"Funds Requested for Introduction"}
                  />
                )}
              </Fade>
            </Col>

            {showRightColumn && (
              <Col xs="12" sm="12" md="12" lg="4" xl="3" className={"p-0 m-0"}>
                <Fade cascade triggerOnce>
                  <Container fluid className="p-0 m-0">
                    <Row className="p-0 m-0">
                      <Col className="p-0 m-0" xs="12">
                        {" "}
                        {event.eventTitle &&
                          getCard(
                            <Calendar
                              size="60"
                              style={{ borderRadius: 50 }}
                              className={"bg-light ml-1 my-1 p-3"}
                            />,
                            "UPCOMING MEMBER EVENT",
                            event.eventTitle || "1",
                            event.eventTime || "2",
                            null
                          )}
                      </Col>

                      {!isByPassDDCredit && (
                        <Col className="p-0 m-0" xs="12">
                          {getCard(
                            <Note
                              size="60"
                              style={{ borderRadius: 50 }}
                              className={"bg-light ml-1 my-1 p-3"}
                            />,
                            "DUE DILIGENCE",
                            <Fragment>
                              <div
                                className="opacity-50 text-muted pb-2"
                                style={{ fontSize: "12px" }}
                              >
                                CREDITS REMAINING
                              </div>
                              <div className="text-bold pb-2 bold">
                                {credits || " - "}
                              </div>
                            </Fragment>,
                            null,

                            null
                          )}
                        </Col>
                      )}
                    </Row>
                  </Container>

                  {newsPageId && (
                    <NotionPageRenderer
                      pageId={newsPageId}
                      maxResult={2}
                      viewMoreComponent={() => {
                        return (
                          <Link
                            className="pl-2 pt-0 primary-link"
                            to="/portfolio-updates"
                            style={{ fontSize: "14px" }}
                          >
                            See all updates {" >"}
                          </Link>
                        );
                      }}
                    ></NotionPageRenderer>
                  )}
                </Fade>
              </Col>
            )}
          </Row>
        </Container>
      </Container>
    </Suspense>
  );
}
