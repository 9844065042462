import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";

import Hero2 from "../components/Hero2";
import Testimonial from "../components/Testimonial";
import BannerWithText from "../components/BannerWithText";

const Home = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const loadData = async () => {
    let token = await getAccessTokenSilently();
    try {
      await BaseService.login(token);
    } catch (e) {}
  };

  useEffect(() => {
    if (user) {
      loadData();
    }
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <>
      <Hero2 showDownArrow={false} />
      {/* <Testimonial />
      <BannerWithText /> */}
    </>
  );
};

export default Home;
