import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import BaseService from "../service/BaseService";
//import FileField from "./FileField.tsx";
import { useDarkMode } from "../useDarkMode";
import {
  AddCircle,
  Eye,
  Menu,
  More,
  More2,
  Refresh,
  TextalignJustifycenter,
} from "iconsax-react";

export default function VCFundTable(props) {
  const navigate = useNavigate();
  const [theme] = useDarkMode();

  const { getAccessTokenSilently } = useAuth0();

  const {
    editEnabled = false,
    favoriteOnly = false,
    title = "",
    publicView = true,
  } = props;
  const [data, setData] = useState([]);
  const [myFav, setMyFav] = useState([]);

  const [count, setCount] = useState([]);

  let defaultOffset = 0;
  let defaultQuery = "";
  let defaultViewStatus = undefined;

  if (editEnabled) {
    defaultOffset = parseInt(localStorage.getItem("VCFundTable-Offset") || 0);
    defaultQuery = localStorage.getItem("VCFundTable-Query") || "";
    defaultViewStatus = localStorage.getItem("VCFundTable-ViewStatus");
  }

  if (defaultViewStatus === null) {
    defaultViewStatus = undefined;
  }

  const [offset, setOffset] = useState(defaultOffset);
  const [query, setQuery] = useState(defaultQuery);
  const [viewStatus, setViewStatus] = useState(defaultViewStatus);

  let limit = 10;

  const editFund = (data) => {
    navigate(`/admin/editFundDetail/${data.name}`);
  };

  const deleteVCFund = async (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const token = await getAccessTokenSilently();
            await VCFundService.deleteFund(token, id);
            loadData();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (!favoriteOnly) {
        let resp = await VCFundService.getAll(
          token,
          limit,
          offset,
          query,
          publicView,
          viewStatus
        );
        setData(resp.rows);
        setCount(resp.total);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  useEffect(() => {
    if (editEnabled) {
      localStorage.setItem("VCFundTable-Query", query);
      localStorage.setItem("VCFundTable-Offset", offset.toString());
      if (viewStatus) {
        localStorage.setItem("VCFundTable-ViewStatus", viewStatus);
      } else {
        localStorage.removeItem("VCFundTable-ViewStatus");
      }
    }
    loadData();
  }, [query, offset, viewStatus, editEnabled]);

  const prev = () => {
    setOffset(Math.max(0, offset - limit));
  };

  const next = () => {
    if (offset + limit < count) {
      setOffset(offset + limit);
    }
  };

  const first = () => {
    setOffset(0);
  };

  const last = () => {
    setOffset(Math.floor(count / limit) * limit);
  };

  const isFav = (d) => {
    return myFav.findIndex((a) => a && d && a.id === d.id) !== -1;
  };

  const getVisibleBadge = (d) => {
    let text = [];

    if (d.fundRaisingStatus === "Closed" || d.fundRaisingStatus === "Started") {
      text.push("FO");
    }

    if (
      d.meta?.ALP?.fundRaisingStatus === "Closed" ||
      d.meta?.ALP?.fundRaisingStatus === "Started"
    ) {
      text.push("ALP");
    }

    if (text.length > 0) {
      return (
        <Badge className={"ml-2 p-1"} color="primary" pill>
          <Eye size={"18"} /> {text.join(" | ")}
        </Badge>
      );
    }
  };

  const getHeader = () => {
    if (favoriteOnly) {
      return null;
    }

    return (
      <Row className="align-content-center center justify-content-center mb-2 mt-2">
        <Col>
          <ButtonGroup className="me-2">
            <Button
              color="primary"
              onClick={() => {
                loadData();
              }}
            >
              <Refresh color="#FFF" />
            </Button>
          </ButtonGroup>
          {editEnabled && (
            <Button
              color="primary"
              onClick={() => {
                editFund({});
              }}
            >
              <AddCircle />
            </Button>
          )}
        </Col>
        <Col sm={6}>
          <InputGroup>
            <Input
              placeholder="Search by VC Fund"
              inputMode={"text"}
              value={query}
              onChange={async (e) => {
                setOffset(0);
                setQuery(e.target.value);
              }}
            />
          </InputGroup>
        </Col>
        <Col>
          {editEnabled && (
            <ButtonGroup>
              <UncontrolledDropdown>
                <DropdownToggle color={"primary"}>
                  Show {viewStatus ? `- ${viewStatus}` : " - Show All"}
                </DropdownToggle>
                <DropdownMenu className="btn-light">
                  <DropdownItem
                    onClick={() => {
                      setOffset(0);
                      setViewStatus(undefined);
                    }}
                  >
                    Show All
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setOffset(0);
                      setViewStatus("Draft");
                    }}
                  >
                    Draft
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setOffset(0);
                      setViewStatus("Published");
                    }}
                  >
                    Published
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setOffset(0);
                      setViewStatus("Archived");
                    }}
                  >
                    Archived
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          )}
        </Col>
      </Row>
    );
  };

  const getPagination = () => {
    if (favoriteOnly) {
      return null;
    }

    return (
      <Row className="align-content-center center justify-content-center">
        <Col sm={3}>
          {count > limit && (
            <Pagination aria-label="Page navigation example">
              <PaginationItem className={"bg-dark"}>
                <PaginationLink className={"bg-dark"} onClick={first} first />
              </PaginationItem>

              <PaginationItem className={"bg-dark"}>
                <PaginationLink className={"bg-dark"} onClick={prev}>
                  Prev
                </PaginationLink>
              </PaginationItem>

              <PaginationItem className={"bg-dark"}>
                <PaginationLink className={"bg-dark"} onClick={next}>
                  Next
                </PaginationLink>
              </PaginationItem>

              <PaginationItem className={"bg-dark"}>
                <PaginationLink className={"bg-dark"} onClick={last} last />
              </PaginationItem>
            </Pagination>
          )}
        </Col>

        <Col sm={6}></Col>

        <Col sm={3}>
          {
            <span>
              Showing {offset + 1} - {Math.min(count, offset + limit)} of{" "}
              {count}
            </span>
          }
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <h4 className="mb-1 mt-1 text-center">{title}</h4>

      {getHeader()}

      <Table
        style={{ minHeight: "200px" }}
        striped={true}
        hover
        size="sm"
        variant="dark"
        responsive={true}
        className="mt-1 mb-1"
      >
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Reference Name</th>
            <th>Fund</th>
            <th>Tier</th>
            <th>Order</th>
            <th>Category</th>

            <th>Raising Status (FO)</th>
            <th>Raising Status (ALP)</th>

            {/* <th>Stage</th>


            <th>Investments</th> */}
            {/*<th className={"d-none d-md-block d-lg-block d-xl-block"}>*/}
            {/*  Chart*/}
            {/*</th>*/}
            {/* <th>Raising Status</th> */}

            {editEnabled && <th></th>}
          </tr>
        </thead>
        <tbody>
          {(data || [])
            .filter((d) => {
              return d;
            })
            .map((d, index) => {
              return (
                <tr key={index} className={"m-5 p-5"}>
                  <td>
                    {isFav(d) && (
                      <FontAwesomeIcon
                        icon={faHeart}
                        color={"white"}
                        size={"lg"}
                        className={"m-1"}
                      />
                    )}
                  </td>
                  <td>{d.id}</td>
                  <td>
                    {!editEnabled ? (
                      <Link to={`/fund-detail/${d.name}`}>{d.name}</Link>
                    ) : (
                      d.name
                    )}
                    {!publicView && (
                      <div>
                        <Badge className={"p-2"} color="primary" pill>
                          {d.status}
                        </Badge>

                        {getVisibleBadge(d)}
                      </div>
                    )}

                    {/* {d.featured && (
                      <Badge className={"m-1"} color="success" pill>
                        Featured
                      </Badge>
                    )} */}
                  </td>
                  <td>
                    {d.meta?.fundName} {d.meta?.closingDate}
                  </td>
                  <td>{d.meta?.tier}</td>
                  <td>{d.meta?.order}</td>
                  <td>{d.fundCategory?.name}</td>
                  <td>{d.fundRaisingStatus}</td>
                  <td>{d.meta?.ALP?.fundRaisingStatus}</td>
                  {/*
                  <td>{d.meta?.stage}</td>
                  <td>{d.meta?.location}</td>
                  <td>{d.meta?.investments}</td> */}
                  {/*<td className={"d-none d-md-block d-lg-block d-xl-block"}>*/}
                  {/*  <StockChart divId={"div-" + index} symbol={index % 2 ? 'NASDAQ:AAPL' : 'BINANCE:BTCUSDT'}/>*/}
                  {/*</td>*/}
                  {/* <td>
                    <Badge color="info" pill>
                      {d.fundRaisingStatus}
                    </Badge>
                  </td> */}
                  {editEnabled && (
                    <td>
                      <UncontrolledDropdown className="rounded">
                        <DropdownToggle color={""}>
                          <TextalignJustifycenter />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              navigate(`/fund-detail/${d.name}`);
                            }}
                          >
                            View
                          </DropdownItem>

                          <DropdownItem
                            onClick={async () => {
                              navigate(`/admin/fundStats/${d.name}`);
                            }}
                          >
                            Stats
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              editFund(d);
                            }}
                          >
                            Edit
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => {
                              deleteVCFund(d.id);
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>

      {getPagination()}
    </Fragment>
  );
}
