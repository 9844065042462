import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useConsent, ConsentBanner, ConsentProvider } from "react-hook-consent";
import Loading from "../components/Loading";

export const Login = (props) => {
  const [context] = useContext(UserContext);
  const navigate = useNavigate();
  const { toggleBanner, hasConsent, toggleDetails, isBannerVisible } =
    useConsent();

  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const loadData = async () => {
    let returnTo = (localStorage.getItem("returnTo") || "").replace("/#", "");
    if (returnTo) {
      localStorage.setItem("returnTo", "");
      navigate(returnTo.replace("/#", ""));
    } else if (context?.type === "VT") {
      navigate("/admin");
    } else if (context?.type === "LP") {
      navigate("/dashboard");
    } else {
      // TODO: wait for cookie
      //loginWithRedirect();

      if (hasConsent("cookie-essential")) {
        loginWithRedirect();
      }
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/login",
    });

    return () => {
      // Side-effect cleanup
    };
  }, []);

  useEffect(() => {
    if (hasConsent("cookie-essential")) {
      loadData();
    }
    return () => {
      // Side-effect cleanup
    };
  }, [isBannerVisible, hasConsent("cookie-essential")]);

  return (
    <div className={"mb-5"}>
      <Loading />
    </div>
  );
};

export default Login;
