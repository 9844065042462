import React, { useEffect, useState } from "react";
import "./Hero2.scss";
import img1 from "../assets/lake-image1.jpeg";
import img2 from "../assets/lake-image2.jpg";
import img3 from "../assets/photo-1516401778970-46de60f7a6d8.jpeg";
import img4 from "../assets/pexels-andrew-neel-3178881.jpg";

import { Col, Row, Container } from "react-bootstrap";
import { Fade, Slide } from "react-awesome-reveal";

import { ArrowDown } from "iconsax-react";
const Hero2 = (props) => {
  const {
    title,
    backgroundSize = "55% auto",
    image = 1,
    hideImage = false,
    subtitle,
    subtitleComponent = undefined,
    showDownArrow = true,
    otherComponent = undefined,
  } = { ...props };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  let img = img1;
  if (image === 2) {
    img = img2;
  } else if (image === 3) {
    img = img3;
  } else if (image === 4) {
    img = img4;
  }

  let backgroundImage = hideImage ? "" : `url(${img})`;

  const desktopStyle = {
    minHeight: "80vh",
    backgroundImage: backgroundImage,
    backgroundPosition: "right",
    backgroundSize: backgroundSize,
    backgroundRepeat: "no-repeat",
    flexDirection: "column",
    justifyContent: "center",
  };

  const mobileStyle = {
    minHeight: "65vh",
    //backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flexDirection: "column",
    justifyContent: "center",
  };

  const imageStyle = {
    height: "80vh",

    backgroundImage: backgroundImage,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flexDirection: "column",
    justifyContent: "center",
    backgroundPosition: "center",
  };

  return (
    <Container fluid className={"hero2 py-5 mx-1"}>
      <Row style={isMobile ? mobileStyle : desktopStyle}>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Fade left cascade triggerOnce>
            <div className={"align-items-center justify-content-center"}>
              <div className="px-5 py-2">
                <h2 className="hero2-title">
                  {title || "Exclusively Venture"}
                </h2>
              </div>

              {subtitleComponent ? (
                subtitleComponent
              ) : (
                <div className="px-5">
                  <p className="hero-subtitle">
                    {subtitle ||
                      "Our singular focus is to distinguish the highest performing venture capital funds."}
                  </p>
                </div>
              )}

              {otherComponent}
            </div>
          </Fade>
        </Col>
      </Row>
      {isMobile && !hideImage && (
        <Row style={imageStyle}>
          <Col xs={12} sm={12} md={12} lg={12}></Col>
        </Row>
      )}

      {showDownArrow && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Fade top spy className={isMobile ? "text-center" : "text-center"}>
              <div className={"align-items-center justify-content-center"}>
                <ArrowDown size="32" color="#333333" className={"float mt-5"} />
              </div>
            </Fade>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Hero2;
