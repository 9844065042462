import React, { useEffect, useState } from "react";
import { Card, CardText, CardTitle, Col, Row, Spinner } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardService from "../service/DashboardService";
import BaseService from "../service/BaseService";

export default function AdminInfo(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await DashboardService.system(token);

      setData(resp);
      BaseService.ping(token, true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    let timerId = setTimeout(() => {
      loadData();
    }, 100);

    return () => clearTimeout(timerId);
  }, []);
  return (
    <>
      <h2>Admin Info</h2>

      {loading ? (
        <Spinner>Loading...</Spinner>
      ) : (
        <Row>
          {Object.entries(data).map(([key, entry]) => {
            return (
              <Col sm={"6"} md={"3"} key={key}>
                <Card body className="bg-dark m-2" key={key}>
                  <CardTitle tag="h5">{entry.label}</CardTitle>
                  <CardText>{entry.value}</CardText>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
}
