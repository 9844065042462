import "./Tiptap.scss";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Image from "@tiptap/extension-image";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";

import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { Fragment, useEffect, useCallback, useState } from "react";
import { Button } from "react-bootstrap";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

const MenuBar = ({ editor, onSave, setLink }) => {
  if (!editor) {
    return null;
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 }) ? "btn is-active" : "btn"
          }
        >
          h1
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 }) ? "btn is-active" : "btn"
          }
        >
          h2
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 }) ? "btn is-active" : "btn"
          }
        >
          h3
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 }) ? "btn is-active" : "btn"
          }
        >
          h4
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          className={
            editor.isActive("heading", { level: 5 }) ? "btn is-active" : "btn"
          }
        >
          h5
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          className={
            editor.isActive("heading", { level: 6 }) ? "btn is-active" : "btn"
          }
        >
          h6
        </Button>
        <Button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "btn is-active" : " btn"}
        >
          paragraph
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "btn is-active" : "btn"}
        >
          bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "btn is-active" : "btn"}
        >
          italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "btn is-active" : "btn"}
        >
          strike
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHighlight().run()}
          className={editor.isActive("highlight") ? "btn is-active" : "btn"}
        >
          highlight
        </Button>
      </div>
      <div>
        <Button
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={
            editor.isActive({ textAlign: "left" }) ? "btn is-active" : "btn"
          }
        >
          left
        </Button>
        <Button
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={
            editor.isActive({ textAlign: "center" }) ? "btn is-active" : "btn"
          }
        >
          center
        </Button>
        <Button
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={
            editor.isActive({ textAlign: "right" }) ? "btn is-active" : "btn"
          }
        >
          right
        </Button>
        <Button
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          className={
            editor.isActive({ textAlign: "justify" }) ? "btn is-active" : "btn"
          }
        >
          justify
        </Button>

        <Button
          onClick={() => {
            setLink(editor);
          }}
          className={editor.isActive("link") ? "btn is-active" : "btn"}
        >
          setLink
        </Button>
        <Button
          className={"btn"}
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive("link")}
        >
          unsetLink
        </Button>
      </div>
      <div>
        <Button
          className="btn"
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          }
        >
          insertTable
        </Button>

        <Button
          className="btn"
          onClick={() => {
            const url = window.prompt("URL");
            if (url) {
              editor.chain().focus().setImage({ src: url }).run();
            }
          }}
        >
          Add Image
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().addColumnBefore().run()}
          disabled={!editor.can().addColumnBefore()}
        >
          addColumnBefore
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().addColumnAfter().run()}
          disabled={!editor.can().addColumnAfter()}
        >
          addColumnAfter
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().deleteColumn().run()}
          disabled={!editor.can().deleteColumn()}
        >
          deleteColumn
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().addRowBefore().run()}
          disabled={!editor.can().addRowBefore()}
        >
          addRowBefore
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().addRowAfter().run()}
          disabled={!editor.can().addRowAfter()}
        >
          addRowAfter
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().deleteRow().run()}
          disabled={!editor.can().deleteRow()}
        >
          deleteRow
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().deleteTable().run()}
          disabled={!editor.can().deleteTable()}
        >
          deleteTable
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().mergeCells().run()}
          disabled={!editor.can().mergeCells()}
        >
          mergeCells
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().splitCell().run()}
          disabled={!editor.can().splitCell()}
        >
          splitCell
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
          disabled={!editor.can().toggleHeaderColumn()}
        >
          toggleHeaderColumn
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().toggleHeaderRow().run()}
          disabled={!editor.can().toggleHeaderRow()}
        >
          toggleHeaderRow
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().toggleHeaderCell().run()}
          disabled={!editor.can().toggleHeaderCell()}
        >
          toggleHeaderCell
        </Button>
        <Button
          className="btn"
          onClick={() => editor.chain().focus().mergeOrSplit().run()}
          disabled={!editor.can().mergeOrSplit()}
        >
          mergeOrSplit
        </Button>
        <Button
          className="btn"
          onClick={() => {
            onSave(editor.getHTML());
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const Tiptap = (props) => {
  const { onSave, editable = false } = props;
  const [viewData, setViewData] = useState(props.data);
  const editor = useEditor({
    editable: editable,
    extensions: [
      StarterKit,
      Image,
      Highlight,
      Typography,
      Link.configure({
        openOnClick: false,
      }),
      Table.configure({
        resizable: editable,
        class: `table responsive ${editable ? "" : "noBorder"}`,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),

      TableRow,
      TableHeader,
      // Default TableCell
      // TableCell,
      // Custom TableCell with backgroundColor attribute
      CustomTableCell,
    ],
    content: viewData,
  });

  const setLink = useCallback(
    (editor) => {
      const previousUrl = editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    [editor]
  );
  return (
    <div
      className={`ProseMirror ${editable ? "" : "noBorder"}`}
      style={{
        display: "flex",
        minHeight: editable ? "400px" : "100px",
        flexDirection: "column",
      }}
    >
      {editable && (
        <MenuBar editor={editor} onSave={onSave} setLink={setLink} />
      )}

      <div className={`${editable ? "" : "noBorder"}`}>
        <EditorContent editor={editor} />
      </div>
      {editable && (
        <Button
          className="btn"
          onClick={() => {
            onSave(editor.getHTML());
          }}
        >
          {" "}
          Save
        </Button>
      )}
    </div>
  );
};

export default Tiptap;
