/* global BigInt */
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";
import Tiptap from "../components/Tiptap.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import { UserContext } from "../UserContext";
import MDComponent from "../components/MDComponent";
import FormStyles from "../components/FormStyles";
import { Field, Form } from "react-final-form";
import BaseService from "../service/BaseService.js";
import DashboardService from "../service/DashboardService.js";

export const EditFundComponent = (props) => {
  let { fundName } = useParams();
  const [fund, setFund] = useState({ meta: {} });
  const [files, setFiles] = useState([]);
  const [fundCategories, setFundCategories] = useState([]);
  const [datarooms, setDatarooms] = useState([]);
  const [activeTab, setActiveTab] = useState("Basic Info");
  const [loading, setLoading] = useState(false);
  const [context] = useContext(UserContext);
  let navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const close = () => {
    navigate(-1);
  };

  const onSubmit = async (values) => {
    await editVCFund(values);
  };

  const editVCFund = async (data) => {
    try {
      const token = await getAccessTokenSilently();
      data.status = data.status || "";

      data.fundRaisingStatus = data.fundRaisingStatus || "";

      // important
      data.name = data.name.trim();
      fundName = data.name;

      if (fund.id) {
        await VCFundService.editFund(token, fund.id, data);
      } else {
        await VCFundService.addFund(token, data);
      }
      loadData();
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const loadData = async () => {
    setLoading(true);
    if (fundName === "undefined") {
      setFund({ meta: {} });
      setLoading(false);

      return;
    }
    const token = await getAccessTokenSilently();

    try {
      let d = await VCFundService.getFundByName(token, fundName);

      let digify = await BaseService.digify(token);
      setFiles(digify?.data.files);
      setDatarooms(digify?.data.datarooms);

      let resp = await DashboardService.getFundCategory(token);
      setFundCategories(resp);

      if (d) {
        setFund(d);
      } else {
        setFund({ meta: {} });
      }
    } catch (e) {
      setFund({ meta: {} });
    }

    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [fundName]);

  const getModal = () => {
    return (
      <Container>
        <h1>{fund?.id ? "Edit VC Fund" : "Add VC Fund"}</h1>

        <div>
          {fund && (
            <FormStyles key={fund.id}>
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  name: fund.name,
                  meta: fund.meta,
                  fundCategoryId: fund.fundCategoryId,
                  //featured: fund.featured,
                  fundRaisingStatus: fund.fundRaisingStatus,
                  status: fund.status,
                  //externalMeta: JSON.stringify(fund.externalMeta || {}),
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Nav>
                      <NavItem>
                        <NavLink
                          className={activeTab === "Basic Info" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("Basic Info");
                          }}
                        >
                          Basic Info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={!fund.id}
                          className={activeTab === "Overview" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("Overview");
                          }}
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={!fund.id}
                          className={
                            activeTab === "Investment Details" ? "active" : ""
                          }
                          onClick={() => {
                            setActiveTab("Investment Details");
                          }}
                        >
                          Investment Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={!fund.id}
                          className={activeTab === "DataRoom" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("DataRoom");
                          }}
                        >
                          Dataroom
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={!fund.id}
                          className={activeTab === "Fund Info" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("Fund Info");
                          }}
                        >
                          Fund Info
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={"Basic Info"}>
                        <div className={"form-fields"}>
                          <label>Fund Name</label>
                          <Field
                            name="name"
                            component="input"
                            type="text"
                            placeholder="Name"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Firm Title</label>
                          <Field
                            name="meta.titleName"
                            component="input"
                            type="text"
                            placeholder="Title Name"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Quote</label>
                          <Field
                            name="meta.quote"
                            component="input"
                            type="text"
                            placeholder="Quote"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Background Color</label>
                          <Field
                            name="meta.backgroundColor"
                            component="input"
                            type="text"
                            placeholder="Background Color"
                          />
                        </div>

                        <div className={"form-fields"}>
                          <label>Font Color</label>
                          <Field
                            name="meta.fontColor"
                            component="input"
                            type="text"
                            placeholder="Font Color"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Logo URL</label>
                          <Field
                            name="meta.logoURL"
                            component="input"
                            type="text"
                            placeholder="Logo URL"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Theme</label>
                          <Field
                            name="meta.theme"
                            component="input"
                            type="text"
                            placeholder="Theme"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Founded</label>
                          <Field
                            name="meta.yearFounded"
                            component="input"
                            type="text"
                            placeholder="Theme"
                          />
                        </div>

                        <div className={"form-fields"}>
                          <label>Select Investments</label>
                          <Field
                            name="meta.iconicDeals"
                            component="input"
                            type="text"
                            placeholder="Select Investments"
                          />
                        </div>
                        {/* <div className={"form-fields"}>
                          <label>
                            Logo
                            <div>
                              <BufferImageComponent
                                width={10}
                                otherProps={{
                                  base64ImgBuffer: fund?.logoPhoto,
                                  imageCoverMode: "contain",
                                }}
                              />
                            </div>
                          </label>

                          <Field name="logoPhoto">
                            {(props) => (
                              <FileBase64
                                multiple={false}
                                onDone={(file) => {
                                  props.input.onChange(file.base64);
                                }}
                              />
                            )}
                          </Field>
                        </div> */}

                        <div className={"form-fields"}>
                          <label>Stage</label>
                          <Field
                            name="meta.stage"
                            component="input"
                            type="text"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Location(s)</label>
                          <Field
                            name="meta.hqLocation"
                            component="input"
                            type="text"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Website</label>
                          <Field
                            name="meta.website"
                            component="input"
                            type="text"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Raising Status (FO)</label>
                          <Field name="fundRaisingStatus" component="select">
                            <option value=""></option>
                            <option value="Pending">Pending</option>
                            <option value="Started">Started</option>
                            <option value="Closed">Closed</option>
                          </Field>
                        </div>

                        <div className={"form-fields"}>
                          <label>Raising Status (ALP)</label>
                          <Field
                            name="meta.ALP.fundRaisingStatus"
                            component="select"
                          >
                            <option value=""></option>
                            <option value="Pending">Pending</option>
                            <option value="Started">Started</option>
                            <option value="Closed">Closed</option>
                          </Field>
                        </div>
                        <div className={"form-fields"}>
                          <label>Status</label>
                          <Field name="status" component="select">
                            <option value=""></option>
                            <option value="Draft">Draft</option>
                            <option value="Published">Published</option>
                            <option value="Archived">Archived</option>
                          </Field>
                        </div>

                        <div className={"form-fields"}>
                          <label>Order (0=highest)</label>
                          <Field
                            min={0}
                            name="meta.order"
                            component="input"
                            type="number"
                          />
                        </div>
                      </TabPane>

                      <TabPane tabId={"DataRoom"}>
                        <h3>Dataroom</h3>

                        <div className={"form-fields"}>
                          <label>Public Video File</label>
                          <Input
                            type={"select"}
                            value={fund?.meta?.publicDigifyVideoGuid}
                            onChange={(e) => {
                              let meta = fund.meta;
                              meta.publicDigifyVideoGuid = e.target.value;
                              fund.meta = meta;
                              setFund({ ...fund });
                            }}
                          >
                            <option value="">None</option>
                            {(files || []).map((file, index) => {
                              return (
                                <option key={file.id} value={file.id}>
                                  {file.FileName}
                                </option>
                              );
                            })}
                          </Input>
                        </div>

                        <div className={"form-fields"}>
                          <label>Due Diligence Report</label>
                          <Input
                            type={"select"}
                            value={fund?.meta?.digifyReportGuid}
                            onChange={(e) => {
                              let meta = fund.meta;
                              meta.digifyReportGuid = e.target.value;
                              fund.meta = meta;
                              setFund({ ...fund });
                            }}
                          >
                            <option value="">None</option>
                            {(files || []).map((file, index) => {
                              return (
                                <option key={file.id} value={file.id}>
                                  {file.FileName}
                                </option>
                              );
                            })}
                          </Input>
                        </div>

                        <div className={"form-fields"}>
                          <label>Due Diligence Report Preview</label>
                          <Input
                            type={"select"}
                            value={fund?.meta?.digifyReportPreviewGuid}
                            onChange={(e) => {
                              let meta = fund.meta;
                              meta.digifyReportPreviewGuid = e.target.value;
                              fund.meta = meta;
                              setFund({ ...fund });
                            }}
                          >
                            <option value="">None</option>
                            {(files || []).map((file, index) => {
                              return (
                                <option key={file.id} value={file.id}>
                                  {file.FileName}
                                </option>
                              );
                            })}
                          </Input>
                        </div>

                        <div className={"form-fields"}>
                          <label>Digify Dataroom Guid</label>
                          <Input
                            type={"select"}
                            value={fund?.meta?.digifyRoomGuid}
                            onChange={(e) => {
                              let meta = fund.meta;
                              meta.digifyRoomGuid = e.target.value;
                              fund.meta = meta;
                              setFund({ ...fund });
                            }}
                          >
                            <option value="">--</option>
                            {(datarooms || []).map((room, index) => {
                              return (
                                <option
                                  key={room.DataRoomGuid}
                                  value={room.DataRoomGuid}
                                >
                                  {room.DataRoomName}
                                </option>
                              );
                            })}
                          </Input>
                        </div>
                      </TabPane>

                      <TabPane tabId={"Overview"}>
                        <MDComponent
                          readOnly={false}
                          key={"description-" + fund.id}
                          defaultValue={fund?.meta?.overview}
                          onSave={(desc) => {
                            let meta = fund.meta || {};
                            meta.overview = desc;
                            fund.meta = meta;
                            setFund({ ...fund });
                          }}
                        />
                      </TabPane>

                      <TabPane tabId={"Investment Details"}>
                        <div className={"border  col col-md-10 mx-auto"}>
                          <Tiptap
                            editable={true}
                            data={fund?.meta?.investmentDetails}
                            onSave={(investmentDetails) => {
                              let meta = fund.meta || {};
                              meta.investmentDetails = investmentDetails;
                              fund.meta = meta;
                              setFund({ ...fund });
                            }}
                          />
                        </div>
                      </TabPane>

                      <TabPane tabId={"Fund Info"}>
                        <h4>Fund</h4>

                        <div className={"form-fields"}>
                          <label>Tier</label>
                          <Input
                            type={"select"}
                            value={fund?.meta?.tier}
                            component="select"
                            onChange={(e) => {
                              fund.fundCategoryId = "";
                              fund.meta.tier = e.target.value;
                              setFund({ ...fund });
                              return;
                            }}
                          >
                            <option value="" />
                            {[...Array(6).keys()].map((t) => {
                              return (
                                <option key={t} value={(t + 1).toString()}>
                                  {t + 1}
                                </option>
                              );
                            })}
                          </Input>
                        </div>

                        <div className={"form-fields"}>
                          <label>Fund Category</label>
                          <Input
                            type={"select"}
                            name={"fundCategoryId"}
                            value={fund.fundCategoryId}
                            onChange={(e) => {
                              fund.fundCategoryId = e.target.value;
                              setFund({ ...fund });
                            }}
                          >
                            <option value=""></option>
                            {(fundCategories || []).map((d, index) => {
                              return (
                                <option
                                  key={d.id}
                                  value={d.id}
                                  disabled={d.tier != fund.meta?.tier}
                                >
                                  {d.name}
                                  {d.tier != fund.meta?.tier &&
                                    "(Available for Tier:" + d.tier + ") "}
                                  {" [" + d.name + "-" + d.id + "]"}
                                </option>
                              );
                            })}
                          </Input>
                        </div>

                        <div className={"form-fields"}>
                          <label>Fund Name</label>
                          <Field
                            name="meta.fundName"
                            component="input"
                            type="text"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Closing Date (FO)</label>
                          <Field
                            name="meta.closingDate"
                            component="input"
                            type="date"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>Closing Date (ALP)</label>
                          <Field
                            name="meta.ALP.closingDate"
                            component="input"
                            type="date"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>
                            Target Amount (FO){" "}
                            {BaseService.formatMoney(
                              values.meta?.fundTargetAmount
                            )}
                          </label>
                          <Field
                            name="meta.fundTargetAmount"
                            component="input"
                            type="number"
                          />
                        </div>

                        <div className={"form-fields"}>
                          <label>
                            Target Amount (ALP){" "}
                            {BaseService.formatMoney(
                              values.meta?.ALP?.fundTargetAmount
                            )}
                          </label>
                          <Field
                            name="meta.ALP.fundTargetAmount"
                            component="input"
                            type="number"
                          />
                        </div>
                        <div className={"form-fields"}>
                          <label>
                            Fund Amount Raised{" "}
                            {BaseService.formatMoney(
                              fund?.meta?.fundAmountRaised
                            )}
                          </label>
                          <Field
                            name="meta.fundAmountRaised"
                            component="input"
                            type="number"
                          />
                        </div>
                        <hr />
                        <div className={"form-fields"}>
                          <label>
                            V0 Allocation{" "}
                            {BaseService.formatMoney(
                              fund?.meta?.fundAllocation
                            )}
                          </label>
                          <Field
                            name="meta.fundAllocation"
                            component="input"
                            type="number"
                          />
                        </div>

                        <div className={"form-fields"}>
                          <label>
                            V0 Fund Raised{" "}
                            {BaseService.formatMoney(fund?.meta?.fundRaised)}
                          </label>
                          <Field
                            name="meta.fundRaised"
                            component="input"
                            type="number"
                          />
                        </div>

                        <div className={"form-fields"}>
                          <label>INVESTMENT AMOUNT (FO)</label>
                          <Field
                            name="meta.voCheckSize"
                            component="input"
                            type="number"
                          />

                          <span className="text-lightly-muted small">
                            {BaseService.formatMoney(values.meta?.voCheckSize)}
                          </span>
                        </div>

                        <div className={"form-fields"}>
                          <label>INVESTMENT AMOUNT (ALP)</label>
                          <Field
                            name="meta.ALP.voCheckSize"
                            component="input"
                            type="number"
                          />
                          <span className="text-lightly-muted small">
                            {BaseService.formatMoney(
                              values.meta?.ALP?.voCheckSize
                            )}
                          </span>
                        </div>

                        <label>
                          If V0 Check Size presented, it will override the fund
                          category min commitment amount
                        </label>

                        <hr />

                        <div className={"form-fields"}>
                          <label>
                            Min Check Size{" "}
                            {BaseService.formatMoney(
                              fund?.meta?.minimunCheckSize
                            )}
                          </label>
                          <Field
                            name="meta.minimunCheckSize"
                            component="input"
                            type="number"
                          />
                          <label>hidden for now</label>
                        </div>

                        <div className={"form-fields"}>
                          <label>Spots Left Message</label>
                          <Field
                            min={0}
                            name="meta.spotsLeftMessage"
                            component="input"
                            type="text"
                          />
                        </div>
                      </TabPane>
                    </TabContent>

                    <div className="buttons">
                      <Button
                        type="submit"
                        disabled={submitting}
                        color={"info"}
                      >
                        {fund?.id ? "Save" : "Add"}
                      </Button>

                      <Button
                        type="button"
                        onClick={form.reset}
                        disabled={submitting}
                      >
                        Reset
                      </Button>
                    </div>
                  </form>
                )}
              />
            </FormStyles>
          )}
        </div>
        <div>
          <Button color="secondary" onClick={close}>
            Back
          </Button>
        </div>
      </Container>
    );
  };

  return (
    <Container className="mb-5">
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`/admin`}>VC Funds</Link>
        </BreadcrumbItem>
        {fundName !== "undefined" && (
          <BreadcrumbItem active>{fundName}</BreadcrumbItem>
        )}
      </Breadcrumb>

      {loading ? <Loading /> : getModal()}
    </Container>
  );
};

export default withAuthenticationRequired(EditFundComponent, {
  onRedirecting: () => <Loading />,
});
