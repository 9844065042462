import React, { useState, useEffect, useContext } from "react";

import { useDarkMode } from "../useDarkMode";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { UserContext } from "../UserContext";
import ReactMarkdown from "react-markdown";

const MDComponent = (props) => {
  const { readOnly = true, onSave = undefined, defaultValue = "" } = props;

  const [theme] = useDarkMode();
  const [currentTheme, setCurrentTheme] = useState(theme);

  const [value, setValue] = useState(defaultValue);
  const [context] = useContext(UserContext);

  useEffect(() => {
    theme && setCurrentTheme(theme);
  }, [theme]); // <-- here put the parameter to listen

  useEffect(() => {
    context && context.theme && setCurrentTheme(context.theme);
  }, [context]); // <-- here put the parameter to listen

  useEffect(() => {
    onSave && onSave(value);
  }, [value]); // <-- here put the parameter to listen

  return (
    <div
      data-color-mode={readOnly ? currentTheme : "light"}
      style={{
        minHeight: readOnly ? "inherit" : "400px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!readOnly && (
        <MDEditor
          height={400}
          minHeight={400}
          visibleDragbar={false}
          highlightEnable={true}
          hideToolbar={readOnly}
          onChange={(val) => {
            setValue(val);
          }}
          value={value}
          preview={"live"}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      )}

      {readOnly && <ReactMarkdown children={value} />}
    </div>
  );
};
export default MDComponent;
