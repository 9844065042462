import React, { Fragment, Suspense, useEffect, useState } from "react";
import "./DDReport.scss";
import * as Sentry from "@sentry/react";
import {
  Badge,
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import {
  LockCircle,
  Lock,
  Lock1,
  Maximize4,
  Unlock,
  Note,
  DocumentText1,
} from "iconsax-react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useAuth0 } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import BaseService from "../service/BaseService";
import DashboardService from "../service/DashboardService";
import ResponsiveEmbed from "react-responsive-embed";
import Loading from "./Loading";
import useScreenType from "react-screentype-hook";
import FundReportActions from "./FundReportActions";
import { Fade } from "react-awesome-reveal";
import { Spinner } from "react-bootstrap";

const DDReport = (props) => {
  const actionButtonStyles = {};

  const reverseActionButtonStyles = {
    ...actionButtonStyles,
    backgroundColor: "#FFF",
    color: "#333",
    border: "#33333355 solid 1px",
    backgroundImage: "none",
  };

  const [activeTab, setActiveTab] = useState("Report");
  const handle = useFullScreenHandle();
  const screenType = useScreenType();

  const { theFund, userId, context } = { ...props };
  const [dataRoomModal, setDataRoomModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [fund, setFund] = useState(theFund);

  const [isLoading, setIsLoading] = useState(false);
  const [isByPassDDCredit, setIsByPassDDCredit] = useState(undefined);

  const [credits, setCredits] = useState(0);
  const [lpProfiles, setLPProfiles] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState(undefined);

  const toggleReport = () => setReportModal(!reportModal);
  const toggleDataroom = () => setDataRoomModal(!dataRoomModal);

  const loadData = async () => {
    const token = await getAccessTokenSilently();

    let resp = await BaseService.getUser(token);
    if (resp) {
      setUser(resp.data);
    }

    let resp2 = (await BaseService.getLPProfile(token)) || {};
    setLPProfiles(resp2);

    let credits = 0;
    //let expiredAt = "";

    let lp = resp2.find((lp) => lp.active);

    credits = parseInt(lp.meta.credits);
    setCredits(credits);

    let conf = await DashboardService.getConfiguration(token);

    let byPassCredit = conf?.find(
      (e) => e.name.toLowerCase() === "system-bypass-dd-credit"
    )?.meta?.value;
    setIsByPassDDCredit(byPassCredit === true);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setFund(theFund);
  }, [theFund]);

  useEffect(() => {
    try {
      const transaction = Sentry.startTransaction({ name: "DD Report Tab" });
      const span = transaction.startChild({ op: activeTab }); // This function returns a Span
      span.finish(); // Remember that only finished spans will be sent with the transaction
      transaction.finish(); // Finishing the transaction will send it to Sentry
    } catch (e) {}
  }, [activeTab]);

  const applyDD = async () => {
    const token = await getAccessTokenSilently();
    const lpEntityCode = user?.meta?.defaultLPEntityCode;
    try {
      setIsLoading(true);
      await VCFundService.applyDDFundByName(token, fund.name, lpEntityCode);
      let resp = await VCFundService.getFundByName(token, fund.name);
      setFund(resp);
    } catch (E) {
    } finally {
      setIsLoading(false);
    }
  };

  if (!fund) {
    return <Loading />;
  }

  const hasDataroom = () => {
    let report = fund.dd.map((dd) => {
      if (dd.EmbedRoomLink) return dd.EmbedRoomLink;
    });

    report = report.filter((item) => item);

    return report.length > 0;
  };

  const hasReport = () => {
    let report = fund.dd.map((dd) => {
      if (dd.EmbedFileLink) return dd.EmbedFileLink;
    });

    report = report.filter((item) => item);

    return report.length > 0;
  };

  const renderView = () => {
    if (isLoading) {
      return <Spinner color="info"></Spinner>;
    } else {
      return (
        <Button className={"primary-button base-button mt-3"} onClick={applyDD}>
          Unlock Section
        </Button>
      );
    }
  };

  const renderLockedState = () => {
    return (
      <Fragment>
        <div className={"cardName  mx-auto"}>
          <h4 className={"p-2 text-bold"}>Due Diligence</h4>
        </div>

        {!isByPassDDCredit ? (
          <div className={"d-flex justify-content-center w-100"}>
            <div className={"cardName"}>
              <Lock
                variant="Outline"
                size="80"
                color="#3e7cb1"
                className="my-4"
              />

              <h4 className={"py-3"}>1 Due Diligence Credit Required</h4>
              <Badge className={`TierBadge SmallerBadge my-0 m-auto`} pill>
                {credits} credits remaining
              </Badge>
            </div>
          </div>
        ) : (
          <div className={"d-flex justify-content-center w-100"}>
            <div className={"cardName"}>
              <Lock
                variant="Outline"
                size="80"
                color="#3e7cb1"
                className="my-4"
              />
              <h4 className={"py-3"}>Confidential Information</h4>
            </div>
          </div>
        )}
        <div className={"d-flex w-75 justify-content-center pt-3"}>
          {isByPassDDCredit ? (
            <div className={"text-muted"}>
              <div className="pb-3">
                {fund?.meta?.tier?.toString() === "1" ? (
                  <>
                    The due diligence report offers a concise overview and an
                    investment thesis of the fund, including an in-depth
                    analysis and insider information not typically available to
                    the public. To start reviewing the information, click
                    'Unlock section' to open the report and access the data room
                    files.
                  </>
                ) : (
                  <>
                    The brief report provides a concise overview and investment
                    thesis of the fund, along with insider information not
                    typically accessible to the public. Click 'Unlock section'
                    to open the report and start reviewing the information.
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className={"text-muted"}>
              <div>
                To unlock this section and view what’s here, a due diligence
                credit is required. Once unlocked, you will have access to
                confidential information:
                <ul className={"py-2"}>
                  {fund?.meta?.digifyReportGuid && <li>V0 Report</li>}
                  {fund?.meta?.digifyRoomGuid && <li>Fund Data Room</li>}
                </ul>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  };
  const renderLock = () => {
    return (
      <Button
        className={"primary-button base-button"}
        onClick={() => {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className={"bg-light py-3 "}>
                  <Row className="d-flex justify-content-between align-content-center align-items-center m-1">
                    <Col className="center align-items-center justify-content-center text-center">
                      <Lock
                        variant="Outline"
                        size="80"
                        color="#3e7cb1"
                        className={"my-4"}
                      />
                      <h3>Use Due Diligence Credit?</h3>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-content-center align-items-center m-1 mx-3">
                    <Col className="center align-items-center justify-content-center text-center text-light text-muted">
                      <p>
                        You have {credits} credits remaining this quarter.
                        Unlocking this section requires 1 due diligence credit.{" "}
                      </p>
                    </Col>
                  </Row>

                  <Container
                    fluid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Row style={{ textAlign: "center" }} className="my-4">
                      <Col>
                        <Button
                          className="mx-0 my-1 base-button ternary-button"
                          style={reverseActionButtonStyles}
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className="mx-0 my-1 base-button primary-button"
                          style={actionButtonStyles}
                          onClick={async () => {
                            await applyDD();
                            onClose();
                          }}
                        >
                          Yes, Unlock Section
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              );
            },
          });
        }}
      >
        Unlock Section
      </Button>
    );
  };

  if (isByPassDDCredit === undefined) {
    return <Loading />;
  } else if (!fund?.meta?.digifyRoomGuid && !fund?.meta?.digifyReportGuid) {
    return null;
  }

  if (fund?.dd.length > 0) {
    // unlocked!
    return (
      <div className={"DDReport bg-light py-5 w-100"}>
        <div
          className={
            "d-flex justify-content-center w-50 mx-auto text-align-center"
          }
        >
          <div className={"cardName  mx-auto"}>
            <h4 className={"p-2 text-bold"}>Due Diligence</h4>
          </div>
        </div>
        <div className={"text-muted"}>
          {/* <div className="text-align-center col-12 mx-auto">
            Due Diligence unlocked on{" "}
            {fund?.dd.length > 0
              ? BaseService.getDateString(fund.dd[0].createdAt)
              : ""}
          </div> */}
          <div className="mt-2 mb-5">
            <FundReportActions
              dd={fund?.dd}
              spotsLeftLocation={"center"}
              fund={theFund}
              fundCategory={fund.fundCategory}
              tier={fund?.meta.tier}
              userId={userId}
              vcFundActions={fund?.vcFundActions}
              fundName={fund?.name}
              lpEntityCode={
                context?.meta?.defaultLPEntityCode || lpProfiles[0]?.code
              }
              isALP={context?.meta?.defaultLPType === "ALP"}
              vcFundId={fund?.id}
              align={"center"}
            />
          </div>
        </div>
        <div className={"w-100"}>
          <Nav className="m-auto  mx-md-0 mx-sm-0 px-3">
            <NavItem>
              <NavLink
                className={activeTab === "Report" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Report");
                }}
              >
                V0 Report
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "Dataroom" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Dataroom");
                }}
              >
                Dataroom
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="Report">
              <Fade>
                <Container fluid className={"m-auto p-auto h-50"}>
                  {fund.dd.map((dd, index) => {
                    return (
                      dd.EmbedFileLink && (
                        <ResponsiveEmbed
                          key={"file-" + index}
                          ratio={screenType.isMobile ? "2:4" : "1:1"}
                          allowFullScreen
                          src={dd.EmbedFileLink}
                        />
                      )
                    );
                  })}
                  {!hasReport() && (
                    <div
                      className={"col col-12 mx-auto py-0 text-align-center"}
                    >
                      <div className={"text-muted pt-5"}>
                        The document is currently unavailable, please check
                        again later.
                      </div>
                    </div>
                  )}
                </Container>
              </Fade>
            </TabPane>

            <TabPane tabId="Dataroom">
              <Container fluid className={"m-auto p-auto text-align-center"}>
                {fund.dd.map((dd, index) => {
                  return (
                    dd?.EmbedRoomLink && (
                      <div
                        key={"outer-room-" + index}
                        className="border border-light"
                      >
                        <Suspense fallback={<Loading />}>
                          <ResponsiveEmbed
                            key={"room-" + index}
                            loading="lazy"
                            ratio={screenType.isMobile ? "2:4" : "1:1"}
                            allowFullScreen
                            src={dd?.EmbedRoomLink}
                          />
                        </Suspense>

                        <div className={"col col-12 mx-auto py-5"}>
                          <div className={"text-muted"}>
                            <div className="pb-3">
                              If you have any problem viewing the dataroom, try
                              the following button.
                            </div>
                            <Button
                              key={"room-" + index}
                              color="info"
                              className={
                                "base-button primary-button  mx-auto my-0 mb-3 rounded-1"
                              }
                              onClick={() => {
                                window.open(dd?.EmbedRoomLink);
                              }}
                            >
                              Access Dataroom
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
                {!hasDataroom() && (
                  <div className={"col col-12 mx-auto py-0"}>
                    <div className={"text-muted pt-5"}>
                      The data room is closed.
                    </div>
                  </div>
                )}
              </Container>
            </TabPane>
          </TabContent>
        </div>

        <Modal
          isOpen={dataRoomModal}
          toggle={toggleDataroom}
          {...props}
          fullscreen={true}
        >
          <ModalHeader toggle={toggleDataroom}>Dataroom files</ModalHeader>
          <ModalBody>
            <iframe
              title="Dataroom"
              width={"100%"}
              height={"100%"}
              src={fund?.dd[0].meta?.roomEmbedLink}
            ></iframe>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={reportModal}
          toggle={toggleReport}
          {...props}
          fullscreen={true}
        >
          <ModalHeader toggle={toggleReport}>V0 Due Diligence</ModalHeader>
          <ModalBody>
            {fund.dd?.map((dd, index) => {
              if (dd.meta?.type === "Digify") {
                return (
                  <iframe
                    key={"dd-" + index}
                    title="DataRoom"
                    src={dd.meta.fileEmbedLink}
                    width={"100%"}
                    height={"100%"}
                  ></iframe>
                );
              }

              return null;
            })}
          </ModalBody>
        </Modal>
      </div>
    );
  }

  // locked
  return (
    <div className="DDReport bg-light py-5">
      {renderLockedState()}
      <div className={"d-flex justify-content-center w-100"}>
        {isByPassDDCredit ? renderView() : credits >= 0 && renderLock()}
      </div>
    </div>
  );
};

export default DDReport;
