import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import logo from "../assets/logo.png";
import { Fade } from "react-awesome-reveal";
import "./FundSelection.scss";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  ReceiptSearch,
  MoneySend,
  Chart1,
  Chart,
  DocumentText1,
  FavoriteChart,
  Link1,
} from "iconsax-react";
import { Container, Row, Col } from "reactstrap";
const getCard = (data) => {
  return (
    <Fade triggerOnce>
      <div className="selectionContainer">
        <div className="selectionInnerContainer">
          <div
            className="my-md-3 ml-md-4 mr-md-2"
            style={{
              background: data.iconColor || "#3E7CB1",
              borderRadius: "70px",
              padding: "10px",
              height: "80px",
            }}
          >
            {data.iconComponent && data.iconComponent}
          </div>
          <div className={"px-md-3 textContainer"}>
            {data.link ? (
              <Link to={data.link}>
                <span className={"title link"}>{data?.title}</span>
              </Link>
            ) : (
              <span className="title">{data?.title}</span>
            )}
            {data?.subtitle && (
              <span className="text-lightly-muted  mt-1 subtitle">
                {data?.subtitle}
              </span>
            )}
          </div>
          {!data.subtitle && <div style={{ width: "60px" }}></div>}
        </div>
      </div>
    </Fade>
  );
};

const FundSelection = (props) => {
  return (
    <div className={"FundSelectionContainer mb-5"}>
      <Row className={"py-5"}>
        <Col>
          <div className={"text-center py-3 fund-category-title"}>
            How to Invest
          </div>

          <div className={"col col-md-10 mx-auto  fund-category-description"}>
            <Fade cascade triggerOnce>
              <p>
                As we continuously track fund activities across tens of
                thousands of datapoints to build a real-time venture landscape,
                the most noteworthy funds are promoted for our partners to
                engage. Distinctive indicators of fund performance we have
                uncovered and spend extra resources evaluating are: the skill of
                each GP and the combined execution of the partnership.
                Therefore, we leverage our Silicon Valley network and VC
                experience to uncover hidden dynamics of the partnership and the
                strengths and weaknesses of GPs when we meet them in person.
              </p>
              <p>
                Through a proprietary due diligence process by our partners, we
                analyze data that goes beyond what is shared in data rooms and
                distill our insights into V0 due diligence reports. Final V0
                selected funds for investment are placed into Alpha, Core, and
                Thematic categories. Below are the steps that you would take to
                initiate capital commitment into limited allocations.
              </p>
            </Fade>
          </div>
        </Col>
      </Row>

      <Container>
        <Tree
          nodePadding={"0px"}
          label={getCard({
            iconComponent: (
              <ReceiptSearch size="60" color={"white"} className="icon" />
            ),
            iconColor: "#3E7CB1",
            title: "View Fund Gallery",
            subtitle:
              "We unveil new funds as they pass through our selection criteria.",
            link: "/fund-gallery",
          })}
          lineHeight="40px"
          lineColor="#ccc"
          lineWidth={"2px"}
          lineBorderRadius={"1px"}
        >
          <TreeNode
            label={getCard({
              iconComponent: (
                <Chart size="60" color={"white"} className="icon" />
              ),
              iconColor: "#E6AF2E",
              title: "View V0 Selected Funds",
              subtitle:
                "Alpha funds have the most limited allocation. Committing early will increase but not guarantee the amount of capital you can commit.",
              link: "/fund-gallery#V0-Selected-Funds",
            })}
          >
            <TreeNode
              label={getCard({
                iconComponent: (
                  <DocumentText1 size="60" color={"white"} className="icon" />
                ),
                iconColor: "#E6AF2E",
                title: "Due Diligence",
                subtitle:
                  "Review V0 Due Diligence Report and data room for funds of interest.",
              })}
            >
              <TreeNode
                label={getCard({
                  iconComponent: (
                    <img
                      alt="logo"
                      src={logo}
                      width={60}
                      style={{
                        padding: "5px",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                  ),
                  iconColor: "#E6AF2E",
                  title: "Commit",
                  subtitle:
                    "After you commit, our team will reach out to you to finalize details of your investment. This commitment can be changed.",
                })}
              ></TreeNode>
            </TreeNode>
          </TreeNode>
          <TreeNode
            label={getCard({
              iconComponent: (
                <Chart1 size="60" color={"white"} className="icon" />
              ),
              iconColor: "#808A9F",
              title: "View Upcoming Funds",
              subtitle:
                "These funds can be accessed by V0 but are not available yet. You can still express interest.",
            })}
          >
            <TreeNode
              label={getCard({
                iconComponent: (
                  <DocumentText1 size="60" color={"white"} className="icon" />
                ),
                iconColor: "#808A9F",
                title: "Pre-commit",
                subtitle:
                  "This is not binding and is an expression of potential interest only. ",
              })}
            />
          </TreeNode>
        </Tree>
      </Container>
    </div>
  );
};

export default FundSelection;
