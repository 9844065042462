import React, { useEffect, useContext, useState, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import "./UserRegistration.scss";
import useScreenType from "react-screentype-hook";
import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";
import { Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import Loading from "./Loading";
import ResponsiveEmbed from "react-responsive-embed";

export default function UserRegistration(props) {
  const { user } = { ...props };
  const navigate = useNavigate();
  const screenType = useScreenType();
  const [currentStep, setCurrentStep] = useState(0);
  const [showFinalStep, setShowFinalStep] = useState(false);
  const [isALP, setIsALP] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [context, setContext] = useContext(UserContext);

  const { getAccessTokenSilently } = useAuth0();
  const scrollToTop = () => {
    try {
      document.getElementById("UserRegistration").scrollIntoView();
    } catch (e) {}
  };

  const [lpProfiles, setLPProfiles] = useState([]);

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let user = await BaseService.getUser(token);

      let resp2 = (await BaseService.getLPProfile(token)) || {};

      let alp = user.data.meta?.defaultLPType === "ALP";
      setLPProfiles(resp2);
      setIsALP(alp);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const next = () => {
    setLoading(true);
    setCurrentStep(currentStep + 1);
    setTimeout(() => {
      setLoading(false);
      scrollToTop();
    }, 200);
  };

  const confirm = async () => {
    setError(undefined);
    setLoading(true);
    scrollToTop();

    const token = await getAccessTokenSilently();

    try {
      let resp = await BaseService.activateInvitation(token, {
        email: user?.email,
      });

      setTimeout(() => {
        setLoading(false);
      }, 1000);

      if (!resp.error) {
        setCurrentStep(-1);
        setShowFinalStep(true);
      } else {
        setCurrentStep(-1);
        setShowFinalStep(true);
        setError(resp.error);
      }
    } catch (e) {
      alert(e);
      return;
    }
  };

  const gotoDashboard = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();

    let c = await BaseService.login(token);
    setContext(c.data);
    navigate("/");
  };

  const gotoHow = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();

    let c = await BaseService.login(token);
    setContext(c.data);
    navigate("/how-to-invest");
  };

  useEffect(() => {
    setLoading(true);
    let timerId = setTimeout(() => {
      loadData();
    }, 100);

    return () => clearTimeout(timerId);
  }, []);

  if (isALP === undefined) {
    return <Loading></Loading>;
  } else {
    return (
      <Container
        fluid
        className={"p-auto mt-0 mb-5 m-auto mx-1 UserRegistration"}
        id="UserRegistration"
      >
        {loading && (
          <div className={"h-100"}>
            <Loading></Loading>
          </div>
        )}

        <Container className={"inner  px-3 rounded p-auto m-auto"}>
          {/* {currentStep !== -1 && (
          <Stepper
            styleConfig={{
              activeBgColor: "#3e7cb1",
              completedBgColor: "#3e7cb1",
            }}
            steps={[
              { label: "Terms of Service" },
              //{ label: "Payment Information" },
            ]}
            activeStep={currentStep}
          />
        )} */}
          {showFinalStep && (
            <Fade triggerOnce>
              <Row>
                <Col className={"mb-3 mt-3"} lg={12} md={12} sm="12" xs="12">
                  <h2 className={"text-center mt-5"}>
                    Welcome to the V0 Platform!
                  </h2>
                </Col>
              </Row>
              <Row className={"text-center px-md-5"}>
                <Col>
                  <span className={"text-align-center"}>
                    You are here because of our shared commitment to venture
                    capital. We look forward to developing our partnership with
                    you.
                  </span>
                </Col>
              </Row>
              <Row className={"text-align-center"}>
                <Col>
                  <Button
                    className={"mt-4 mb-5 primary-button base-button"}
                    onClick={() => {
                      if (isALP) {
                        gotoDashboard();
                      } else {
                        gotoHow();
                      }
                    }}
                  >
                    Let's start!
                  </Button>
                </Col>
              </Row>
            </Fade>
          )}
          {currentStep === 0 && (
            <Fade triggerOnce>
              <Row>
                <Col className={"mb-0 mt-3"} lg={12} md={12} sm="12" xs="12">
                  {lpProfiles.length > 0 && (
                    <h2 className={"text-align-center mt-5 p-3"}>
                      Welcome {user.given_name}
                      {!isALP && ` from ${lpProfiles[0].companyName}`}!
                    </h2>
                  )}
                </Col>
              </Row>

              <Row className={"px-md-5 pb-5"}>
                <Col>
                  <span className={"text-align-center"}>
                    Before you access the platform, please review carefully and
                    agree to our Terms of Service by clicking the button below.
                  </span>
                </Col>
              </Row>

              <div className={"p-0 bg-light"}>
                <ResponsiveEmbed
                  ratio={screenType.isMobile ? "2:4" : "1:1"}
                  src={
                    require("../assets/Terms of Service Agreement (8.29.2023).pdf") +
                    "#toolbar=0"
                  }
                ></ResponsiveEmbed>

                <Row>
                  <Col
                    className={"my-3 text-align-center"}
                    lg={12}
                    md={12}
                    sm="12"
                    xs="12"
                  >
                    <Button
                      className={"primary-button base-button"}
                      onClick={() => confirm()}
                    >
                      Accept
                    </Button>
                  </Col>
                </Row>
              </div>
            </Fade>
          )}
        </Container>
      </Container>
    );
  }
}
