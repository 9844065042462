import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabPane,
  TabContent,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import NumericInput from "react-numeric-input";
import { Form, Field } from "react-final-form";
import { useAuth0 } from "@auth0/auth0-react";
import FormStyles from "./FormStyles";
import DashboardService from "../service/DashboardService";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Badge, Nav, NavItem, NavLink } from "react-bootstrap";
import BaseService from "../service/BaseService";
import {
  AddCircle,
  Menu,
  Refresh,
  TextalignJustifycenter,
} from "iconsax-react";

export default function FundCategoryTable(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [activeTab, setActiveTab] = useState("Basic Info");
  const { editEnabled = false } = props;
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [row, setRow] = useState(undefined);

  const close = () => {
    setModal(false);
  };

  const edit = (data) => {
    setRow(data);
    setModal(true);
  };

  const save = async (data) => {
    let d = {
      name: data.name,
      description: data.description,
      title: data.title,
      managementFees: data.managementFees,
      carry: data.carry,
      tier: data.tier,
      status: data.status,
      meta: data.meta,
    };

    try {
      const token = await getAccessTokenSilently();
      if (row.id) {
        await DashboardService.editFundCategory(token, row.id, d);
      } else {
        await DashboardService.addFundCategory(token, d);
      }
      loadData();
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const deleteRow = async (id) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const token = await getAccessTokenSilently();
              await DashboardService.deleteFundCategory(token, id);
              loadData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await DashboardService.getFundCategory(token);
      setData(resp);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        toggle={close}
        fullscreen={false}
        backdrop={true}
        fade={true}
        size={"xl"}
        scrollable={true}
      >
        <ModalHeader toggle={close} className="bg-light">
          {row?.id ? "Edit" : "Add"}
        </ModalHeader>
        <ModalBody>
          {row && (
            <FormStyles>
              <Form
                onSubmit={async (values, { setSubmitting }) => {
                  await save(values);
                  setModal(false);
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Required";
                  }

                  return errors;
                }}
                initialValues={{
                  name: row.name,
                  title: row.title,
                  managementFees: row.managementFees,
                  carry: row.carry,
                  tier: row.tier,
                  description: row.description,
                  status: row.status,
                  meta: row.meta,
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Nav>
                      <NavItem>
                        <NavLink
                          className={activeTab === "Basic Info" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("Basic Info");
                          }}
                        >
                          Basic Info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === "ALP" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("ALP");
                          }}
                        >
                          ALP Specific
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={"Basic Info"}>
                        <div className={"form-fields"}>
                          <label>Name</label>
                          <Field name="name" component="input" type="text" />
                        </div>
                        <div className={"form-fields"}>
                          <label>Title</label>
                          <Field name="title" component="input" type="text" />
                        </div>

                        <div className={"form-fields"}>
                          <label>Tier</label>
                          <Field name="tier" component="select">
                            <option value="" />
                            {[...Array(6).keys()].map((t) => {
                              return (
                                <option key={t} value={t + 1}>
                                  {t + 1}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div className={"form-fields"}>
                          <label>Carry</label>
                          <Field name="carry" component="input" type="text" />
                        </div>

                        <div className={"form-fields"}>
                          <label>Min Check Size</label>

                          <Field
                            name="meta.min"
                            render={({ input, meta }) => (
                              <div>
                                <NumericInput
                                  step={1000}
                                  precision={0}
                                  snap
                                  min={0}
                                  {...input}
                                />
                              </div>
                            )}
                          />
                          <span className="text-lightly-muted small">
                            {BaseService.formatMoney(values.meta?.min)}
                          </span>
                        </div>

                        <div className={"form-fields"}>
                          <label>Max Check Size</label>

                          <Field
                            name="meta.max"
                            render={({ input, meta }) => (
                              <div>
                                <NumericInput
                                  step={1000}
                                  precision={0}
                                  snap
                                  min={0}
                                  {...input}
                                />
                              </div>
                            )}
                          />
                          <span className="text-lightly-muted small">
                            {BaseService.formatMoney(values.meta?.max)}
                          </span>
                        </div>

                        <div className={"form-fields"}>
                          <label>Management Fees</label>
                          <Field
                            name="managementFees"
                            component="input"
                            type="text"
                          />
                        </div>

                        <Field
                          name="description"
                          render={({ input, meta }) => (
                            <div className={"form-fields"}>
                              <label>Description</label>
                              <textarea {...input} rows="4" />
                            </div>
                          )}
                        />

                        {/* <div className={"form-fields"}>
                          <label>Status</label>
                          <Field name="status" component="select">
                            <option value="" />
                            <option value="PUBLISHED">PUBLISHED</option>
                            <option value="UNPUBLISHED">UNPUBLISHED</option>
                          </Field>
                        </div>

                        <div className={"form-fields"}>
                          <label>Color Code</label>
                          <Field
                            name="meta.color"
                            component="input"
                            type="text"
                          />
                        </div> */}
                      </TabPane>
                      <TabPane tabId={"ALP"}>
                        <div className={"form-fields"}>
                          <label>Carry</label>
                          <Field
                            name="meta.ALP.carry"
                            component="input"
                            type="text"
                          />
                        </div>

                        <div className={"form-fields"}>
                          <label>Min Check Size</label>

                          <Field
                            name="meta.ALP.min"
                            render={({ input, meta }) => (
                              <div>
                                <NumericInput
                                  step={1000}
                                  precision={0}
                                  snap
                                  min={0}
                                  {...input}
                                />
                              </div>
                            )}
                          />
                          <span className="text-lightly-muted small">
                            {BaseService.formatMoney(values.meta?.ALP?.min)}
                          </span>
                        </div>

                        <div className={"form-fields"}>
                          <label>Max Check Size</label>

                          <Field
                            name="meta.ALP.max"
                            render={({ input, meta }) => (
                              <div>
                                <NumericInput
                                  step={1000}
                                  precision={0}
                                  snap
                                  min={0}
                                  {...input}
                                />
                              </div>
                            )}
                          />
                          <span className="text-lightly-muted small">
                            {BaseService.formatMoney(values.meta?.ALP?.max)}
                          </span>
                        </div>

                        <div className={"form-fields"}>
                          <label>Management Fees</label>
                          <Field
                            name="meta.ALP.managementFees"
                            component="input"
                            type="text"
                          />
                        </div>

                        <Field
                          name="meta.ALP.description"
                          render={({ input, meta }) => (
                            <div className={"form-fields"}>
                              <label>Description</label>
                              <textarea {...input} rows="4" />
                            </div>
                          )}
                        />
                      </TabPane>
                    </TabContent>

                    <div className="buttons">
                      <button type="submit" disabled={submitting}>
                        {row?.id ? "Save" : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={form.reset}
                        disabled={submitting}
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              />
            </FormStyles>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Button
        color="primary"
        className={"m-1"}
        onClick={() => {
          loadData();
        }}
      >
        <Refresh color="#FFF" />
      </Button>
      <Button
        color="primary"
        className={"m-1"}
        onClick={() => {
          edit({});
        }}
      >
        <AddCircle />
      </Button>

      <Table size="sm" responsive={true} style={{ minHeight: "200px" }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Tier</th>
            <th>Description</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => {
            return (
              <tr key={index}>
                <td>{d.name}</td>
                <td>
                  <p>{d.title}</p>
                  <Badge>
                    {d.name}-{d.id}
                  </Badge>
                </td>

                <td>{d.tier}</td>
                <td>
                  <Badge>Carry:{d.carry}</Badge>{" "}
                  <Badge>
                    {" "}
                    Management Fees:
                    {d.managementFees}
                  </Badge>{" "}
                  <br />
                </td>
                <td>{d.status}</td>

                <td>
                  <UncontrolledDropdown className="rounded">
                    <DropdownToggle color={""}>
                      <TextalignJustifycenter />
                    </DropdownToggle>
                    <DropdownMenu className="btn-light">
                      <DropdownItem
                        onClick={() => {
                          edit(d);
                        }}
                      >
                        Edit
                      </DropdownItem>

                      <DropdownItem
                        onClick={() => {
                          deleteRow(d.id);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}
