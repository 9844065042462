import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";

export default function LogoBadge(props) {
  return (
    <div
      style={{
        width: "100px",
        height: "100px",
        margin: "auto",
        borderRadius: "50%",
        borderWidth: "0px",
        backgroundColor: "#3e7cb1",
      }}
    >
      <img
        title="logo"
        alt="logo"
        src={logo}
        width={80}
        style={{
          padding: "5px",
          marginTop: "15px",
          filter: "brightness(0) invert(1)",
        }}
      />
    </div>
  );
}
