import React, { Fragment, useEffect, useContext, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import { Fade, Slide } from "react-awesome-reveal";
import useScreenType from "react-screentype-hook";
import ReactDOM from "react-dom";

//import FileField from "./FileField.tsx";
import { useDarkMode } from "../useDarkMode";
import VCFundCard from "./VCFundCard";
import FundAnnouncement from "./FundAnnouncement";
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowLeft,
  ArrowRight,
} from "iconsax-react";
import { UserContext } from "../UserContext";

export default function VCFundListing(props) {
  let limit = 100;
  let defaultOffset = 0;
  let defaultQuery = "";
  let defaultViewStatus = undefined;

  const navigate = useNavigate();
  const [context, setContext] = useContext(UserContext);

  const { getAccessTokenSilently } = useAuth0();

  const { favoriteOnly = false, publicView = true, tier = undefined } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [offset, setOffset] = useState(defaultOffset);
  const [query, setQuery] = useState(defaultQuery);
  const [viewStatus, setViewStatus] = useState(defaultViewStatus);
  const fetchByDotOperator = (object, value) => {
    return value.split(".").reduce((acc, curr) => acc[curr], object);
  };
  const groupBy = (arr, property) => {
    return arr.reduce((acc, cur) => {
      acc[fetchByDotOperator(cur, property)] = [
        ...(acc[fetchByDotOperator(cur, property)] || []),
        cur,
      ];
      return acc;
    }, {});
  };
  const loadData = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (!favoriteOnly) {
        let resp = await VCFundService.getAll(
          token,
          limit,
          offset,
          query,
          publicView,
          viewStatus,
          tier
        );

        // separate into categories
        let rows = resp.rows;
        if (rows) {
          let d = groupBy(rows, "fundCategory.name");
          //console.log(d);

          setData(d);
        }

        // let resp2 = await BaseService.getUser(token);
        // setMyFav(resp2.data.favFunds);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const getSize = (tier) => {
    const mapping = {
      1: 10,
      2: 4,
      3: 3,
      4: 3,
      5: 3,
      6: 3,
    };
    return mapping[tier];
  };

  // Tier 6
  if (tier === 6) {
    return (
      <Fragment>
        {Object.keys(data).map((cat) => {
          let row = data[cat];
          let firstRow = data[cat][0];
          return (
            <Fragment key={cat}>
              <Row className={"pt-5 pb-3 page-width mx-auto"}>
                {!props.hideCategoryTitle && (
                  <Col>
                    <Fade triggerOnce cascade>
                      <h4 className="text-center py-3 fund-category-title">
                        {firstRow.fundCategory.title}
                      </h4>
                      <div
                        className={
                          "col col-md-10 mx-auto  fund-category-description"
                        }
                      >
                        {firstRow.fundCategory.description}
                      </div>
                    </Fade>
                  </Col>
                )}
              </Row>

              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "84%",
                  alignContent: "center",
                  margin: "auto",
                }}
              >
                <Fade cascade triggerOnce>
                  {row.map((d, index) => {
                    return (
                      <div className="px-3" key={index}>
                        <VCFundCard
                          key={index}
                          data={d}
                          showQuote={false}
                          title={firstRow.fundCategory.title}
                        />
                      </div>
                    );
                  })}
                </Fade>
              </div>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  // Tier 3
  if (tier === 3) {
    return (
      <Fragment>
        {Object.keys(data).map((cat) => {
          let row = data[cat];
          let firstRow = data[cat][0];
          return (
            <Fragment key={cat}>
              <Row className={"pt-5 pb-3 page-width mx-auto"}>
                <Col>
                  <Fade triggerOnce>
                    <h4 className="text-center py-3 fund-category-title">
                      {firstRow.fundCategory.title}
                    </h4>
                    <div
                      className={
                        "col col-md-10 mx-auto  fund-category-description"
                      }
                    >
                      {firstRow.fundCategory.description}
                    </div>
                  </Fade>
                </Col>
              </Row>

              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "84%",
                  alignContent: "center",
                  margin: "auto",
                }}
              >
                <Fade cascade triggerOnce>
                  {row.map((d, index) => {
                    return (
                      <div className="px-3" key={index}>
                        <VCFundCard
                          key={index}
                          data={d}
                          showQuote={false}
                          title={firstRow.fundCategory.title}
                        />
                      </div>
                    );
                  })}
                </Fade>
              </div>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  // Tier 2
  if (tier === 2 && context.meta?.defaultLPType !== "ALP") {
    return (
      <Fragment>
        {Object.keys(data).map((cat) => {
          let row = data[cat];
          let firstRow = data[cat][0];
          return (
            <Fragment key={cat}>
              {context.meta?.defaultLPType !== "ALP" && (
                <Row className={"pt-5 pb-3 page-width mx-auto"}>
                  <Col>
                    <Fade triggerOnce>
                      <h4 className="text-center py-3 fund-category-title">
                        {firstRow.fundCategory.title}
                      </h4>
                      <div
                        className={
                          "col col-md-10 mx-auto  fund-category-description"
                        }
                      >
                        {firstRow.fundCategory.description}
                      </div>
                    </Fade>
                  </Col>
                </Row>
              )}
              <Container
                className={"page-width"}
                style={{
                  justifyContent:
                    row.length % 2 === 0 ? "center" : "space-around",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  //  alignContent: "center",
                }}
              >
                <Fade
                  cascade
                  triggerOnce
                  className={`${row.length % 2 === 0 ? "mx-1" : ""}`}
                >
                  {row.map((d, index) => {
                    return (
                      <VCFundCard
                        widthMode={true}
                        key={index}
                        data={d}
                        showQuote={false}
                        title={firstRow.fundCategory.title}
                      />
                    );
                  })}
                </Fade>
              </Container>
            </Fragment>
          );
        })}
      </Fragment>
    );
  } else {
    // only for 1
    let number = 0;
    return (
      <Fragment>
        {Object.keys(data).map((cat) => {
          let row = data[cat];
          number += 1;
          let firstRow = data[cat][0];
          return (
            <Fragment key={cat}>
              {context.meta?.defaultLPType !== "ALP" && (
                <Row className={"pt-5 pb-3 page-width mx-auto"}>
                  <Col>
                    <Fade triggerOnce>
                      <h4 className="text-center py-3 fund-category-title">
                        {firstRow.fundCategory.title}
                      </h4>
                      <div
                        className={
                          "col col-md-10 mx-auto pb-3 fund-category-description"
                        }
                      >
                        {firstRow.fundCategory.description}
                      </div>
                    </Fade>
                  </Col>
                </Row>
              )}
              <Row
                style={
                  {
                    //maxWidth: tier === 2 ? "83%" : "inherit",
                    //margin: tier === 2 ? "auto" : "inherit",
                  }
                }
              >
                <Fade triggerOnce={true} cascade delay={1000}>
                  {row.map((d, index) => {
                    return (
                      <Col
                        className={"mx-auto"}
                        key={index}
                        sm={12}
                        md={
                          context.meta?.defaultLPType === "ALP"
                            ? 12
                            : tier > 3
                            ? 6
                            : 12
                        }
                        lg={
                          context.meta?.defaultLPType === "ALP"
                            ? 12
                            : getSize(tier)
                        }
                      >
                        <VCFundCard
                          showBadge={context.meta?.defaultLPType !== "ALP"}
                          listingView={true}
                          data={d}
                          displayAsTier1={context.meta?.defaultLPType === "ALP"}
                          tier={tier}
                          showQuote={false}
                          title={firstRow.fundCategory.title}
                        />
                      </Col>
                    );
                  })}
                </Fade>
              </Row>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
}
