import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { ConsentProvider } from "react-hook-consent";

const onRedirectCallback = (appState: AppState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const consentOptions = {
  services: [
    {
      id: "cookie-essential",
      name: "Essential",
      description:
        "These cookies are necessary to the core functionality of our website and some of its features, such as access to secure areas.",

      mandatory: true,
    },
    {
      id: "cookie-analytic",
      name: "Analytic",
      description:
        "These cookies collect information that can help us understand how our websites are being used. This information can also be used to measure effectiveness in our marketing campaigns or to curate a personalized site experience for you.",
    },
  ],
  theme: "light",
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const auth0ProviderConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <Auth0Provider {...auth0ProviderConfig}>
      <ConsentProvider options={consentOptions}>
        <App />
      </ConsentProvider>
    </Auth0Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
