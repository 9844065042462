import React, { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { PopupButton, Widget } from "@typeform/embed-react";
import { Button } from "reactstrap";
import Hero2 from "../components/Hero2";
import Linkify from "react-linkify";

const Signup = () => {
  let navigate = useNavigate();

  const loadData = async () => {};

  const getQueryVariable = (variable) => {
    var query = window.location.href.split("?")[1];
    var vars = (query || "").split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return "";
  };

  useEffect(() => {
    return () => {
      // Side-effect cleanup
    };
  }, []);

  let title = "Oops!";
  let failed = getQueryVariable("failed");

  if (!failed) {
    title = "Sign up";
  }

  let props = {};
  if (failed) {
    props.subtitleComponent = (
      <div className="pl-5 pr-3 mr-5 text-oops">
        <p className={"mb-2"}>
          We're sorry, but the email address you entered does not match any
          accounts in our system.
        </p>
        <br />
        <p className={"mb-2 mt-2"}>
          <p>Please consider the following:</p>
          <ul className={"mb-2"}>
            <li className={"mb-3"}>
              <h6>Check Your Email:</h6>
              Follow the link provided in the invitation email to start the
              invitation process.
            </li>

            <li className={"mb-3"}>
              <h6>Check Your Email Address:</h6>Confirm that the email address
              you enter is the same one that received the welcome email from V0.
            </li>
            <li className={"mb-3"}>
              <h6>
                Add Your Email Address to Your Family Office (FO) Membership:
              </h6>
              Provide your email address in the registration form in the
              membership invitation email that was sent to the primary FO
              contact.
            </li>
            <li className={"mb-3"}>
              <h6>Request an Introduction:</h6>If you have not received a
              membership invitation, please click the button below to initiate
              this process. Our team will review your request and get back to
              you shortly.
            </li>
          </ul>
          <br />
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <span>
              For additional assistance, please contact us at
              support@v0invest.com. We're here to help.
            </span>
          </Linkify>
        </p>
      </div>
    );
  }
  return (
    <>
      <Hero2
        image={failed ? 3 : 4}
        showDownArrow={false}
        backgroundSize={"auto 120%"}
        title={title}
        {...props}
        otherComponent={
          <>
            <PopupButton
              id="f7VpR4zj"
              className="m-5 primary-button base-button"
            >
              Request an Introduction
            </PopupButton>
          </>
        }
      />
    </>
  );
};

export default Signup;
