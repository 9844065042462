import React from "react";
import { CardImg } from "reactstrap";

const Buffer = require("buffer/").Buffer;

export default function BufferImage(props) {
  const {
    base64ImgBuffer,
    size,
    imageCoverMode = "cover",
    ...otherProps
  } = props;

  let base64Img;
  if (base64ImgBuffer) {
    base64Img = Buffer.from(base64ImgBuffer).toString("utf8");
  }

  if (!base64Img) {
    return null;
  }

  return (
    <CardImg
      src={base64Img}
      style={{
        maxHeight: size,
        maxWidth: size,
        height: size,
        width: size,
        objectFit: imageCoverMode,
      }}
      {...otherProps}
    />
  );
}
