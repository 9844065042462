import React, { Fragment, useState, useEffect } from "react";
import {
  Badge,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import "./PostInvestmentSelector.scss";
import { Fade, Slide } from "react-awesome-reveal";
import BaseService from "../../service/BaseService";
import PostInvestmentService from "../../service/PostInvestmentService";
import { Link, useNavigate } from "react-router-dom";

export const PostInvestmentSelector = (props) => {
  const { token, currentFundName } = { ...props };
  const [data, setData] = useState(undefined);
  const navigate = useNavigate();
  const loadData = async () => {
    if (!token) {
      return;
    }
    let resp = (await BaseService.getCurrentLPProfile(token)) || {};

    let committedWithPostInvestment = resp.committed.filter(
      (c) => c.fund.postInvestmentOverview
    );

    setData(committedWithPostInvestment);
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, [token]);

  if (!data || data?.length <= 1) {
    return null;
  }

  return (
    <div className={"PostInvestmentSelector mt-3"}>
      <UncontrolledDropdown
        className={"border py-2 px-4 z-3"}
        direction="down"
        size="sm"
        group={false}
        nav={false}
        menuRole={"menu"}
        inNavbar={true}
      >
        <DropdownToggle nav caret>
          <span className={"title"}>Select Invested Funds</span>
        </DropdownToggle>
        {data && data.length > 0 && (
          <DropdownMenu className={""}>
            {data.map((d, index) => {
              return (
                <DropdownItem className={"py-2 pe-none"} key={index}>
                  <div
                    disabled={d.fund.name === currentFundName}
                    onClick={() => {
                      navigate(`/dashboard/post-investment/${d.fund.name}`);
                    }}
                  >
                    <span
                      className={`${
                        d.fund.name === currentFundName
                          ? "disabled "
                          : " pe-auto"
                      }  `}
                    >
                      {d.fund.meta?.titleName} {" - "}
                      {d.fund.meta?.fundName}
                    </span>
                  </div>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    </div>
  );
};

export default PostInvestmentSelector;
