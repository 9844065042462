import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";

import {
  Container,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import "./ManageTeam.scss";
import TeamMembers from "../components/TeamMembers";
import { Fade } from "react-awesome-reveal";

export const ManageTeamComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState({});

  const [data, setData] = useState([]);
  const [lpProfiles, setLPProfiles] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [activeTab, setActiveTab] = useState("Members");

  const loadData = async () => {
    const token = await getAccessTokenSilently();
    let resp = await BaseService.getUser(token);
    if (resp) {
      setUser(resp.data);
    }
    let resp2 = (await BaseService.getLPProfile(token)) || {};
    setLPProfiles(resp2);

    let lp = resp2.find((lp) => lp.code === resp.data.meta.defaultLPEntityCode);
    if (lp) {
      setCompanyName(lp.companyName);
    }

    let resp3 = (await BaseService.getLPTeamMembers(token, lp.code)) || {};

    setData(resp3);
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  useEffect(() => {
    //localStorage.setItem("activeManageTeamTab", activeTab);
    return () => {
      // Side-effect cleanup
    };
  }, [activeTab]);

  return (
    <Container className={"mb-5 px-lg-5 px-xl-5 px-3 ManageTeam"}>
      <h3 className="welcome-title mt-5 mb-5 px-3  pb-3">{companyName}</h3>

      <div className="mt-3">
        <Nav>
          <NavItem>
            <NavLink
              className={activeTab === "Members" ? "active" : ""}
              onClick={() => {
                setActiveTab("Members");
              }}
            >
              Members
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={activeTab === "Billing" ? "active" : ""}
              onClick={() => {
                setActiveTab("Billing");
              }}
            >
              Billing
            </NavLink>
          </NavItem> */}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="Members">
            <Fade>
              <Container className="mt-0 px-0">
                <TeamMembers
                  lpEntityCode={user?.meta?.defaultLPEntityCode}
                  data={data}
                />
              </Container>
            </Fade>
          </TabPane>

          <TabPane tabId="Billing">
            <Fade>
              <Container className="mt-5"></Container>
            </Fade>
          </TabPane>
        </TabContent>
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(ManageTeamComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
