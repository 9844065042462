import BaseService from "./BaseService";

class LPEntityService {
  static async getAll(token) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/lpentity`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async addLPEntity(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/lpentity`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async editLPEntity(token, code, data) {
    let d = JSON.stringify({ ...data, code: code });
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/lpentity/${code}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async deleteLPEntity(token, code) {
    // TODO: Delete any lpentityuser based on the code
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/lpentity/${code}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      credentials: "include",
    });
    return response.statusText;
  }
}

export default LPEntityService;
