import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import Heading from "./Heading";
import { Input, InputGroup, Label } from "reactstrap";
import {
  Additem,
  ArrowLeft,
  ClipboardText,
  Folder,
  Note,
  Notepad2,
} from "iconsax-react";
import {
  Container,
  Badge,
  Form,
  Row,
  Col,
  FormGroup,
  Button,
} from "react-bootstrap";
import "./VCFundLibrary.scss";
import { Fade } from "react-awesome-reveal";
import DataTable from "react-data-table-component";
import FundBadge from "./FundBadge";
import Loading from "./Loading";
import useScreenType from "react-screentype-hook";

export default function VCFundLibrary(props) {
  const navigate = useNavigate();
  const screenType = useScreenType();

  const { getAccessTokenSilently } = useAuth0();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState([]);

  let defaultOffset = 0;
  let defaultQuery = "";
  let limit = 5;
  const [offset, setOffset] = useState(defaultOffset);
  const [query, setQuery] = useState(defaultQuery);

  const loadMore = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      let resp = await VCFundService.search(token, {
        name: query,
        limit: limit,
        offset: offset,
        publicView: true,
      });
      setData(data.concat(resp.rows));
      setCount(resp.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const getSecondRow = (data) => {
    return (
      <div
        className={` ${
          screenType.isMobile ? "d-flex-column" : "d-flex d-flex-row"
        }`}
      >
        {data.fundRaisingStatus === "Started" && (
          <div className="mr-3">
            <Additem></Additem>
            <span>Currently Raising</span>
          </div>
        )}

        {data.meta?.digifyRoomGuid && (
          <div className="mr-3">
            <Folder />
            <span>Data room</span>
          </div>
        )}
        {data.meta?.digifyReportGuid &&
          data?.meta?.tier?.toString() === "1" && (
            <div className="mr-3">
              <Notepad2></Notepad2>
              <span>Due diligence report</span>
            </div>
          )}
        {data.meta?.digifyReportGuid &&
          data?.meta?.tier?.toString() !== "1" && (
            <div className="mr-3">
              <Note></Note>Brief Report
            </div>
          )}
      </div>
    );
  };
  const getBadge = (data) => {
    return (
      <div className="p-3">
        <FundBadge
          size={screenType.isMobile ? "Normal" : "Large"}
          fund={data}
          imageURL={data?.meta.logoURL}
          fontColor={data?.meta.fontColor}
          fundTitle={data?.meta.titleName || data?.name}
          tier={data?.meta.tier}
          imageCoverMode={"contain"}
        />
      </div>
    );
  };
  const columns = [
    {
      name: "",
      grow: 0,
      minWidth: screenType.isMobile ? "10" : "150",
      style: {
        padding: 0,
      },
      selector: (row) => {
        let data = row;
        return (
          <div
            className="py-2 cursor-pointer"
            onClick={() => navigate(`/fund-detail/${data.name}`)}
          >
            {getBadge(data)}
          </div>
        );
      },
      sortable: false,
    },

    {
      name: "",
      grow: 10,

      selector: (row) => {
        let data = row;
        return (
          <div
            className="py-2 cursor-pointer"
            onClick={() => navigate(`/fund-detail/${data.name}`)}
          >
            <div
              className={` pb-2 mt-1 ${
                screenType.isMobile ? "d-flex d-flex-row" : "d-flex d-flex-row"
              }`}
            >
              <div className={"fundTitle pr-2"}>{row.name}</div>

              <Badge
                className={`mt-1 ml-0 TierBadge SmallerBadge ${
                  data?.meta?.tier?.toString() === "1" && "Tier1Badge"
                }`}
                pill
              >
                {data?.fundCategory?.name}
              </Badge>
            </div>

            {getSecondRow(data)}
          </div>
        );
      },
      sortable: false,
    },
  ];

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      let resp = await VCFundService.search(token, {
        name: query,
        limit: limit,
        offset: offset,
        publicView: true,
      });
      setData(resp.rows);
      setCount(resp.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(() => {
    loadMore();
  }, [offset]);

  const prev = () => {
    setOffset(Math.max(0, offset - limit));
  };

  const next = () => {
    if (offset + limit < count) {
      setOffset(offset + limit);
    }
  };

  const first = () => {
    setOffset(0);
  };

  const last = () => {
    setOffset(Math.floor(count / limit) * limit);
  };

  return (
    <Container className="VCFundLibrary">
      {loading && <Loading />}

      <div className={"my-3 v0-black"}>
        <Link
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size="32" color="#333333" /> Back
        </Link>
      </div>
      <Heading title={"Funds Library"} className={"text-align-center"} />

      <Label for="query">Search our funds database</Label>
      <Input
        id="query"
        placeholder="Search funds"
        inputMode={"text"}
        value={query}
        onChange={async (e) => {
          setOffset(0);
          setQuery(e.target.value);
        }}
      />

      {query && (
        <Label className="py-3">
          {count} funds found for [{query}]
        </Label>
      )}

      {count ? (
        <div className="py-5">
          <DataTable
            responsive={true}
            className="dataTables_wrapper"
            columns={columns}
            data={data}
            noTableHead={true}
            noHeader={true}
          />
        </div>
      ) : null}

      {count > data.length && (
        <div className="d-flex">
          <Button
            className="primary-button base-button mx-auto my-1 "
            onClick={next}
          >
            Load more...
          </Button>
        </div>
      )}
    </Container>
  );
}
